import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetHealthProfileDataProps } from 'services/healthProfile/healthProfile.types';

import { HealthProfileState } from './healthProfile.types';

const initialState: HealthProfileState = {
  medicalIntake: {
    additionalHealthConditions: [],
    allergyList: [],
    chronicDiseases: [],
    healthConditions: [],
    medications: { prescription: [], supplements: [] },
    preferredPharmacy: '',
    socialHistory: [],
    womenHealth: {
      approxStartDate: '',
      name: '',
      startDate: ''
    }
  },
  mifDetails: {
    id: '',
    status: 'mif-normal'
  }
};

const healthProfileSlice = createSlice({
  initialState,
  name: 'healthProfile',
  reducers: {
    setHealthProfile: (state, action: PayloadAction<Partial<GetHealthProfileDataProps>>) => {
      state.medicalIntake = { ...state.medicalIntake, ...action.payload.medicalIntake };
      state.mifDetails = { ...state.mifDetails, ...action.payload.mifDetails };
    }
  }
});

export const { setHealthProfile } = healthProfileSlice.actions;

export default healthProfileSlice.reducer;
