import { memo, useState } from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';

import { ResendEmailButtonProps } from './buttonResendEmail.types';

const DISABLED_MS = 59000; // 59 seconds

const ButtonResendEmail: React.FC<ResendEmailButtonProps> = ({
  children,
  dataTestId = 'resend_email_btn',
  disabled,
  initDisabled = false,
  onClick
}) => {
  const [isEnabled, toggleIsEnabled] = useToggle(!initDisabled);
  const [date, setDate] = useState(DISABLED_MS);

  return (
    <Common.Button
      color="white-alt"
      data-testid={dataTestId}
      disabled={disabled || !isEnabled}
      size="sm"
      type="button"
      onClick={(e) => {
        setDate(new Date().getTime() + DISABLED_MS);
        onClick?.(e);
        toggleIsEnabled();
      }}
    >
      {!isEnabled && (
        <Countdown
          date={date}
          renderer={({ seconds }: CountdownRenderProps) => (
            <span data-testid="timer">{seconds}s</span>
          )}
          onComplete={toggleIsEnabled}
        />
      )}
      {children}
    </Common.Button>
  );
};

export default memo(ButtonResendEmail);
