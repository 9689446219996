import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';

import { selectNewAppointmentExtended } from 'store';

import BirthInput from 'shared/form/BirthInput';

import { useAppSelector } from 'hooks';

const Dob: React.FC<{ onContinue: (dob: string) => void }> = ({ onContinue }) => {
  const {
    control,
    getValues,
    formState: { isValid },
    reset,
    handleSubmit
  } = useForm({ shouldFocusError: false });

  const { userDob } = useAppSelector(selectNewAppointmentExtended);

  const onSubmit = () => {
    const dob = getValues('dob');
    if (dob && isValid) {
      onContinue(dob);
    }
  };

  useEffect(() => {
    if (userDob) {
      reset({ dob: userDob });
    }
  }, []);

  return (
    <form className="flex h-full flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
      <h3 className="main-header text-primary-700">Please enter your date of birth</h3>
      <BirthInput control={control} label="Date of birth" name="dob" size="lg" maxAgeValidation />
      <Common.Button className="max-md:mt-auto md:mx-auto" color="blue" fullWidthOnMobile>
        Continue
      </Common.Button>
    </form>
  );
};

export default Dob;
