import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system/lib/src';

import { LabsType } from 'services/wm/wm.types';

import { selectOrchestrate, selectStatesWhereAshIsAvailable, selectUser } from 'store';

import { AltSignupStepProps } from 'containers/SignUp/Content/content.types';
import LabsToCheckout from 'features/LabsToCheckout';
import Heading from 'widgets/wmOnboarding/parts/Heading';

import { useAppSelector, useQuery } from 'hooks';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';
import { buildSearch } from 'utils/helpers';

import AshSelected from './components/AshSelected';

import LabTesting from 'assets/images/signUp/labs.svg';

const LabWork: React.FC<AltSignupStepProps & { onSelectLabsType: (v: boolean) => void }> = ({
  onContinue,
  onSelectLabsType,
  selectedFlow
}) => {
  const { send, isLoading } = useSubmitOrchestrateForm();
  const navigate = useNavigate();
  const subStep = useQuery().get('subStep');
  const [activeItem, setActiveItem] = useState<LabsType>();
  const [isExpanded, setIsExpanded] = useState(false);
  // const [isOpenSeeDetails, setIsOpenSeeDetails] = useState(false);
  const [isSecondaryAgreementAccepted, setIsSecondaryAgreementAccepted] = useState(false);
  const {
    user: { state }
  } = useAppSelector(selectOrchestrate);
  const { state: authState } = useAppSelector(selectUser);
  const statesWithAsh = useAppSelector(selectStatesWhereAshIsAvailable);
  const isAshSelected = subStep === 'ash-selected' || activeItem === 'ash';

  const handleSelect = () => {
    const finalState = selectedFlow === 'authorized' ? authState : state;
    if (!subStep) {
      if (!statesWithAsh.includes(finalState)) {
        send('mif_qa', [{ answer: 'elation', question: 'labs' }], onContinue);
      } else {
        navigate({
          search: buildSearch(
            selectedFlow === 'authorized' ? 's=lab-work' : '',
            'subStep=labs-select&shouldEmulateBack=true'
          )
        });
      }
    } else if (subStep === 'labs-select') {
      if (isAshSelected) {
        onSelectLabsType(isAshSelected);
        navigate({
          search: buildSearch(
            selectedFlow === 'authorized' ? 's=lab-work' : '',
            'subStep=ash-selected&shouldEmulateBack=true'
          )
        });
      } else {
        onSelectLabsType(isAshSelected);
        handleSubmit();
      }
    } else {
      onSelectLabsType(isAshSelected);
      handleSubmit();
    }
  };

  const handleSubmit = () =>
    send(
      'mif_qa',
      [{ answer: String(subStep === 'ash-selected' ? 'ash' : activeItem), question: 'labs' }],
      onContinue
    );

  const getDisabledStatus = () => {
    if (!subStep || subStep === 'ash-selected') {
      return false;
    }
    return !activeItem || (activeItem === 'ash' && !isSecondaryAgreementAccepted);
  };

  return (
    <div className="flex flex-col gap-6 max-md:h-full">
      {!subStep && (
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <div className="self-center rounded-full bg-secondary-100 p-4">
              <img alt="lab-work" className="self-center" src={LabTesting} />
            </div>
            <div className="flex flex-col gap-2">
              <h2 className="wm-signup-title mx-auto max-w-[80%] text-center" data-testid="header">
                Heads up, lab work will be required
              </h2>
              <span className="text-center text-primary-700">
                To minimize the risk of side effects, completing lab work is required before
                receiving your medication, and may be required at any time prior to attaining
                subsequent medication refills.
              </span>
            </div>
          </div>
        </div>
      )}
      {subStep === 'labs-select' && (
        <div className="flex h-full flex-col gap-6">
          <Heading
            category="Plan"
            subtitle="Choose a convenient at-home lab kit or complete at a lab near you."
            title="How would you like to complete your labs?"
          />
          <div>
            <LabsToCheckout
              activeItem={activeItem}
              isLoading={isLoading}
              setActiveItem={setActiveItem}
            />
            {/* <Common.Button
              className="mx-auto text-mSm font-medium text-gray underline"
              preIcon="info"
              style="text-only"
              onClick={() => setIsOpenSeeDetails(true)}
            >
              See details
            </Common.Button> */}
          </div>

          {activeItem === 'ash' && (
            <Common.Checkbox
              checked={isSecondaryAgreementAccepted}
              className="!items-start"
              color="blue"
              onChange={() => setIsSecondaryAgreementAccepted((p) => !p)}
            >
              <p className="flex flex-col gap-6 px-0.5 text-base">
                <span>
                  I authorize specimen collection with a dried blood spot collection. I further
                  understand, agree, certify, and authorize the following:{' '}
                  {!isExpanded && (
                    <button
                      className="underline"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsExpanded(true);
                      }}
                    >
                      Expand
                    </button>
                  )}
                </span>
                {isExpanded && (
                  <>
                    <span>
                      This test involves a dried blood spot card which will require a capillary
                      sample extracted via a lancet. It may be uncomfortable, painful, or
                      potentially cause mild abrasion or bleeding. No long-lasting side effects from
                      testing are expected.
                    </span>
                    <span>
                      LifeMD, Inc. has contracted with Ash Wellness, Inc. and their partner labs for
                      laboratory analysis and report of my specimen.
                    </span>
                    <span>
                      I understand that I am not entering into a doctor-patient relationship with
                      Ash Wellness, Inc or LifeMD, Inc. and that any questions or required follow up
                      shall be my responsibility to arrange with my own
                    </span>
                  </>
                )}
              </p>
            </Common.Checkbox>
          )}
        </div>
      )}
      {subStep === 'ash-selected' && <AshSelected />}
      <Common.Button
        className="mt-auto md:self-center"
        color="blue"
        disabled={getDisabledStatus()}
        isLoading={isLoading}
        fullWidthOnMobile
        onClick={handleSelect}
      >
        Continue
      </Common.Button>
    </div>
  );
};

export default LabWork;
