import { useNavigate } from 'react-router-dom';

import { selectOrchestrate } from 'store';

import DiabetesPicker from 'widgets/wmOnboarding/pickers/DiabetesPicker';

import { useAppSelector, useQuery } from 'hooks';
import { useGetSelectedItem } from 'hooks/useGetSelectedItem';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';
import { FlowTypes, PathName } from 'utils/enums';
import { buildSearch } from 'utils/helpers';

import { Props } from './diabetes.types';

const Diabetes: React.FC<Props> = ({
  moveToStep,
  selectedFlow = FlowTypes.WeightManagementFlow,
  migrationFlow
}) => {
  const {
    user: { email }
  } = useAppSelector(selectOrchestrate);
  const { send, isLoading } = useSubmitOrchestrateForm();
  const navigate = useNavigate();
  const query = useQuery();
  const { value, setValue } = useGetSelectedItem('diagnosed_with_diabetes');

  const handleSelect = (v: string, cb?: () => void) => {
    setValue(v);
    const isRestrictedValue = ['yes_type_i'].includes(v);
    if (isRestrictedValue) {
      cb?.();
      navigate({
        search: buildSearch(
          selectedFlow === 'authorized' || !!migrationFlow ? 's=wm-diabetes-question' : '',
          `hideProgress=true&subStep=fail-diabetes-step&a=${v}`
        )
      });
    } else {
      send('mif_qa', [{ answer: v, question: 'diagnosed_with_diabetes' }], () => {
        cb?.();
        moveToStep('next');
      });
    }
  };

  const handleProspect = () => {
    if (!!migrationFlow || selectedFlow === 'authorized') {
      navigate(PathName.Home);
    } else {
      const answer = query.get('a');
      if (answer && !!email) {
        send(
          'mif_qa',
          [{ answer, question: 'diagnosed_with_diabetes' }],
          () => {
            moveToStep(-1);
          },
          'PROSPECT',
          () => {
            moveToStep(-1);
          }
        );
      } else {
        moveToStep(-1);
      }
    }
  };

  const isErrorState = query.get('subStep') === 'fail-diabetes-step';

  return (
    <DiabetesPicker
      isErrorState={isErrorState}
      loading={isLoading}
      selected={value as string}
      selectedFlow={selectedFlow}
      onHandleProspect={handleProspect}
      onSelect={handleSelect}
    />
  );
};

export default Diabetes;
