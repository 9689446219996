import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrchestrateState } from './orchestrate.types';

const initialState: OrchestrateState = {
  agreements: {
    medicaidWaiverAgreed: false
  },
  appointment: {
    appointmentDescription: '',
    appointmentMethod: '',
    appointmentTime: {
      endTime: null,
      startTime: null
    },
    appointmentTypeId: '',
    doctorId: '',
    planId: '',
    planPricePointId: '',
    timezone: ''
  },
  insurance: null,
  insurances: [],
  marketing: {
    'flow-source': '',
    onboarding_url: '',
    prospect_flow: '',
    prospect_initial_appt_type: '',
    prospect_type: ''
  },
  mif: {
    bmi: { date: '', value: 0 },
    height: { date: '', value: 0 },
    weight: { date: '', value: 0 }
  },
  mif_qa: [],
  payment: {
    addOns: [],
    coupon_codes: [],
    credit_card_attributes: {
      cvv: '',
      expiration_month: '',
      expiration_year: '',
      full_number: ''
    },
    product_handle: '',
    product_price_point_handle: ''
  },
  selectedLab: null,
  user: {
    address: '',
    city: '',
    dob: '',
    email: '',
    firstName: '',
    flowUrl: '',
    lastName: '',
    phone: '',
    referrerUrl: '',
    sexAtBirth: undefined,
    state: '',
    timezone: '',
    zipCode: ''
  }
};

const orchestrate = createSlice({
  initialState,
  name: 'orchestrate',
  reducers: {
    clearOrchestrateState: () => initialState,
    setAppointment: (state, action: PayloadAction<Partial<OrchestrateState['appointment']>>) => {
      state.appointment = { ...state.appointment, ...action.payload };
    },
    setMarketing: (state, action: PayloadAction<Partial<OrchestrateState['marketing']>>) => {
      state.marketing = { ...state.marketing, ...action.payload };
    },
    setMif: (state, action: PayloadAction<OrchestrateState['mif']>) => {
      state.mif = action.payload;
    },
    setMifQA: (state, action: PayloadAction<OrchestrateState['mif_qa']>) => {
      state.mif_qa = action.payload;
    },
    setPayment: (state, action: PayloadAction<Partial<OrchestrateState['payment']>>) => {
      state.payment = { ...state.payment, ...action.payload };
    },
    setSubmit: (state, action: PayloadAction<OrchestrateState['submit']>) => {
      state.submit = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<Partial<OrchestrateState['user']>>) => {
      state.user = { ...state.user, ...action.payload };
    },
    setWholeState: (state, action: PayloadAction<OrchestrateState>) => {
      return { ...state, ...action.payload };
    },
    setSelectedLab: (state, action: PayloadAction<OrchestrateState['selectedLab']>) => {
      state.selectedLab = action.payload;
    },
    setAgreements: (state, action: PayloadAction<{ [key: string]: boolean }>) => {
      state.agreements = { ...state.agreements, ...action.payload };
    }
  }
});

export const {
  setUserInfo,
  // setAppointment,
  // setMarketing,
  setMif,
  setMifQA,
  setPayment,
  // setSubmit,
  setWholeState,
  clearOrchestrateState
  // setSelectedLab,
  // setAgreements
} = orchestrate.actions;

export default orchestrate.reducer;
