import React from 'react';
import classNames from 'classnames';

import useAnalytics from 'hooks/useAnalytics';
import { MessageTab } from 'utils/enums';

import { ChannelTabsProps, TabProps } from './channelTabs.types';

const ChannelTabs: React.FC<ChannelTabsProps> = ({ tabs, onChange, selectedTabId }) => {
  const logEvent = useAnalytics();

  const handleClickTab = (tab: TabProps) => {
    logEvent(
      tab.id === MessageTab.FRONT_DESK
        ? 'messages_front_desk_tab_click'
        : 'messages_care_team_tab_click'
    );
    onChange(tab);
  };
  return (
    <div
      className="relative flex w-full gap-5 border-b border-gray-200 px-4 py-6"
      data-testid="tabs_filter_menu"
    >
      {tabs.map((tab) => (
        <button
          className={classNames(
            'flex items-center text-mBase gap-0.5',
            tab.id === selectedTabId ? 'text-primary-700 font-bold' : 'text-gray font-semibold'
          )}
          data-testid="channel_tab"
          key={tab.label}
          type="button"
          onClick={() => handleClickTab(tab)}
        >
          {tab.label}
          {!!tab.unreadMessageCount && (
            <div
              className="flex size-4 items-center justify-center rounded-full bg-red text-xs text-white"
              data-testid="unread_messages_counter"
            >
              {tab.unreadMessageCount > 9 ? '9+' : tab.unreadMessageCount}
            </div>
          )}
        </button>
      ))}
    </div>
  );
};

export default ChannelTabs;
