import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query';
import queryString from 'query-string';

import { RootState } from 'store';
import { setUserState } from 'store/signup/signupSlice';
import { clearUser, setToken } from 'store/user/userSlice';

import packageInfo from '../../package.json';

import { PathName } from './enums';
interface IRes {
  data: { data: { accessToken: string; refreshToken: string } };
  error?: {
    data: {
      errorMessage: number;
      message: string;
    };
    status: number;
  };
}

export const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_BASE_URL + '/',
  paramsSerializer: (params) => queryString.stringify(params, { arrayFormat: 'bracket' }),
  prepareHeaders: (headers, { getState }) => {
    const token = headers.get('Authorization') ?? (getState() as RootState).user.accessToken;
    const staffId = sessionStorage.getItem('staffId');
    headers.set('App-Version', packageInfo.version);
    headers.set('Platform', 'web-patient');
    headers.set('API-KEY', import.meta.env.VITE_API_KEY ?? '');
    staffId && headers.set('impersonation-mode-by', staffId);

    if (token) {
      headers.set('Authorization', token);
    }

    return headers;
  }
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  const store = api.getState() as RootState;
  const { refreshToken } = store.user;
  const { user } = (api.getState() as RootState).orchestrate;

  const {
    user: { refreshToken: guestUserRefreshToken }
  } = store.signUp;

  if (result.error && result.error.status === 401) {
    if (!refreshToken && !guestUserRefreshToken) {
      // if this is orchestrate flow, we don't need to clear user, we need to regenerate the token. To do that we move user back so he can regenerate the token
      if (!!user?.accessToken) {
        window.history.back();
      } else {
        api.dispatch(clearUser());
        window.location.href = PathName.Login;
      }
      return result;
    }
    // try to get a new token
    try {
      const refreshResult = await baseQuery(
        {
          body: { refreshToken: refreshToken || guestUserRefreshToken },
          headers: {
            'API-KEY': import.meta.env.VITE_API_KEY
          },
          method: 'POST',
          url: '/auth/token-renewal'
        },
        api,
        extraOptions
      );

      if (refreshResult.error) {
        api.dispatch(clearUser());
        throw Error('Error while getting refresh token');
      }

      const { data } = (refreshResult as IRes)?.data;

      if (data) {
        // if we are not logged in and have only guest access token
        if (guestUserRefreshToken) {
          api.dispatch(setUserState(data));
        } else {
          // else log user in
          api.dispatch(setToken(data));
        }

        // retry the initial query
        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(clearUser());
      }
    } catch (e) {
      // log out user if we can't get a new token
      api.dispatch(clearUser());
      throw e;
    }
  }

  return result;
};
