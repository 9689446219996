import { useTitle } from 'react-use';
import classNames from 'classnames';

import { selectUser } from 'store';

import Channels from 'pages/Messages/components/Channels';
import CreateNewRequest from 'pages/Messages/components/CreateNewRequest';

import PageWrapper from 'shared/animationWrappers/PageWrapper';
import Chat from 'widgets/Chat';

import { useAppSelector, useFrontDesk, useMessages, useQuery } from 'hooks';
import useWidth from 'hooks/useWidth';
import { MessageTab } from 'utils/enums';

const Messages = () => {
  useTitle('LifeMD - Messages');
  const { isMobile } = useWidth();
  const chatId = useQuery().get('chatId');
  const queryTabId = useQuery().get('tab');
  const { activePlanCode } = useAppSelector(selectUser);

  const { channels: frontDeskChannels } = useFrontDesk();
  const { channels: careTeamChannels } = useMessages();
  const isCreateNewRequest = useQuery().get('create-new-request') === 'true';

  const selectedChannel =
    careTeamChannels.find((el) => el.channelId === chatId) ||
    frontDeskChannels.find((el) => el.channelId === chatId);

  const isRestrictedAccess = queryTabId === MessageTab.CARE_TEAM && !activePlanCode;

  return (
    <PageWrapper
      className={classNames('flex size-full', {
        'blur-sm': isRestrictedAccess
      })}
    >
      <Channels />
      <div className="flex grow flex-col truncate">
        {!isMobile && (
          <div
            className="flex h-[73px] items-center justify-center border-b border-gray-200 px-8 text-lg font-bold"
            data-testid="chat_name"
          >
            <span className="truncate">
              {selectedChannel && 'frontDeskRequestType' in selectedChannel
                ? selectedChannel.frontDeskRequestType
                : isCreateNewRequest
                  ? 'Create new request'
                  : selectedChannel?.channelTitle}
            </span>
          </div>
        )}
        {isCreateNewRequest ? <CreateNewRequest /> : <Chat />}
      </div>
    </PageWrapper>
  );
};

export default Messages;
