import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';
import dayjs from 'dayjs';
import HTMLReactParser from 'html-react-parser';

import { useGetDocumentsQuery } from 'services/documents/documents';
import { useGetSignableDocumentsQuery } from 'services/general/general';

import { selectUser } from 'store';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';

import { useAppSelector } from 'hooks';
import { DateFormat, PathName } from 'utils/enums';

const MedicaidWaiver = () => {
  useTitle('LifeMD - Medicaid Waiver');

  const { firstName, lastName } = useAppSelector(selectUser);

  const { data: templates, isFetching } = useGetSignableDocumentsQuery();
  const { data: documents } = useGetDocumentsQuery({
    pageNo: 0,
    category: 'signable-document'
  });

  const { signedDate } =
    documents?.data.find((item) => item.subCategory === 'medicaid-waiver-terms') || {};

  const { html } = templates?.data.find((item) => item.type === 'medicaid-waiver-terms') || {
    html: ''
  };

  return (
    <FadeWrapper className="flex flex-col gap-4 md:p-8">
      <Loader isVisible={isFetching} />
      <NavLink className="flex items-center gap-3 max-md:hidden" to={PathName.Legal}>
        <Common.Icon name="arrow-left" />
        <h1 className="text-xl font-bold">Medicaid waiver</h1>
      </NavLink>
      <div className="mx-auto flex w-full max-w-[500px] flex-col gap-8">
        <div className="text-primary-700" data-testid="agreement_block">
          {HTMLReactParser(html)}
        </div>
        <div className="flex flex-col gap-2 py-6">
          <p className="text-mXs font-semibold text-primary-700">Agreed on 11/12/2024 by</p>
          <p className="text-gray">
            {firstName} {lastName}
          </p>
        </div>
        {signedDate && (
          <div className="flex flex-col gap-2 py-6">
            <p className="text-mXs font-semibold text-primary-700">
              Agreed on {dayjs(signedDate).format(DateFormat.MM_DD_YYYY)} by
            </p>
            <p className="text-gray">
              {firstName} {lastName}
            </p>
          </div>
        )}
      </div>
    </FadeWrapper>
  );
};
export default MedicaidWaiver;
