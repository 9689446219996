import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
import Header from 'widgets/Header';

import { useMigrateFromTirzepatide } from 'hooks/useMigrateFromTirzepatide';

import Content from './Content';
import { MIGRATE_FROM_TIRZEPATIDE_STEPS } from './migrateFormTirzepatide.types';

const MigrateFromTirzepatide = ({
  onComplete,
  onLeaveFlow
}: {
  onComplete: () => void;
  onLeaveFlow: () => void;
}) => {
  const {
    loading,
    currentStep,
    isBackAnimation,
    moveToStep,
    userCurrentPPType,
    userCurrentPP,
    tirzepatidePP,
    ppToUpgrade,
    mifSteps,
    isSubmittingMif,
    isGettingMyAccount,
    couponCode
  } = useMigrateFromTirzepatide(onComplete, onLeaveFlow);

  return (
    <FadeWrapper className="flex h-full flex-col">
      <Loader isVisible={loading} />
      <Header
        flowLength={MIGRATE_FROM_TIRZEPATIDE_STEPS.length}
        step={MIGRATE_FROM_TIRZEPATIDE_STEPS.indexOf(currentStep)}
        onClickPrev={() => moveToStep('prev')}
      />
      {mifSteps.length > 0 && (
        <Content
          couponCode={couponCode}
          isBackAnimation={isBackAnimation}
          isGettingMyAccount={isGettingMyAccount}
          isSubmittingMif={isSubmittingMif}
          mifSteps={mifSteps}
          moveToStep={moveToStep}
          ppToUpgrade={ppToUpgrade}
          step={currentStep}
          tirzepatidePP={tirzepatidePP}
          userCurrentPP={userCurrentPP}
          userCurrentPPType={userCurrentPPType}
        />
      )}
    </FadeWrapper>
  );
};

export default MigrateFromTirzepatide;
