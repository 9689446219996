import { motion } from 'framer-motion';

import { VARIANTS } from 'shared/animationWrappers/ListItemWrapper/ListItemWrapper';

import { StepHeaderInfoProps } from './stepHeaderInfo.types';

const StepHeaderInfo: React.FC<StepHeaderInfoProps> = ({ stepTitle, stepSubtitle, dataTestId }) => {
  const stepTitleClassName = 'text-left text-m2xl font-bold text-primary-700 my-2 md:text-xl';
  const stepSubtitleClassName = 'text-mBase text-primary-700  md:text-base';

  return (
    <motion.div animate="visible" exit="hidden" initial="hidden" variants={VARIANTS}>
      <h2 className={stepTitleClassName} data-testid={dataTestId}>
        {stepTitle}
      </h2>
      <p className={stepSubtitleClassName}>{stepSubtitle}</p>
    </motion.div>
  );
};

export default StepHeaderInfo;
