import { useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { nanoid } from 'nanoid';

import { selectMifInfo, selectSignUp } from 'store';
import { MedicationItemProps } from 'store/mif/mif.types';
import { setMifInformation, setMifPick } from 'store/mif/mifSlice';

import ButtonsGroup from 'pages/SignUp/regularFlows/mifSteps/parts/ButtonsGroup';
import StepHeaderInfo from 'pages/SignUp/regularFlows/mifSteps/parts/StepHeaderInfo';
import YesNoSelect from 'pages/SignUp/regularFlows/mifSteps/parts/YesNoSelect';

import { VARIANTS } from 'shared/animationWrappers/ListItemWrapper/ListItemWrapper';
import MedicationsItem from 'widgets/mif/Medications';

import { useAppDispatch, useAppSelector } from 'hooks';
import { useMifNavigate } from 'hooks/useMifNavigate';
import useSubmitMifParts from 'hooks/useSubmitMifParts';
import { createGtmEvent } from 'utils/gtm';
import { handleRequestCatch } from 'utils/helpers';
import { AT_LEAST_ONE_LETTER_REGEXP } from 'utils/regExp';

import { MifStepProps } from '../mifSteps.types';
type MedicationState = MedicationItemProps & {
  id: string;
  isError?: boolean;
  isPrescription: boolean;
};
const buildData = (items: MedicationState[]) => ({
  prescription: items.map(({ id, ...r }) => r).filter(({ isPrescription }) => isPrescription),
  supplements: items.map(({ id, ...r }) => r).filter(({ isPrescription }) => !isPrescription)
});

const Medications: React.FC<MifStepProps> = ({ moveToStep }) => {
  useTitle('LifeMD - Medical intake form');
  const { isEdit, onNavigate } = useMifNavigate();
  const { sendMifPart, isLoading } = useSubmitMifParts();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectSignUp);
  const {
    mifInfo: { medications },
    mifPicks
  } = useAppSelector(selectMifInfo);
  const [hasMedications, setHasMedications] = useState(false);
  const [medicationItems, setMedicationItems] = useState<MedicationState[]>([]);

  const handleSubmit = () => {
    const body = {
      accessToken: user?.accessToken,
      metrics: {
        medications: {
          prescription: medicationItems.flatMap(({ id, isError, isPrescription, ...r }) =>
            isPrescription ? [r] : []
          ),
          supplements: medicationItems.flatMap(({ id, isError, isPrescription, ...r }) =>
            !isPrescription ? [r] : []
          )
        }
      }
    };

    sendMifPart(body)
      .then(() => {
        createGtmEvent('medications_info', user);
      })
      .catch(handleRequestCatch)
      .finally(() => onNavigate(() => moveToStep('next')));
  };

  const setSelectedValues = () => {
    dispatch(
      setMifInformation({
        data: buildData(medicationItems),
        prop: 'medications'
      })
    );
    handleSubmit();
  };

  const handleSelect = (v: boolean) => {
    dispatch(setMifPick({ medicationsPick: v }));
    setHasMedications(v);
    if (v) {
      setMedicationItems([
        { dosage: '', id: nanoid(), isError: false, isPrescription: false, name: '' }
      ]);
    } else {
      handleSubmit();
    }
  };

  const isDisabled =
    hasMedications === null ||
    (hasMedications &&
      medicationItems.some(
        (p) => p.name.trim().length < 3 || !p.name.match(AT_LEAST_ONE_LETTER_REGEXP)
      ));

  useEffect(() => {
    const list = [
      ...medications.prescription.map((m) => ({
        ...m,
        id: nanoid(),
        isError: m.name.trim().length < 3,
        isPrescription: true
      })),
      ...medications.supplements.map((m) => ({
        ...m,
        id: nanoid(),
        isError: m.name.trim().length < 3,
        isPrescription: false
      }))
    ];
    setMedicationItems(list);
    setHasMedications(mifPicks?.medicationsPick || !!list.length);
  }, []);

  useEffect(() => {
    if (!medicationItems.length && hasMedications) {
      setHasMedications(false);
    }
  }, [medicationItems]);

  return (
    <div className="flex grow flex-col">
      <motion.div
        animate="visible"
        className="intake-form-wrapper"
        exit="hidden"
        initial="hidden"
        variants={VARIANTS}
      >
        <StepHeaderInfo
          dataTestId="medications"
          stepSubtitle={
            !hasMedications
              ? 'Are you currently taking any medications?'
              : 'Please list all the medications you are taking. It’s important to be thorough.  If you are unsure about dosage, you can to leave it blank.'
          }
          stepTitle="Medications"
        />
        <LayoutGroup id="m-lix">
          {!hasMedications ? (
            <YesNoSelect
              loading={isLoading}
              selectedValue={hasMedications}
              onSelect={handleSelect}
            />
          ) : (
            <AnimatePresence>
              <div className="flex flex-col gap-4">
                <MedicationsItem medications={medicationItems} onUpdate={setMedicationItems} />
              </div>
              <motion.div
                className="mt-6 hidden h-px w-full bg-gray-200 md:block"
                key="separator"
                layoutId="separator"
              />
              <motion.div className="mt-auto md:mt-0" key="btns" layoutId="btns-group">
                <ButtonsGroup
                  isDisabledNextBtn={isDisabled || isLoading}
                  loading={isLoading}
                  nextBtnText={isEdit ? 'Update' : 'Next'}
                  hidePrev
                  onClickNext={setSelectedValues}
                />
              </motion.div>
            </AnimatePresence>
          )}
        </LayoutGroup>
      </motion.div>
    </div>
  );
};

export default Medications;
