import { useState } from 'react';
import { Common } from '@thecvlb/design-system/lib/src';
import dayjs from 'dayjs';

import { useGetReportsQuery } from 'services/reports/reports';

import Loader from 'shared/Loader';
import RadioGroup from 'shared/RadioGroup';

import { LabsToReviewProps } from './labsToReview.types';

const LabsToReview: React.FC<LabsToReviewProps> = ({ onSelect }) => {
  const [activeItem, setActiveItem] = useState<string>();
  const { data, isLoading, isFetching } = useGetReportsQuery(undefined, {
    refetchOnMountOrArgChange: true // Ensures fresh data when the component mounts
  });
  const reports = data?.data ?? [];

  const handleOnSelect = (value: string) => {
    setActiveItem(value);
    onSelect(value);
  };

  const reportsOptions = isFetching
    ? []
    : [
        ...reports.map((report) => ({
          label: report.title || report.reportType || 'Report',
          value: report._id,
          description: report?.signedDate
            ? dayjs(report.signedDate).format('MMM D, YYYY')
            : 'Not signed',
          disabled: !!report.pendingAppointment?.appointmentId
        })),
        { label: 'Another', value: 'another', description: 'You’ll upload it on the next page' }
      ];

  return (
    <div className="flex flex-col gap-6">
      <Loader isVisible={isFetching || isLoading} />
      <div className="flex flex-col gap-2">
        <Common.ColorTag color="primary" text="Lab tests" />
        <h1 className="text-m2xl font-bold text-primary-700" data-testid="header">
          Which labs do you want to review with your provider
        </h1>
      </div>
      <RadioGroup items={reportsOptions} selectedItem={activeItem} onSelect={handleOnSelect} />
    </div>
  );
};

export default LabsToReview;
