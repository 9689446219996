import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';

import { selectNewAppointmentExtended } from 'store';

import AddressForm from 'shared/form/Address';

import { useAppSelector } from 'hooks';

import { MailingAddress } from 'models/user.types';

const Address: React.FC<{ onContinue: (data: MailingAddress) => void }> = ({ onContinue }) => {
  const { userAddress, userCity, userState, userZip } = useAppSelector(
    selectNewAppointmentExtended
  );
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    // formState: { isValid },
    trigger
  } = useForm<MailingAddress>({
    criteriaMode: 'all',
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  useEffect(() => {
    reset({
      address: userAddress,
      city: userCity,
      state: userState,
      zipCode: userZip
    });
  }, []);

  return (
    <form className="flex h-full flex-col gap-6" onSubmit={handleSubmit(onContinue)}>
      <h3 className="main-header text-primary-700">Place of residence</h3>
      <AddressForm
        control={control}
        setValue={setValue}
        size="lg"
        trigger={trigger}
        isDividedToTwoColumns
      />
      <Common.Button className="max-md:mt-auto md:mx-auto" color="blue" fullWidthOnMobile>
        Continue
      </Common.Button>
    </form>
  );
};

export default Address;
