import { getAnalytics, logEvent } from 'firebase/analytics';

import { selectUser } from 'store';

import { useAppSelector } from './index';

const useAnalytics = () => {
  const { userId } = useAppSelector(selectUser);
  const analytics = getAnalytics();

  return (eventName: string, eventParams?: Record<string, unknown>) => {
    logEvent(analytics, eventName, { ...eventParams, platform: 'web', userId });
  };
};

export default useAnalytics;
