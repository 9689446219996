import React from 'react';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { MessageEvent } from 'utils/enums';
import { parseAvatar } from 'utils/helpers';

import AppointmentMessage from './AppointmentMessage';
import DefaultMessage from './DefaultMessage';
import File from './File';
import { MessageProps } from './message.types';
import Status from './Status';
import SystemMessage from './SystemMessage';

import ProviderAvatar from 'assets/icons/doc-avatar.svg';

const Message: React.FC<MessageProps> = ({ message, mobile, self, combined }) => {
  return (
    <div
      className={classNames('md:px-4', {
        'mb-1': combined,
        'ml-auto': self
      })}
      data-testid="message"
    >
      <div
        className={classNames('flex h-fit items-end', {
          'flex-row-reverse': self,
          'ml-12': !self && combined && !mobile,
          'ml-14': !self && combined && mobile,
          'mr-12': self && combined && !mobile
        })}
      >
        {!combined && (
          <Common.ProfileImage
            className={classNames('mb-[26px] block', {
              hidden: self && mobile,
              'mr-2': !self && mobile
            })}
            size="lg"
            src={
              message.senderDetails?.profileImage
                ? message.senderDetails?.profileImage
                : self
                  ? parseAvatar()
                  : ProviderAvatar
            }
          />
        )}
        <div
          className={classNames(
            'flex flex-col gap-3',
            { 'items-end': self },
            !mobile && (self ? 'mr-3' : 'ml-3')
          )}
        >
          <File combined={combined} message={message} mobile={mobile} self={self} />
          {message.event &&
          [MessageEvent.APPOINTMENT_CREATED, MessageEvent.APPOINTMENT_CANCELLED].includes(
            message.event
          ) ? (
            <AppointmentMessage combined={combined} message={message} self={self} />
          ) : message.event &&
            [MessageEvent.APPOINTMENT_MISSED, MessageEvent.APPOINTMENT_COMPLETE].includes(
              message.event
            ) ? (
            <SystemMessage combined={combined} message={message} self={self} />
          ) : (
            <DefaultMessage combined={combined} message={message} self={self} />
          )}

          <Status combined={combined} message={message} mobile={mobile} self={self} />
        </div>
      </div>
    </div>
  );
};

export default Message;
