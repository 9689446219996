import { useEffect, useState } from 'react';
import { useGetSet, useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import {
  useAddInsuranceMutation,
  useDeleteInsuranceMutation,
  useGetInsurancesQuery
} from 'services/insurance/insurance';

import { selectUser } from 'store';

import CurrentInsurance from 'pages/AccountSettings/Insurance/CurrentInsurance';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader/Loader';
import { notifySuccess } from 'shared/Toast/Toast';
import InsuranceWidget from 'widgets/Insurance';
import { FormValues } from 'widgets/Insurance/insurance.types';

import { WM_INSURANCE_VALID_PRICE_POINTS } from 'constants/pricepoints';
import { useAppSelector } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';

const Insurance = () => {
  useTitle('LifeMD - Insurance');

  const logEvent = useAnalytics();
  const { firstName, lastName, activePricePoint } = useAppSelector(selectUser);
  const [deleteInsurance] = useDeleteInsuranceMutation();
  const [addInsurance, { isLoading: isAddingInsurance }] = useAddInsuranceMutation();
  const {
    data,
    isSuccess,
    isError,
    isLoading: isGettingInsurance,
    isFetching: isFetchingInsurance,
    refetch
  } = useGetInsurancesQuery();

  const primaryInsurance = data?.data?.insurances?.find((item) => item.rank === 1);
  const secondaryInsurance = data?.data?.insurances?.find((item) => item.rank === 2);

  const [activeInsuranceID, setActiveInsuranceID] = useGetSet<string>('');
  const [step, setStep] = useState<'checking' | 'add-edit' | 'exist' | 'initial'>('initial');
  const buttonClassName = (type: boolean) =>
    classNames(
      'text-start w-full rounded-lg p-6 md:max-w-xs  transition-all',
      data?.data.hasInsurance === false && !type
        ? 'ring-2 ring-blue bg-blue-50'
        : 'border border-gray-300 hover:bg-gray-50'
    );

  const handleAddInsuranceThen = () => {
    refetch().then(() => notifySuccess('Information is updated'));
  };

  const handleClick = (type: boolean) => () => {
    if (type) {
      logEvent('insurance_have_insurance_btn_click');
      setStep('add-edit');
    } else {
      logEvent('insurance_no_insurance_btn_click');
      addInsurance({ hasInsurance: false }).unwrap().then(handleAddInsuranceThen);
    }
  };

  const handleDeleteInsuranceThen = () => {
    refetch()
      .unwrap()
      .then(({ data }) => setStep(!!data.insurances.length ? 'exist' : 'checking'));
  };

  const handleDelete = async (id: string) => {
    await deleteInsurance({ id }).unwrap().then(handleDeleteInsuranceThen);
  };

  const handleEdit = (id: string) => () => {
    setActiveInsuranceID(id);
    setStep('add-edit');
  };

  const getRank = (): 1 | 2 => {
    if (activeInsuranceID()) {
      return primaryInsurance?._id === activeInsuranceID() ? 1 : 2;
    }
    return !!primaryInsurance ? 2 : 1;
  };

  const defaultValues = (): FormValues | undefined => {
    if (!activeInsuranceID) return undefined;
    const activeInsurance = data?.data?.insurances.find((item) => item._id === activeInsuranceID());
    if (activeInsurance) {
      return {
        memberId: activeInsurance.memberId,
        rxBIN: activeInsurance.rxBIN,
        rxGroupId: activeInsurance.rxGroupId,
        rxPCN: activeInsurance.rxPCN
      };
    }
  };

  const handleClickCancel = () => {
    setStep(data?.data?.hasInsurance && data.data.insurances.length > 0 ? 'exist' : 'checking');
  };

  const handleClickContinue = () => {
    logEvent(primaryInsurance ? 'insurance_update_btn_click' : 'insurance_save_btn_click');
    refetch()
      .unwrap()
      .then(() => {
        setStep('exist');
        notifySuccess('Information is updated');
      });
  };

  useEffect(() => {
    if (isSuccess) {
      setStep(data?.data?.hasInsurance && data.data.insurances.length > 0 ? 'exist' : 'checking');
    } else if (!isGettingInsurance && isError) {
      setStep('checking');
    }
  }, [isSuccess, isGettingInsurance]);

  return (
    <FadeWrapper>
      <Loader isVisible={step === 'initial' || isFetchingInsurance || isAddingInsurance} />
      {step === 'checking' && (
        <div className="flex flex-col gap-4">
          <Common.Alert type="info" colorableBackground>
            If you have insurance we can use the details to calculate your prescription benefits.
            Your insurance will not be billed for appointments on the LifeMD platform.
          </Common.Alert>
          <h4 className="font-semibold md:mx-auto md:max-w-xs">Do you have medical insurance?</h4>
          <div className="flex w-full flex-col gap-4 max-md:py-4 md:mx-auto md:items-center">
            <button
              className={buttonClassName(true)}
              data-testid="have_insurance_btn"
              onClick={handleClick(true)}
            >
              Yes, I have insurance
            </button>
            <button
              className={buttonClassName(false)}
              data-testid="dont_have_insurance_btn"
              onClick={handleClick(false)}
            >
              No, I do not have insurance
            </button>
          </div>
        </div>
      )}
      {step === 'add-edit' && (
        <InsuranceWidget
          continueBtnText={primaryInsurance ? 'Update' : 'Save'}
          defaultFormValues={defaultValues()}
          insuranceID={activeInsuranceID()}
          rank={getRank()}
          src="my-chart"
          onCancel={handleClickCancel}
          onContinue={handleClickContinue}
        />
      )}
      {step === 'exist' && (
        <div className="flex flex-col gap-3">
          <h2 className="text-mBase font-semibold">My insurance</h2>
          {!!primaryInsurance && (
            <CurrentInsurance
              data={{ ...primaryInsurance, fullName: `${firstName} ${lastName}` }}
              isAbleToEdit={
                !!activePricePoint && !WM_INSURANCE_VALID_PRICE_POINTS.includes(activePricePoint)
              }
              onDelete={handleDelete}
              onEdit={handleEdit(primaryInsurance._id)}
            />
          )}
          {!!secondaryInsurance && (
            <CurrentInsurance
              data={{
                ...secondaryInsurance,
                fullName: `${firstName} ${lastName}`
              }}
              isAbleToEdit={
                !!activePricePoint && !WM_INSURANCE_VALID_PRICE_POINTS.includes(activePricePoint)
              }
              onDelete={handleDelete}
              onEdit={handleEdit(secondaryInsurance._id)}
            />
          )}
          {(!primaryInsurance || !secondaryInsurance) && (
            <Common.Button
              className="mt-5"
              color="white-alt"
              preIcon="plus"
              fullWidthOnMobile
              onClick={() => {
                setActiveInsuranceID('');
                setStep('add-edit');
              }}
            >
              Add another insurance
            </Common.Button>
          )}
        </div>
      )}
    </FadeWrapper>
  );
};

export default Insurance;
