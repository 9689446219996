import { FieldValues } from 'react-hook-form';
import classNames from 'classnames';

import { useResendVerifyEmailMutation } from 'services/auth/auth';

import { selectUser } from 'store';

import ResendEmailButton from 'shared/ButtonResendEmail';
import EmailInput from 'shared/form/EmailInput';
import Loader from 'shared/Loader';
import { notifySuccess } from 'shared/Toast/Toast';

import { useAppSelector } from 'hooks';
import { EmailStatus } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

import { EmailStatusType } from 'models/user.types';

const VerifyEmailInput = ({
  status,
  error,
  ...inputPropsRest
}: {
  error?: string | null;
  status: EmailStatusType;
} & FieldValues) => {
  const { accessToken, pendingEmail } = useAppSelector(selectUser);
  const [resendVerifyEmail, { isLoading }] = useResendVerifyEmailMutation();
  const verifyStatusText = 'absolute right-3 top-[42px] text-mBase md:top-[37px] md:text-base';

  const handleSendVerifyEmailClick = () => {
    resendVerifyEmail({ accessToken })
      .unwrap()
      .then((res) => {
        notifySuccess(res.message ?? 'Verification email sent successfully');
      })
      .catch(handleRequestCatch);
  };

  const renderPendingEmailInput = () => (
    <div className="relative w-full">
      <EmailInput
        {...inputPropsRest}
        className="mb-2"
        inputClassName={classNames({ '!pr-[80px]': !error })}
      />
      {!error && (
        <>
          <span
            className={classNames(verifyStatusText, 'text-yellow')}
            data-testid="pending_status"
          >
            Pending
          </span>
          <span className="text-base text-gray md:text-sm">
            We’ve sent an email to <span className="text-black">{pendingEmail}</span> to verify this
            new address. Please click the link in the email to confirm this change.{' '}
            <ResendEmailButton className="underline" onClick={handleSendVerifyEmailClick}>
              Resend email
            </ResendEmailButton>
            .
          </span>
        </>
      )}
    </div>
  );

  const renderUnverifiedEmailInput = () => (
    <div className="relative w-full">
      <EmailInput
        {...inputPropsRest}
        className="mb-2"
        inputClassName={classNames({ '!pr-[80px]': !error })}
      />
      {!error && (
        <>
          <span className={classNames(verifyStatusText, 'text-yellow')} data-testid="email_status">
            Unverified
          </span>
          <ResendEmailButton
            className="text-base text-gray underline md:text-sm"
            onClick={handleSendVerifyEmailClick}
          >
            Verify email
          </ResendEmailButton>
        </>
      )}
    </div>
  );

  const renderInput = () => {
    switch (status) {
      case EmailStatus.VERIFIED:
        return (
          <div className="relative w-full">
            <EmailInput {...inputPropsRest} inputClassName={classNames({ '!pr-[80px]': !error })} />
            {!error && (
              <span
                className={classNames(verifyStatusText, 'text-green')}
                data-testid="verified_status"
              >
                Verified
              </span>
            )}
          </div>
        );
      case EmailStatus.PENDING:
        return renderPendingEmailInput();
      case EmailStatus.UNVERIFIED:
      case EmailStatus.LEGACY:
      default:
        return renderUnverifiedEmailInput();
    }
  };

  return (
    <>
      <Loader isVisible={isLoading} />
      {renderInput()}
    </>
  );
};

export default VerifyEmailInput;
