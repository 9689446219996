import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTitle } from 'react-use';

import { useGetCartQuery } from 'services/shop/shop';

const Shop = () => {
  useTitle('LifeMD - Shop');
  useGetCartQuery();

  return <Outlet />;
};

export default Shop;
