import { JSX, ReactElement } from 'react';
import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';

import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
import {
  AllSet,
  Appointment,
  Checkout,
  Intro,
  MifQuestionOne,
  MifQuestionTwo,
  Results,
  SelectMedication
} from 'widgets/migrateFromTirzepatide';

import { PossibleStepsType } from '../migrateFormTirzepatide.types';

import { Props } from './content.types';

const renderWithAnimation = (
  component: JSX.Element,
  key: string,
  params?: {
    className?: string;
    isBackAnimation?: boolean;
  }
) => (
  <SlideAnimateWrapper
    className={classNames(
      'mx-auto w-full max-w-[500px] p-4 md:pt-8 max-md:flex-1',
      params?.className ?? ''
    )}
    isBackAnimation={!!params?.isBackAnimation}
    key={key}
  >
    {component}
  </SlideAnimateWrapper>
);

const Content: React.FC<Props> = ({
  step,
  moveToStep,
  isBackAnimation,
  userCurrentPPType,
  userCurrentPP,
  tirzepatidePP,
  ppToUpgrade,
  mifSteps,
  isSubmittingMif,
  isGettingMyAccount,
  couponCode
}) => {
  const content: { [key in PossibleStepsType[number]]: ReactElement } = {
    intro: renderWithAnimation(<Intro onContinue={() => moveToStep('next')} />, 'intro', {
      isBackAnimation
    }),
    'mif-1': renderWithAnimation(
      <MifQuestionOne
        config={mifSteps[0].config}
        onContinue={(data) => {
          moveToStep({
            step: 'mif-1',
            data
          });
        }}
      />,
      'mif-1',
      {
        isBackAnimation
      }
    ),
    'mif-2': renderWithAnimation(
      <MifQuestionTwo
        config={mifSteps[1].config}
        loading={isSubmittingMif || isGettingMyAccount}
        onContinue={(data) => {
          moveToStep({
            step: 'mif-2',
            data
          });
        }}
      />,
      'mif-2',
      {
        isBackAnimation
      }
    ),
    results: renderWithAnimation(
      <Results
        onContinue={(data) => {
          moveToStep({
            step: 'results',
            data
          });
        }}
      />,
      'results',
      { isBackAnimation }
    ),
    appointment: renderWithAnimation(
      <Appointment
        onContinue={() => {
          moveToStep({
            step: 'appointment'
          });
        }}
      />,
      'appointment',
      { isBackAnimation }
    ),
    checkout: renderWithAnimation(
      <>
        {ppToUpgrade && (
          <Checkout
            couponCode={couponCode}
            currentPP={userCurrentPP}
            newPricePoint={ppToUpgrade}
            userCurrentPPType={userCurrentPPType}
            onContinue={() => {
              moveToStep({
                step: 'checkout'
              });
            }}
          />
        )}
      </>,
      'checkout',
      { isBackAnimation }
    ),
    'select-medication': renderWithAnimation(
      <SelectMedication
        ppToUpgrade={ppToUpgrade}
        tirzepatidePP={tirzepatidePP}
        userCurrentPPType={userCurrentPPType}
        onContinue={(data) => {
          moveToStep({
            step: 'select-medication',
            data
          });
        }}
      />,
      'select-medication',
      { isBackAnimation }
    ),
    'all-set': renderWithAnimation(<AllSet />, 'all-set', { isBackAnimation })
  };

  return <AnimatePresence mode="wait">{content[step]}</AnimatePresence>;
};

export default Content;
