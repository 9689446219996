import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import AdvantageItem from 'features/AdvantageItem';

import usePartnerPatient from 'hooks/usePartnerPatient';
import { DateFormat } from 'utils/enums';
import { filterBalladHealthAdvantage, parseDecimal } from 'utils/helpers';

import { freePlanCode, getHint } from './planDetails.settings';
import { PlanDetailsProps } from './planDetails.types';

const PlanDetails: React.FC<PlanDetailsProps> = ({
  currentPlan,
  activePP,
  discount,
  rebillPrice,
  rebillPriceWithDiscount,
  rebillDate,
  nextSubscriptionRenewalAmount
}) => {
  const isBalladHealthPatient = usePartnerPatient('Ballad Health');

  const priceInfo = () => {
    if (currentPlan.planCode === freePlanCode) {
      return 'Free';
    }

    const isInsurance = activePP.categories?.includes('insurance');

    return activePP.paymentInterval.qty === 1 && activePP.paymentInterval.units === 'months'
      ? `$${parseDecimal(rebillPriceWithDiscount)}/mo ${isInsurance ? '+ appt copay' : ''}`
      : `$${parseDecimal(rebillPriceWithDiscount)}/every ${activePP.paymentInterval.qty} mo`;
  };
  const getDiscount = () => {
    if (!discount) return '';
    if (discount?.includes('%')) return `${Math.round(parseFloat(discount))}%`;
    return `$${parseDecimal(discount.replace('$', ''))}`;
  };

  const getAdvantages = () => {
    const advantages = activePP.additionalInfo ?? currentPlan.additionalInfo;
    if (isBalladHealthPatient) {
      return advantages.filter(filterBalladHealthAdvantage);
    }
    return advantages;
  };

  return (
    <div className="flex flex-col gap-4 rounded-xl border border-gray-200 bg-white p-4">
      <div className="flex flex-col justify-between">
        <h2 className="font-bold" data-testid="current_plan">
          {activePP.planName ?? currentPlan.planName}
        </h2>
        <div className="flex items-center justify-between">
          <div className="flex h-[25px] items-center">
            {!!getDiscount() && (
              <h2 className="mr-1 font-semibold text-gray-500 line-through">
                ${parseDecimal(rebillPrice)}
              </h2>
            )}
            <h2 className="font-semibold" data-testid="plan_price">
              {priceInfo()}
            </h2>
          </div>

          {!!getDiscount() && <Common.ColorTag color="secondary" text={`${getDiscount()} off`} />}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {getAdvantages().map((advantage) => (
          <AdvantageItem
            advantage={advantage}
            className="text-mSm md:text-sm"
            key={typeof advantage === 'object' ? advantage.question : advantage}
            showCustomIcon
          />
        ))}
      </div>
      <Common.Alert className="!text-base" type="info" colorableBackground>
        <span className="font-bold">Next bill:</span> ${parseDecimal(nextSubscriptionRenewalAmount)}{' '}
        on {dayjs(rebillDate).format(DateFormat.MMMM_DD_YYYY)}
      </Common.Alert>
      {!!getHint(activePP.planPricePointId) && (
        <div className="py-2 text-[11px] font-medium leading-4 text-gray-500">
          {getHint(activePP.planPricePointId)}
        </div>
      )}
      {/* <Common.Button color="blue" dataTestId="change_plan" fullWidthOnMobile onClick={onChangePlan}>
        Change plan
      </Common.Button> */}
    </div>
  );
};

export default PlanDetails;
