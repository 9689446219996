import { useForm } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';

import NewPasswordInput from 'shared/form/NewPasswordInput';

import { NewPasswordFormInput, NewPasswordFormProps } from './newPasswordForm.types';

const NewPasswordForm: React.FC<NewPasswordFormProps> = ({ onSubmit, onCancel }) => {
  const { control, handleSubmit, formState } = useForm<NewPasswordFormInput>({
    criteriaMode: 'all',
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  return (
    <form
      className="flex flex-col gap-4 rounded-2xl border bg-white p-8"
      role="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h2 className="text-lg font-semibold">Enter new password</h2>
      <NewPasswordInput
        control={control}
        label="Password"
        name="newPassword"
        placeholder="**********"
        size="md"
      />
      <div className="flex gap-2">
        <Common.Button color="blue" dataTestId="save_btn" disabled={!formState.isValid} size="sm">
          Change password
        </Common.Button>
        <Common.Button
          color="white-alt"
          dataTestId="discard_btn"
          size="sm"
          type="button"
          onClick={onCancel}
        >
          Cancel
        </Common.Button>
      </div>
    </form>
  );
};

export default NewPasswordForm;
