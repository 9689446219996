/* eslint-disable @typescript-eslint/ban-ts-comment */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';

import { MifProps, MifStateProps } from 'store/mif/mif.types';

export const initialState: MifStateProps = {
  mifInfo: {
    allergyList: [],
    additionalHealthConditions: [],
    chronicDiseases: [],
    healthConditions: [],
    medications: { prescription: [], supplements: [] },
    preferredPharmacy: '',
    socialHistory: [],
    womenHealth: {
      approxStartDate: '',
      name: '',
      startDate: ''
    }
  },
  mifPicks: {
    allergiesPick: false,
    medicationsPick: false
  },
  appointmentMif: [],
  tirzepatideMif: []
};

const mifSlice = createSlice({
  initialState,
  name: 'mif',
  reducers: {
    clearMifState: (state) => {
      state.mifInfo = initialState.mifInfo;
    },
    setMifByCompleteAccount: (state, action: PayloadAction<{ data: MifProps }>) => {
      const { data } = action.payload;
      state.mifInfo = { ...state.mifInfo, ...data };
    },
    setMifInformation: (state, action: PayloadAction<{ data: unknown; prop: keyof MifProps }>) => {
      const { data, prop } = action.payload;
      // @ts-ignore
      state.mifInfo[prop] = data;
    },
    setMifPick: (state, action: PayloadAction<Partial<MifStateProps['mifPicks']>>) => {
      state.mifPicks = { ...state.mifPicks, ...action.payload };
    },
    setAppointmentMif: (state, action: PayloadAction<MifStateProps['appointmentMif']>) => {
      const filteredArray = uniqBy(
        [...action.payload, ...(state.appointmentMif ?? [])],
        'question'
      );
      state.appointmentMif = filteredArray;
    },
    removePartOfAppointmentMifAnswers: (
      state,
      action: PayloadAction<MifStateProps['appointmentMif']>
    ) => {
      const filteredArray = state.appointmentMif.filter((i) => {
        return !action.payload.some((item) => i.question === item.question);
      });
      state.appointmentMif = filteredArray;
    },
    setTirzepatideMif: (state, action: PayloadAction<MifStateProps['tirzepatideMif']>) => {
      const filteredArray = uniqBy(
        [...action.payload, ...(state.tirzepatideMif ?? [])],
        'question'
      );
      state.tirzepatideMif = filteredArray;
    },
    clearAppointmentMif: (state) => {
      state.appointmentMif = [];
    },
    clearTirzepatideMif: (state) => {
      state.tirzepatideMif = [];
    }
  }
});

export const {
  setMifInformation,
  setMifByCompleteAccount,
  clearMifState,
  setMifPick,
  setAppointmentMif,
  clearAppointmentMif,
  setTirzepatideMif,
  clearTirzepatideMif,
  removePartOfAppointmentMifAnswers
} = mifSlice.actions;

export default mifSlice.reducer;
