import { useGetMembershipPlansQuery } from 'services/lookup/lookup';

import { selectLookup } from 'store';

import { useAppSelector } from 'hooks';
import { PlanCodes } from 'utils/enums';

export const useGetLifeMDPlusPlan = () => {
  const { membershipPlans } = useAppSelector(selectLookup);
  const { data: fetchedPlans, isFetching } = useGetMembershipPlansQuery(undefined, {
    skip: !!membershipPlans.length
  });

  const plans = fetchedPlans?.data || membershipPlans;

  const lifeMDPlusPlan = plans?.find((p) => p.planCode === PlanCodes.LifeMDPlus);
  const defaultPricePoint =
    lifeMDPlusPlan?.pricePoints?.find((p) => p.isDefault) || lifeMDPlusPlan?.pricePoints[0];

  return {
    lifeMDPlusPlan,
    defaultPricePoint,
    isFetching
  };
};
