import { parsePhoneNumber } from 'react-phone-number-input';
import dayjs from 'dayjs';

import { AddPersonalInfoReqProps, PaymentCombinedReqProps } from 'services/auth/auth.types';

import { AppointmentsState } from 'store/appointments/appointments.types';

import { PossibleMoveToStepTypes } from 'containers/CreateAppointmentExtended/Content/content.types';
import {
  CareTypes,
  FillAppointmentDataProps,
  StepName
} from 'containers/CreateAppointmentExtended/createAppointmentExtended.types';
import { buildCreditCardAttributesFromForm } from 'widgets/PaymentFormNew/paymentFormNew.settings';
import { PaymentFormFields } from 'widgets/PaymentFormNew/paymentFormNew.types';

import { DEFAULT_LAB_TESTS_APPT_CODE } from 'constants/defaults';
import { SexAtBirth } from 'utils/enums';

import { MembershipPlan, PricePoint } from 'models/plans.types';

// on some steps we need to write data about session, here we define what data we need to write
export const buildPayload = (
  type: PossibleMoveToStepTypes
): Partial<AppointmentsState['newAppointmentExtended']> | null => {
  let payload: Partial<AppointmentsState['newAppointmentExtended']> | null = null;
  if (typeof type !== 'object') {
    return payload;
  }

  switch (type.step) {
    case 'red-flags':
      if (type.data) {
        const { status, _id, plans, ...rest } = type.data;
        payload = {
          ...rest,
          ...(!!plans ? { plans } : { plans: undefined }),
          appointmentTypeId: _id
        };
      }
      break;
    case 'appointment-types-picker':
      const { status, _id, plans, ...rest } = type.data;
      payload = {
        ...rest,
        ...(!!plans ? { plans } : { plans: undefined }),
        appointmentTypeId: _id,
        ...(rest.code === DEFAULT_LAB_TESTS_APPT_CODE
          ? { src: 'shop-labs' }
          : {
              src: 'talk-to-a-doctor',
              labsResultID: undefined
            })
      };
      break;
    case 'choose-type-of-care':
      payload = { src: type.data };
      break;
    case 'details-for-provider':
      payload = { appointmentDescription: type.data.appointmentDescription, callMethod: 'video' };
      break;
    case 'create-account-address':
      payload = {
        userAddress: type.data.address,
        userCity: type.data.city,
        userState: type.data.state,
        userZip: type.data.zipCode
      };
      break;
    case 'prescriptions-type':
      const {
        status: statusFromApptType,
        _id: appointmentTypeId,
        ...restApptData
      } = type.data.apptType;
      payload = { ...restApptData, appointmentTypeId };
      break;
    case 'create-account-dob':
      payload = { userDob: type.data };
      break;
    case 'create-account-phone':
      payload = { userPhone: type.data };
      break;
    case 'create-account-personal-details':
      payload = {
        userEmail: type.data.email,
        userFirstName: type.data.firstName,
        userLastName: type.data.lastName
      };
      break;
    case 'labs-to-review':
      payload = {
        labsResultID: type.data.labsResultID !== 'another' ? type.data.labsResultID : ''
      };

      break;
    case 'qualified-for-async':
      payload = { ...type.data };
      break;
    case 'additional-information':
      payload = { files: type.data };
      break;
    case 'subscription-required':
      payload = {
        callMethod: type.data.callMethod,
        membershipData: {
          planId: type.data.planId,
          planPricePoint: type.data.pricePoint
        }
      };
      break;
    // add more conditions here
    default:
      break;
  }

  return payload;
};

// in this function we define what step should be next and set into query params
export const getNextStep = (
  type: FillAppointmentDataProps,
  {
    src,
    accessToken,
    isRequiredToUpgradeToLifeMDPlus, // to show / hide upgrade step
    shouldAllowToSeeAsyncSelectScreen, // to show / hide qualified-for-async step
    haveToPayForAppt, // to show / hide checkout step
    uploadRequired,
    code
  }: {
    accessToken: string | null;
    code?: string;
    haveToPayForAppt: boolean;
    isRequiredToUpgradeToLifeMDPlus: boolean;
    shouldAllowToSeeAsyncSelectScreen: boolean;
    src: CareTypes;
    uploadRequired?: boolean;
  }
): StepName | 'shop' | null => {
  let nextStep: StepName | 'shop' | null = null;
  switch (type.step) {
    case 'choose-type-of-care':
      nextStep = type.data === 'shop-labs' ? 'shop' : 'red-flags';
      break;
    case 'red-flags': // red flags is just a marketing page, flow is defined based on src (place from where user came)
      switch (src) {
        case 'labs-appt':
          nextStep = 'details-for-provider';
          break;
        case 'prescriptions':
          nextStep = 'prescription-for';
          break;
        case 'talk-to-a-doctor':
          nextStep = 'appointment-types-picker';
          break;
        default:
          break;
      }
      break;
    case 'appointment-types-picker': // if selected appointment type has some mif in it, we should show mif screen, otherwise just show details
      nextStep = type.data.mifCode
        ? 'mif'
        : type.data.code === DEFAULT_LAB_TESTS_APPT_CODE
          ? 'labs-to-review'
          : 'details-for-provider';
      break;
    case 'labs-to-review':
      nextStep =
        type.data.labsResultID === 'another' ? 'additional-information' : 'details-for-provider';
      break;
    case 'prescription-for':
      switch (type.data) {
        case 'new-prescription':
          nextStep = 'appointment-types-picker';
          break;
        case 'prescription-refill':
          nextStep = 'prescriptions-type';
          break;
        default:
          break;
      }
      break;
    case 'is-renew':
      nextStep = 'details-for-provider';
      break;
    case 'details-for-provider': // based on requirements after this step we may have following flows:
      // 0. if user came from labs-appt means that he need to schedule specific lab type - go to date-time
      // 1. new users (w/o accessToken) - go to create account flow
      // 2. if category allows to upload files - go to additional-information
      // 3. if user has to upgrade to LifeMD Plus to complete appt - go to subscription-required
      // 4. if user can see qualified-for-async screen - go to qualified-for-async, otherwise go to date-time to schedule
      // NOTE later probably we'll have ability to schedule async w/o mif, in this case we should add condition to go to the
      // qualified-for-async screen, based on shouldAllowToSeeAsyncSelectScreen field
      nextStep =
        src === 'labs-appt' || code === DEFAULT_LAB_TESTS_APPT_CODE
          ? !isRequiredToUpgradeToLifeMDPlus
            ? 'date-time'
            : 'subscription-required'
          : !!accessToken
            ? uploadRequired
              ? 'additional-information'
              : isRequiredToUpgradeToLifeMDPlus
                ? 'subscription-required'
                : 'date-time'
            : 'create-account-intro';
      break;
    case 'prescriptions-type':
      switch (type.data.prescriptionsValueType) {
        case 'weight-management':
          nextStep = 'is-renew';
          break;
        case 'something-else':
          nextStep = type.data.apptType.mifCode ? 'mif' : 'details-for-provider';
          break;
        default:
          break;
      }
      break;
    case 'mif': // here is next step based on requirements:
      // 1. If user needs to add files - go to additional-information
      // 2. If has no account - go to create-account-intro (we are not sending mif yet in those cases)
      // 3. If user can see qualified for async screen - go to qualified-for-async
      // 4. Based on user's plan we should go to upgrade plan screen or for the date selection
      nextStep = uploadRequired
        ? 'additional-information'
        : !accessToken
          ? 'create-account-intro'
          : shouldAllowToSeeAsyncSelectScreen
            ? 'qualified-for-async'
            : isRequiredToUpgradeToLifeMDPlus
              ? 'subscription-required'
              : 'date-time';
      break;
    case 'additional-information': // logic is the same as in mif step
      nextStep = !accessToken
        ? 'create-account-intro'
        : shouldAllowToSeeAsyncSelectScreen
          ? 'qualified-for-async'
          : isRequiredToUpgradeToLifeMDPlus
            ? 'subscription-required'
            : 'date-time';
      break;
    case 'subscription-required':
      nextStep = type.data.callMethod === 'video' ? 'date-time' : 'payment-checkout';
      break;
    case 'create-account-intro':
      nextStep = 'create-account-personal-details';
      break;
    case 'create-account-personal-details':
      nextStep = 'create-account-address';
      break;
    case 'create-account-address':
      nextStep = 'create-account-dob';
      break;
    case 'create-account-dob':
      nextStep = 'create-account-phone';
      break;
    case 'create-account-phone':
      nextStep = 'create-account-password';
      break;
    case 'create-account-password':
      nextStep = shouldAllowToSeeAsyncSelectScreen
        ? 'qualified-for-async'
        : 'subscription-required';
      break;
    case 'qualified-for-async':
      nextStep = isRequiredToUpgradeToLifeMDPlus
        ? 'subscription-required'
        : type.data.callMethod === 'video'
          ? 'date-time'
          : 'payment-checkout';
      break;
    case 'date-time':
      nextStep = haveToPayForAppt ? 'payment-checkout' : 'confirmation';
      break;
    default:
  }
  return nextStep;
};

// method to create pending (prospect) user
export const buildBodyForUserCreation = (
  data: Omit<
    AppointmentsState['newAppointmentExtended'],
    | 'accessToken'
    | 'membershipData'
    | 'src'
    | 'plans'
    | 'mifCode'
    | 'asyncAllowed'
    | 'uploadRequired'
  > & {
    password: string;
  }
): AddPersonalInfoReqProps => {
  return {
    email: data.userEmail ?? '',
    firstName: data.userFirstName ?? '',
    lastName: data.userLastName ?? '',
    dob: data.userDob ?? '',
    flow: 'lifemd-plus',
    phone: {
      countryCode: parsePhoneNumber(data.userPhone ?? '')?.country || '',
      phoneNumber: parsePhoneNumber(data.userPhone ?? '')?.nationalNumber || ''
    },
    address: data.userAddress ?? '',
    city: data.userCity ?? '',
    state: data.userState ?? '',
    zipCode: data.userZip ?? '',
    password: data.password,
    sexAtBirth: SexAtBirth.Unknown,
    timezone: dayjs.tz.guess()
  };
};

// method to create body for the payment of the new user

export const buildBodyForPayment = ({
  data,
  formData,
  lifeMDPlusPlan,
  defaultPricePoint
}: {
  data: Omit<
    AppointmentsState['newAppointmentExtended'],
    'membershipData' | 'src' | 'plans' | 'mifCode' | 'asyncAllowed' | 'uploadRequired'
  > & {
    accessToken: string;
  };
  defaultPricePoint: PricePoint;
  formData: PaymentFormFields;
  lifeMDPlusPlan: MembershipPlan;
}): PaymentCombinedReqProps => {
  const creditCardInfo = buildCreditCardAttributesFromForm(formData, 'credit_card');

  return {
    accessToken: data.accessToken,
    appointment: {
      appointmentTypeId: data.appointmentTypeId,
      doctorId: data.doctorId,
      appointmentMethod: data.callMethod,
      isAsapAppointment: false,
      appointmentDescription: data.appointmentDescription,
      bookedSlotId: data.bookedSlotId
    },
    credit_card: creditCardInfo,
    flow: 'lifemd-plus',
    subscription: {
      appointmentPricePointId: defaultPricePoint.initialAppointmentPricePointId,
      componentId: defaultPricePoint.initialAppointmentComponentId,
      planCode: lifeMDPlusPlan.planCode,
      planId: lifeMDPlusPlan._id,
      planPricePointId: defaultPricePoint.planPricePointId
    }
  };
};
