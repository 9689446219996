import React from 'react';
import { FieldValues, useController } from 'react-hook-form';
import InputMask from '@mona-health/react-input-mask';
import { Common } from '@thecvlb/design-system';

import { PATIENTS_AGE_LIMITS } from 'utils/constants';
import { DateFormat } from 'utils/enums';
import { validateBirth } from 'utils/helpers';

const BirthInput = <TFormValues extends FieldValues>({
  control,
  name,
  placeholder = DateFormat.MM_DD_YYYY,
  dataTestId = 'dob_field',
  label = 'Date of birth',
  hint,
  customErrorMessage,
  maxAgeValidation = false,
  labelDirection,
  className,
  preIcon,
  postIcon,
  size,
  maxAge
}: TFormValues) => {
  const { field, fieldState } = useController({
    control,
    defaultValue: '',
    name,
    rules: {
      required: {
        message: `Birth date is required`,
        value: true
      },
      validate: {
        isValidBirth: (val) =>
          validateBirth(
            val,
            `Unfortunately, we are currently unable to provide care for patients who are under the age of ${PATIENTS_AGE_LIMITS.MIN}, or over the age of ${maxAge || PATIENTS_AGE_LIMITS.MAX}.`,
            maxAgeValidation
          )
      }
    }
  });

  return (
    <InputMask mask="99/99/9999" type="tel" {...field} maskPlaceholder={null} ref={null}>
      <Common.Input
        className={className}
        dataTestId={dataTestId}
        errors={fieldState.error}
        helper={customErrorMessage || fieldState.error?.message}
        hint={hint}
        label={label}
        labelDirection={labelDirection}
        placeholder={placeholder}
        postIcon={postIcon}
        preIcon={preIcon}
        ref={field.ref}
        size={size}
        type="tel"
      />
    </InputMask>
  );
};

export default BirthInput;
