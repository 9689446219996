import { useEffect } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';

import { useCheckFreeAppointmentsMutation } from 'services/appointments/appointments';
import {
  useGetCreditBalanceQuery,
  useGetCreditCardInformationQuery,
  useLazyPreviewChangeSubscriptionQuery,
  useUpdateDefaultPaymentMethodMutation
} from 'services/myAccount/myAccount';

import { selectUser } from 'store';

import PaymentMethods from 'features/PaymentMethods/PaymentMethods';
import EncryptedBadge from 'shared/EncryptedBadge';
import Loader from 'shared/Loader';
import { notifySuccess } from 'shared/Toast/Toast';
import AppointmentCard from 'widgets/PaymentCheckout/AppointmentCard';
import UpdatePlanCard from 'widgets/UpdatePlanCard';

import { useAppSelector } from 'hooks';
import { PlanCodes } from 'utils/enums';
import { getPaymentNumbers, getPricePoint, handleRequestCatch, parseDecimal } from 'utils/helpers';

import { PaymentMethod } from 'models/payments.types';

import AcceptedInsurance from './AcceptedInsurance';
import {
  getAppointmentCost,
  getFixedFinalPrice,
  getMemberShipInfoForAppointmentCard
} from './paymentCheckout.settings';
import { PaymentCheckoutProps } from './paymentCheckout.types';

const PaymentCheckout: React.FC<PaymentCheckoutProps> = ({
  onProceed,
  selectedPlan,
  existingPlan,
  category,
  time,
  couponCode,
  providerName,
  loading,
  freeAppointmentInfo,
  selectedPricePoint,
  newPlanSubtitle,
  isUpdatingRecommendation,
  isLifeMDPlusFlow,
  callMethod,
  isAsync,
  isRefillAppt = false
}) => {
  const { activePricePoint } = useAppSelector(selectUser);
  const [enableToChange, toggleEnableToChange] = useToggle(
    existingPlan?.planCode !== PlanCodes.WeightManagementMembership ||
      selectedPlan.planCode === PlanCodes.WeightManagementMembership
  );
  const [updateDefaultPaymentMethod, { isLoading: isUpdatingDefaultPaymentMethod }] =
    useUpdateDefaultPaymentMethodMutation();

  const [checkFreeAppointments, { data: ins, isLoading: isGettingPaymentData }] =
    useCheckFreeAppointmentsMutation();
  const insuranceCoverageData = ins?.data?.insuranceEligibility;
  const noPaymentNow = !!ins?.data?.isFree && !!ins?.data?.insuranceEligibility;
  const isAccepted =
    insuranceCoverageData?.status &&
    ['ACTIVE', 'ACTIVE_AT_RISK'].includes(insuranceCoverageData?.status);
  const isDeclined =
    insuranceCoverageData?.status &&
    ['INACTIVE', 'UNKNOWN'].includes(insuranceCoverageData?.status);

  const isPercentageDiscount =
    typeof insuranceCoverageData?.coinsurance === 'number' &&
    insuranceCoverageData?.coinsurance > 0;

  const fixedFinalPrice = getFixedFinalPrice(insuranceCoverageData?.finalCost);

  const { data: balance } = useGetCreditBalanceQuery(undefined, {
    skip: !activePricePoint
  });
  const { data, isFetching, isLoading, refetch } = useGetCreditCardInformationQuery(
    {
      isInsuranceSubscription: !!isAccepted
    },
    {
      skip: !activePricePoint
    }
  );
  const isChangingPlan =
    selectedPlan.planCode !== existingPlan.planCode ||
    (selectedPricePoint && selectedPricePoint.planPricePointId !== activePricePoint);

  const [
    getPreviewChangeSubscription,
    { data: previewChangeSubscription, isLoading: previewChangeSubscriptionLoading }
  ] = useLazyPreviewChangeSubscriptionQuery();
  const creditBalance = balance?.data.creditBalance;
  const discountAmount = previewChangeSubscription?.data?.newDiscount?.discountAmount;
  const planCost = !discountAmount
    ? selectedPricePoint?.totalCost
    : Number(selectedPricePoint?.totalCost || 0) - Number(discountAmount || 0);

  const existingPricePoint =
    isLifeMDPlusFlow && isChangingPlan
      ? selectedPricePoint
      : getPricePoint(existingPlan.pricePoints, undefined, activePricePoint);

  const plansWithPaymentPerAppointment = [
    PlanCodes.InitialAppointment,
    PlanCodes.FlexCare,
    PlanCodes.LifeMDMembership,
    PlanCodes.TotalCareMembership,
    PlanCodes.UrgentCare
  ];

  const appointmentCost = getAppointmentCost(existingPricePoint, isAsync, isRefillAppt);

  const finalCost = isAccepted
    ? fixedFinalPrice
    : !isChangingPlan
      ? appointmentCost
      : plansWithPaymentPerAppointment.includes(selectedPlan.planCode as PlanCodes)
        ? selectedPricePoint?.subsequentAppointmentCost
        : selectedPricePoint?.totalCost;
  const { finalPrice } = getPaymentNumbers(
    Number(previewChangeSubscription?.data.paymentDue) > 0
      ? previewChangeSubscription?.data.paymentDue
      : finalCost,
    discountAmount,
    creditBalance,
    planCost,
    isLifeMDPlusFlow
  );

  const membershipInfo = getMemberShipInfoForAppointmentCard(
    isLifeMDPlusFlow,
    selectedPricePoint,
    previewChangeSubscription,
    isChangingPlan,
    isUpdatingRecommendation,
    selectedPlan.planName
  );
  const finalPriceValue = membershipInfo !== undefined ? membershipInfo.paymentDue : finalPrice;

  const submitBtnTitle =
    isChangingPlan && !isLifeMDPlusFlow
      ? 'Confirm plan change'
      : noPaymentNow
        ? 'Confirm'
        : freeAppointmentInfo?.isFree || finalPriceValue
          ? `Confirm $${freeAppointmentInfo?.isFree ? '0' : parseDecimal(finalPriceValue)} payment`
          : 'Confirm';

  const isDisabled =
    isUpdatingDefaultPaymentMethod ||
    loading ||
    isFetching ||
    !enableToChange ||
    previewChangeSubscriptionLoading ||
    isGettingPaymentData;

  const handleFavoritePaymentMethod = async (id: string, cb?: () => void) => {
    if (!id) return;

    try {
      const response = await updateDefaultPaymentMethod({
        id,
        isInsuranceSubscription: !!isAccepted
      }).unwrap();
      await refetch().unwrap();
      notifySuccess(response.message ?? 'Updated default payment method');
      cb?.();
    } catch (e) {
      handleRequestCatch(e as MessageEvent);
    }
  };
  const isFinalPriceMoreThanZero = parseInt(finalPriceValue) > 0;

  const getPaymentMethods = (): PaymentMethod[] => {
    return (
      data?.data?.map((paymentMethod) => ({
        ...paymentMethod,
        type: isAccepted ? 'appointments' : 'membership'
      })) ?? []
    );
  };

  useEffect(() => {
    !!time && !!activePricePoint && checkFreeAppointments({ appointmentStartTime: time }).unwrap();
    if (!selectedPricePoint || !isChangingPlan) return;
    const appointmentCost = isAsync
      ? isRefillAppt
        ? selectedPricePoint.asyncMedicationRefillCost
        : selectedPricePoint.asyncAppointmentCost
      : selectedPricePoint.subsequentAppointmentCost;

    const options = {
      planId: selectedPlan._id,
      planPricePointId: selectedPricePoint.planPricePointId,
      // Set the additional charge in cents
      ...(isLifeMDPlusFlow &&
        appointmentCost && {
          additionalCharge: -appointmentCost * 100
        }),
      ...(couponCode && { couponCode })
    };

    getPreviewChangeSubscription(options);
  }, [selectedPricePoint]);

  const headingClassName = 'font-bold text-gray-700 md:hidden';

  const generalLoadingState = isLoading || previewChangeSubscriptionLoading || isGettingPaymentData;

  return (
    <>
      <Loader isVisible={generalLoadingState} />
      <div className="mx-auto flex size-full max-w-[500px] flex-col px-6 md:rounded-xl md:bg-white">
        {!!insuranceCoverageData && isDeclined ? (
          <span className="block h-full place-content-center text-center text-mXl font-semibold">
            There is some problems with your insurance,
            <br />
            please reach out to our support team
          </span>
        ) : (
          <div className="mx-auto flex w-full max-w-[500px] flex-col gap-4 max-md:h-full md:gap-8 md:py-8">
            <h1 className="large-title hidden md:block" data-testid="heading">
              {isFinalPriceMoreThanZero || generalLoadingState
                ? 'Confirm and Pay'
                : isChangingPlan
                  ? 'Confirm change'
                  : 'Confirm'}
            </h1>
            <h2 className={headingClassName}>Items</h2>
            {!!isAccepted && <AcceptedInsurance insuranceCoverageData={insuranceCoverageData} />}
            {(isChangingPlan || isUpdatingRecommendation) && !isLifeMDPlusFlow ? (
              <>
                {previewChangeSubscription?.data && selectedPricePoint ? (
                  <UpdatePlanCard
                    creditBalance={creditBalance}
                    oldPlan={
                      existingPricePoint
                        ? {
                            planName: existingPlan?.planName || '',
                            pricePoint: existingPricePoint
                          }
                        : undefined
                    }
                    plan={{
                      planName: newPlanSubtitle || selectedPlan.planName,
                      pricePoint: selectedPricePoint
                    }}
                    previewChangeSubscription={previewChangeSubscription.data}
                  />
                ) : null}
              </>
            ) : !!existingPricePoint ? (
              <AppointmentCard
                appointmentCost={appointmentCost}
                callMethod={callMethod}
                category={category}
                copayInfo={
                  !!insuranceCoverageData
                    ? {
                        copayAmount: fixedFinalPrice,
                        isPercentageDiscount: isPercentageDiscount
                      }
                    : undefined
                }
                creditBalance={creditBalance}
                discount={discountAmount}
                freeAppointmentInfo={freeAppointmentInfo}
                isLifeMDPlusFlow={isLifeMDPlusFlow}
                isLoading={generalLoadingState}
                membershipInfo={membershipInfo}
                noPaymentNow={noPaymentNow}
                providerName={providerName}
                time={time}
              />
            ) : (
              <span>Error, please reach out to our support team</span>
            )}
            {(previewChangeSubscription?.data.migrationHasPayment || isFinalPriceMoreThanZero) && (
              <div className="flex flex-col gap-4">
                <h2 className="hidden text-xl font-bold text-primary-700 md:block">
                  Payment method
                </h2>
                <h2 className={headingClassName}>Payment</h2>
                {!isLoading && (
                  <PaymentMethods
                    isInsuranceUser={!!isAccepted}
                    isLoading={isFetching}
                    paymentMethods={getPaymentMethods()}
                    onAddMethod={refetch}
                    onUpdateFavoritePaymentMethod={handleFavoritePaymentMethod}
                  />
                )}
              </div>
            )}
            {existingPlan?.planCode === PlanCodes.WeightManagementMembership &&
              selectedPlan.planCode !== PlanCodes.WeightManagementMembership && (
                <Common.Checkbox checked={enableToChange} onChange={() => toggleEnableToChange()}>
                  I understand that I will lose access to my Weight Management program.
                </Common.Checkbox>
              )}
            <div className="mt-auto flex flex-1 flex-col items-center gap-4 max-md:justify-end">
              <Common.Button
                color="blue"
                dataTestId="confirm_btn"
                disabled={isDisabled}
                isLoading={loading}
                fullWidthOnMobile
                onClick={() => onProceed()}
              >
                {submitBtnTitle}
              </Common.Button>
              <EncryptedBadge />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentCheckout;
