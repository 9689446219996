import { useParams } from 'react-router-dom';

import { useGetAppointmentQuery } from 'services/appointments/appointments';

import Loader from 'shared/Loader';

import AsyncAppointment from './components/AsyncAppointment';
import RegularAppointment from './components/RegularAppointment';

const Appointment = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { data, isLoading } = useGetAppointmentQuery(id);

  const appointment = data?.data;

  if (isLoading) return <Loader isVisible />;

  return appointment?.appointmentMethod === 'message' ? (
    <AsyncAppointment appointment={appointment} />
  ) : (
    <RegularAppointment appointment={appointment} />
  );
};

export default Appointment;
