import { createBreakpoint } from 'react-use';

import { TAILWIND_BREAKPOINTS } from 'utils/constants';
import { ScreenSizes } from 'utils/enums';
const useBreakpoint = createBreakpoint(TAILWIND_BREAKPOINTS);

const useWidth = () => {
  const breakpoint = useBreakpoint() as ScreenSizes;
  const isMobile = ['xs', 'sm'].includes(breakpoint);
  const isTablet = breakpoint === 'md';

  return { breakpoint, isMobile, isTablet };
};

export default useWidth;
