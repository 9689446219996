import { Common } from '@thecvlb/design-system';

const ChangePasswordSuccess: React.FC<{
  onClickAgain: () => void;
  onClickDone: () => void;
}> = ({ onClickAgain, onClickDone }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-4 rounded-2xl border bg-white p-8">
      <Common.Icon
        className="size-20 rounded-full bg-secondary-100 p-4 text-secondary"
        name="check"
      />
      <h2 className="text-xl font-bold text-primary-700">Password changed!</h2>
      <p className="text-center text-base text-primary-700">
        Awesome. You’ve successfully updated your password.
      </p>
      <div className="flex w-full justify-center gap-2">
        <Common.Button color="blue" dataTestId="done_btn" size="sm" onClick={onClickDone}>
          Done
        </Common.Button>
        <Common.Button
          color="white-alt"
          dataTestId="change_again_btn"
          size="sm"
          type="button"
          onClick={onClickAgain}
        >
          Change again
        </Common.Button>
      </div>
    </div>
  );
};

export default ChangePasswordSuccess;
