import { useState } from 'react';
import { Common } from '@thecvlb/design-system/lib/src';

import RadioGroup from 'shared/RadioGroup';

import { SelectMedicationProps } from './selectMedication.types';

const SelectMedication: React.FC<SelectMedicationProps> = ({
  ppToUpgrade,
  onContinue,
  tirzepatidePP,
  userCurrentPPType
}) => {
  const isBundle = userCurrentPPType === 'bundle';

  const ITEMS = [
    {
      label: isBundle ? 'Tirzepatide plan' : 'Tirzepatide maintenance',
      value: tirzepatidePP?.planPricePointId || '',
      description: 'No longer available',
      disabled: true,
      subLabel: <span className="text-gray">${tirzepatidePP?.monthlyPrice}/mo</span>
    },
    {
      subLabel: (
        <span className={isBundle ? 'text-green' : 'text-gray-700'}>
          {isBundle && (
            <span className="mr-1 text-gray line-through">${ppToUpgrade?.monthlyPrice}</span>
          )}
          {`$${Number(ppToUpgrade?.monthlyPrice) - (isBundle ? 100 : 0)}/mo`}
        </span>
      ),
      label: isBundle
        ? 'Semaglutide bundled plan (Medication included)'
        : 'Semaglutide maintenance',
      value: ppToUpgrade?.planPricePointId || ''
    },
    ...(isBundle
      ? []
      : [
          {
            label: 'Discuss with my provider',
            value: 'appointment',
            description: 'You’ll create an appointment at your next renewal.'
          }
        ])
  ];
  const [selected, setSelected] = useState(ITEMS[1].value);

  const handleContinue = () => {
    onContinue(selected !== 'appointment');
  };

  return (
    <div className="flex h-full grow flex-col">
      <div className="flex flex-col gap-4 py-6">
        <div className="flex flex-col items-center gap-2 py-6 text-center">
          <h3 className="text-m2xl font-bold text-primary-700 md:text-2xl">
            Please choose how you want to continue
          </h3>
          <span>
            We recommend the bundled compounded semaglutide plan, which includes care and the cost
            of medication.
          </span>
        </div>
        <div className="flex flex-col gap-3">
          <RadioGroup
            items={ITEMS}
            selectedItem={selected}
            wrapperClassName={isBundle ? 'flex-col-reverse' : ''}
            onSelect={setSelected}
          />
        </div>
      </div>
      <div className="flex flex-col items-center gap-3 max-md:mt-auto md:mx-auto">
        <Common.Button color="blue" fullWidthOnMobile onClick={handleContinue}>
          Continue
        </Common.Button>
        {isBundle && (
          <Common.Button color="white-alt" fullWidthOnMobile onClick={() => onContinue(false)}>
            Discuss with my provider
          </Common.Button>
        )}
      </div>
    </div>
  );
};

export default SelectMedication;
