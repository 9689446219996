import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';
import { Participant, VideoPlayer } from '@zoom/videosdk';
import classNames from 'classnames';

import VoiceIndicator from 'widgets/zoom/VoiceIndicator';

import { useZoomCall } from 'hooks';
import useWidth from 'hooks/useWidth';
import { PathName } from 'utils/enums';

const RemoteParticipants = () => {
  const {
    connectionLost,
    room,
    isMinimize,
    toggleIsMinimize,
    isOpenChat,
    appointment,
    isFullscreen,
    participantDisconnected,
    toggleParticipantDisconnected,
    resetValuesToDefault
  } = useZoomCall();

  const navigate = useNavigate();
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [remoteParticipant, setRemoteParticipant] = useState<Participant | null>(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const { isMobile } = useWidth();
  const isMinimizeOrMobile = isMinimize || (isMobile && isOpenChat);
  const stream = room?.getMediaStream();

  const handlePeerVideoStateChange = ({ action }: { action: string }) =>
    setVideoEnabled(action === 'Start');

  const leaveFromTheCall = () => {
    navigate(PathName.Home);
    stream?.stopVideo();
    stream?.stopAudio();
    room?.leave();
    resetValuesToDefault();
  };

  const handleUserAdded = (data: Participant[]) => {
    const isUserAlreadyConnected = data.find(
      (el) => el.userIdentity === room?.getCurrentUserInfo()?.userIdentity
    );
    if (isUserAlreadyConnected) {
      leaveFromTheCall();
      return;
    }
    toggleParticipantDisconnected(false);
    setRemoteParticipant(data[0]);
  };

  const handleActiveSpeaker = (payload: Participant[]) => {
    const user = payload.find((el) => el.userId !== room?.getCurrentUserInfo()?.userId);
    if (!user) return;
    setIsSpeaking(true);
    setTimeout(() => {
      setIsSpeaking(false);
    }, 1000);
  };

  const renderVideo = () => {
    if (!remoteParticipant) return;
    // const videoElement = document.querySelector('#remote-video') as HTMLCanvasElement;
    // videoEnabled
    //   ? stream?.renderVideo(videoElement, remoteParticipant.userId, 1920, 1080, 0, 0, 3)
    //   : stream?.stopRenderVideo(videoElement, remoteParticipant.userId);
    const remoteVideo = document.querySelector('#remote-video');
    videoEnabled
      ? stream?.attachVideo(remoteParticipant.userId, 3).then((userVideo) => {
          remoteVideo?.appendChild(userVideo as VideoPlayer);
        })
      : stream?.detachVideo(remoteParticipant.userId).then(() => {
          remoteVideo?.querySelector('video-player')?.remove();
        });
  };

  useEffect(renderVideo, [videoEnabled, remoteParticipant]);

  useEffect(() => {
    room
      ?.getAllUser()
      .filter((participant) => participant.userId !== room?.getCurrentUserInfo()?.userId)
      .forEach(setRemoteParticipant);

    room?.on('peer-video-state-change', handlePeerVideoStateChange);
    room?.on('user-added', handleUserAdded);
    room?.on('active-speaker', handleActiveSpeaker);
    return () => {
      room?.off('peer-video-state-change', handlePeerVideoStateChange);
      room?.off('user-added', handleUserAdded);
      room?.off('active-speaker', handleActiveSpeaker);
    };
  }, []);

  useEffect(() => {
    remoteParticipant && setVideoEnabled(remoteParticipant.bVideoOn);
  }, [remoteParticipant]);

  const videoPlayer = (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <video-player-container
      className="video-call-bg size-full"
      data-testid="video_screen"
      id="remote-video"
    />
  );

  return (
    <div
      className={classNames('flex h-full items-center justify-center', {
        'max-md:border-[3px] max-md:border-red max-md:bg-red': isMinimizeOrMobile,
        'w-full': isFullscreen
      })}
    >
      {isMinimizeOrMobile && (
        <button className="h-full md:hidden" onTouchStart={toggleIsMinimize}>
          <Common.Icon className="text-white" name="arrow-left" />
        </button>
      )}
      <div
        className={classNames('relative size-full', {
          'overflow-hidden rounded-xl': isMinimizeOrMobile
        })}
      >
        {/*<canvas*/}
        {/*  className="video-call-bg size-full object-cover"*/}
        {/*  data-testid="video_screen"*/}
        {/*  height="1080"*/}
        {/*  id="remote-video"*/}
        {/*  width="1920"*/}
        {/*/>*/}
        {videoPlayer}
        {participantDisconnected ? (
          <h2
            className={classNames(
              'video-call-bg absolute inset-0 flex items-center justify-center font-bold text-white',
              isOpenChat && isMobile ? 'px-px text-xs' : 'px-9 text-xl'
            )}
          >
            {appointment?.staffName} left the call
          </h2>
        ) : (
          <>
            {!videoEnabled && <VoiceIndicator isSpeaking={isSpeaking} />}
            {!remoteParticipant && !isMinimizeOrMobile && (
              <h2 className="video-call-bg absolute inset-0 flex items-center justify-center px-9 text-xl font-bold text-white">
                Waiting for {appointment?.staffName} to arrive...
              </h2>
            )}
          </>
        )}
        {connectionLost && (
          <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-900 text-white">
            <Common.Icon
              className="mx-auto mb-2 size-[64px] animate-spin rounded-full bg-gray-800 p-4"
              name="loader"
            />
            <b>You lost your network connection</b>
            <p>Trying to reconnect...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default RemoteParticipants;
