import { useEffect, useState } from 'react';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';

import NetworkQualityLevel from 'widgets/zoom/NetworkQualityLevel';

import { useZoomCall } from 'hooks';
import useWidth from 'hooks/useWidth';

const Header = () => {
  const { isOpenChat, isMinimize, toggleIsMinimize, appointment, toggleIsOpenChat } = useZoomCall();
  const [callTime, setCallTime] = useState('00:00');
  const { isMobile } = useWidth();

  const startTimer = () => {
    const startTime = dayjs();
    const id = setInterval(() => {
      const minutes = dayjs().diff(startTime, 'm');
      const seconds = dayjs().diff(dayjs(startTime), 's') - minutes * 60;

      setCallTime(`${`${minutes}`.padStart(2, '0')}:${`${seconds}`.padStart(2, '0')}`);
    }, 1000);

    return () => clearInterval(id);
  };

  useEffect(startTimer, []);

  const headerBackgroundStyle =
    isOpenChat && isMobile
      ? { backdropFilter: 'blur(14px)', webkitBackdropFilter: 'blur(14px)' }
      : {
          background: isMobile
            ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.80) -15.5%, rgba(0, 0, 0, 0.60) 46.06%, rgba(0, 0, 0, 0) 100%)'
            : 'linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0) 100%)'
        };

  return (
    <div
      className={classNames(
        'absolute inset-x-0 flex items-center justify-between px-5 py-3 md:p-4',
        { hidden: isMinimize, 'md:pr-[520px]': isOpenChat },
        isOpenChat && isMobile ? 'z-30' : 'z-20'
      )}
      style={headerBackgroundStyle}
    >
      <button
        className="flex items-center gap-2 text-start"
        onClick={isMobile ? () => {} : toggleIsMinimize}
        onTouchStart={() => (isOpenChat ? toggleIsOpenChat() : toggleIsMinimize())}
      >
        <Common.Icon
          className={classNames('size-6 text-white', {
            invisible: isMobile && isOpenChat
          })}
          name="arrow-left"
        />
        <Common.ProfileImage
          className={classNames({
            invisible: isMobile && isOpenChat
          })}
          size="lg"
          src={appointment?.staffImage}
        />
        <div>
          <h2
            className={classNames('text-mLg font-bold md:text-lg md:text-white', {
              'text-white': !isOpenChat
            })}
          >
            {appointment?.staffName}
          </h2>
          <span
            className={classNames(
              'text-mSm font-semibold md:text-sm',
              isOpenChat && isMobile ? 'text-gray' : 'text-gray-300'
            )}
          >
            {callTime}
          </span>
        </div>
      </button>
      <NetworkQualityLevel />
    </div>
  );
};

export default Header;
