import { Common } from '@thecvlb/design-system/lib/src';

import { NotOnThisListProps } from './notOnThisList.types';

const NotOnThisList: React.FC<NotOnThisListProps> = ({ btnText, onClick }) => {
  return (
    <div className="flex items-center justify-center gap-2 align-middle">
      <span className="text-mSm font-semibold">Not on this list?</span>
      <Common.Button
        color="white-alt"
        dataTestId="be_seen_smth_else_btn"
        size="sm"
        onClick={onClick}
      >
        {btnText}
      </Common.Button>
    </div>
  );
};

export default NotOnThisList;
