import { useState } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';

import { LINK_TO_FAQ } from 'constants/externalLinks';
import useAnalytics from 'hooks/useAnalytics';

import { DetailsForProvidersProps } from './detailsForProvider.types';

const DetailsForProviders: React.FC<DetailsForProvidersProps> = ({
  onClickNext,
  appointmentDescription,
  category,
  title = 'Please add details for your provider.',
  placeholder
}) => {
  const logEvent = useAnalytics();
  const [agreeNextStep, toggleAgreeNextStep] = useToggle(false);

  const [details, setDetails] = useState(appointmentDescription);
  const checkboxLabels = ['Medication refills', 'Aches & Pains (Head, ear, back, Mouth etc.)'];
  const displayCheckbox = checkboxLabels.includes(category || '');
  const textareaPlaceholder =
    placeholder ||
    (category === 'Medication refills'
      ? 'What prescription are you looking to refill?'
      : 'What brings you in today?');

  const handleChangeCheckbox = () => {
    logEvent('appt_type_hint_chbox_click');
    toggleAgreeNextStep();
  };

  const link = (
    <a
      className="text-base underline"
      href={LINK_TO_FAQ}
      target="_blank"
      onClick={() => logEvent('appt_type_faq_link_click')}
    >
      FAQ
    </a>
  );

  return (
    <div className="flex h-full grow flex-col justify-between">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <Common.ColorTag color="primary" icon="check" text={category} />
          <h1 className="text-m2xl font-bold text-primary-700">{title}</h1>
        </div>
        <Common.TextArea
          className="w-full"
          dataTestId="details_textArea"
          placeholder={textareaPlaceholder}
          rows={3}
          value={details}
          onChange={(e) => setDetails(e.target.value)}
        />
        {displayCheckbox ? (
          <Common.Checkbox checked={agreeNextStep} color="blue" onChange={handleChangeCheckbox}>
            <span data-testid="alert_banner">
              I understand that LifeMD does not provide prescriptions or refills for controlled
              substances such as benzodiazepines, narcotics or amphetamines. Read more in our&nbsp;
              {link}.
            </span>
          </Common.Checkbox>
        ) : (
          <Common.Alert type="info" colorableBackground>
            <span className="text-base">
              LifeMD does not provide prescriptions or refills for controlled substances such as
              benzodiazepines, narcotics or amphetamines. Read more in our&nbsp;
              {link}.
            </span>
          </Common.Alert>
        )}
      </div>
      <Common.Button
        className="mx-auto mt-4"
        color="blue"
        dataTestId="continue_btn"
        disabled={displayCheckbox && !agreeNextStep}
        fullWidthOnMobile
        onClick={() => onClickNext(details)}
      >
        Continue
      </Common.Button>
    </div>
  );
};

export default DetailsForProviders;
