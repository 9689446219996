import React, { useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';
import { Common } from '@thecvlb/design-system';
import HTMLReactParser from 'html-react-parser';

import { useGetSignableDocumentsQuery } from 'services/general/general';

import Loader from 'shared/Loader';

const MedicaidWaiver: React.FC<{ loading?: boolean; onContinue: (v: string) => void }> = ({
  onContinue,
  loading
}) => {
  const { data, isFetching } = useGetSignableDocumentsQuery();

  const [isRead, toggleRead] = useState(false);
  const [isAgreed, toggle] = useState(false);

  const endOfAgreement = useRef<HTMLSpanElement>(null);

  const intersection = useIntersection(endOfAgreement as React.RefObject<HTMLElement>, {
    root: null,
    rootMargin: '0px',
    threshold: 1
  });

  const document = data?.data.find((item) => item.type === 'medicaid-waiver-terms');

  useEffect(() => {
    if (intersection?.isIntersecting && !!document) {
      toggleRead(true);
    }
  }, [intersection?.isIntersecting]);

  return (
    <div className="flex h-full flex-col gap-4">
      <Loader isVisible={isFetching} />
      <h1 className="text-2xl font-bold" data-testid="header">
        Agreement for Self-Payment of Services
      </h1>
      <Common.Alert type="info" colorableBackground>
        To continue, please scroll down and check the box to agree to terms.
      </Common.Alert>
      <div
        className="max-h-[350px] overflow-auto rounded-xl border bg-white p-3 text-mBase text-gray-600 md:text-base"
        data-testid="agreement_block"
      >
        {HTMLReactParser(document?.html || '')}
        <span ref={endOfAgreement}></span>
      </div>
      <Common.Checkbox
        checked={isAgreed}
        color="blue"
        disabled={!isRead || isFetching}
        onChange={() => toggle((p) => !p)}
      >
        I acknowledge and agree to the statements written above.
      </Common.Checkbox>
      <Common.Button
        className="mx-auto mt-auto"
        color="blue"
        dataTestId="continue_btn"
        disabled={!isAgreed || isFetching}
        isLoading={loading}
        fullWidthOnMobile
        onClick={() => onContinue(document?.documentId || '')}
      >
        Continue
      </Common.Button>
    </div>
  );
};

export default MedicaidWaiver;
