import { Fragment } from 'react';
import classNames from 'classnames';

import { ShippingProgressProps } from './shippingProgress.types';

const ShippingProgress: React.FC<ShippingProgressProps> = ({ phases, className }) => {
  const activeStepItem = [...phases].reverse().find((i) => i.completed);
  const activeStep = phases.findIndex((i) => i.label === activeStepItem?.label);

  return (
    <div className={`flex ${className}`}>
      {phases.map((item, index) => (
        <Fragment key={item.label}>
          <div className="flex flex-col items-center">
            <div
              className={classNames(
                'z-20 mx-auto size-[15px] rounded-full',
                item.completed ? 'bg-primary-400' : 'border border-gray',
                {
                  'ring-[5px] !bg-primary ring-primary-100': index === activeStep
                }
              )}
              data-testid="progress_circle"
            />
            <p
              className={classNames(
                'absolute mt-4 text-[9px] font-semibold',
                item.completed ? 'text-primary-300' : 'text-gray',
                {
                  '!text-primary': index === activeStep
                }
              )}
              data-testid={`progress_label_${item.label}`}
            >
              {item.label}
            </p>
          </div>
          {index < phases.length - 1 && (
            <div className="w-full self-center border border-dashed border-primary-300" />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default ShippingProgress;
