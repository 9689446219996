import Countdown, { CountdownRenderProps, zeroPad } from 'react-countdown';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import { selectOrchestrate } from 'store';

import { ReducedSignupStepProps } from 'containers/SignUp/Content/content.types';

import { useAppSelector } from 'hooks';
import useHoldTime from 'hooks/useHoldTime';
import { DateFormat, FlowTypes } from 'utils/enums';

const AppointmentConfirmation: React.FC<
  ReducedSignupStepProps & { selectedFlow: FlowTypes | 'authorized' }
> = ({ moveToStep, selectedFlow }) => {
  const { appointment } = useAppSelector(selectOrchestrate);

  const difference = useHoldTime();

  return (
    <div className="flex flex-col items-center gap-2 max-md:h-full md:mx-auto md:max-w-[500px] md:gap-6">
      <Common.Illustration name="success" />
      <h2 className="wm-signup-title max-w-[400px] text-center" data-testid="header">
        Great! We’re holding your appointment for 10 minutes.
      </h2>
      {difference > 0 && (
        <Countdown
          date={new Date().getTime() + difference * 1000}
          renderer={({ minutes, seconds }: CountdownRenderProps) => (
            <div
              className="w-[127px] rounded-xl bg-white px-4 py-2 text-center text-mLg font-bold tracking-[4.5px] shadow-lg md:py-2"
              data-testid="timer"
            >
              {zeroPad(minutes)}:{zeroPad(seconds)}
            </div>
          )}
        />
      )}
      <p className="text-center text-primary-700 max-md:mb-4 md:mx-4 md:font-semibold">
        Confirm your appointment slot on the next screen by providing your payment details to
        complete the checkout process.
      </p>
      <div className="flex w-full flex-col gap-2">
        <p className="text-center text-mSm font-bold text-gray md:text-sm">
          Your appointment time is set for:
        </p>
        <Common.Appointment
          description={dayjs(appointment.appointmentTime?.startTime).format(
            DateFormat.MMM_D_h_mma_z
          )}
          startTime={appointment.appointmentTime?.startTime || undefined}
          title={`${selectedFlow === FlowTypes.TripleTherapy ? '' : 'GLP-1 '}Weight Management`}
          type="default"
        />
      </div>
      <Common.Button
        className="max-md:mt-auto"
        color="blue"
        preIcon="check"
        fullWidthOnMobile
        onClick={() => moveToStep('next')}
      >
        Finish signup
      </Common.Button>
    </div>
  );
};

export default AppointmentConfirmation;
