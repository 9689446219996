import { createContext, PropsWithChildren, useEffect } from 'react';
import { Socket } from 'socket.io-client';

import { useLazyGetAppointmentsQuery } from 'services/appointments/appointments';

import { setUser } from 'store/user/userSlice';

import { notifyError } from 'shared/Toast/Toast';

import { useAppDispatch } from 'hooks';
import { AppointmentListTypes } from 'utils/enums';

import {
  AppointmentUpdateProps,
  CommonSocketContextParams,
  PatientDoctorUpdateProps
} from './commonSocketContext.types';

const CommonSocketContext = createContext<CommonSocketContextParams>({});

export const CommonSocketProvider: React.FC<PropsWithChildren<{ socket: Socket }>> = ({
  children,
  socket
}) => {
  const [getAppointments] = useLazyGetAppointmentsQuery();
  const dispatch = useAppDispatch();

  const handleSetAppointmentUpdate = (appointment: AppointmentUpdateProps) => {
    getAppointments({ appointmentListType: AppointmentListTypes.UPCOMING });
    if (appointment.appointmentStatus === 'cancelled') {
      notifyError('This appointment has been cancelled.', true);
    }
  };

  const handlePatientDoctorUpdate = (params: PatientDoctorUpdateProps) => {
    dispatch(setUser({ doctorId: params.doctorId }));
  };

  useEffect(() => {
    socket.on('appointmentUpdate', handleSetAppointmentUpdate);
    socket.on('patientDoctorUpdate', handlePatientDoctorUpdate);
    return () => {
      socket.off('appointmentUpdate');
      socket.off('patientDoctorUpdate');
    };
  }, []);

  return <CommonSocketContext.Provider value={{}}>{children}</CommonSocketContext.Provider>;
};
