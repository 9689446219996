import { Common } from '@thecvlb/design-system/lib/src';

import { AltSignupStepProps } from 'containers/SignUp/Content/content.types';

import Heading from '../../components/Heading';

const WhatNext: React.FC<AltSignupStepProps> = ({ onContinue }) => {
  return (
    <div className="flex flex-col gap-6 max-md:h-full">
      <Heading category="Plan" title="What’s next?" />
      <p className="text-mLg md:text-lg">
        These questions are designed to gather important details about your lifestyle behaviors,
        medical history, and weight loss goals. By carefully reviewing your responses, your
        LifeMD-affiliated medical provider can gain a clearer picture of your overall health.
      </p>
      <Common.Button
        className="mt-auto md:mx-auto"
        color="blue"
        fullWidthOnMobile
        onClick={onContinue}
      >
        Continue
      </Common.Button>
    </div>
  );
};

export default WhatNext;
