import { ShippingStatuses } from 'utils/enums';

export const getAshLabKitInfo = (shippingStatus: string): string => {
  switch (shippingStatus) {
    case ShippingStatuses.Placed:
      return 'Lab kit is ordered';
    case ShippingStatuses.ASH_ORDER_FULFILLED:
      return 'Lab kit is shipping';
    case ShippingStatuses.ASH_KIT_DELIVERED_TO_PATIENT:
      return 'Lab kit is delivered';
    case ShippingStatuses.ASH_KIT_IN_TRANSIT_TO_LAB:
      return 'Lab kit is shipping to lab';
    case ShippingStatuses.ASH_TEST_SAMPLES_RECEIVED:
    case ShippingStatuses.ASH_KIT_ACCESSIONED:
      return 'Your lab kit was received';
    case ShippingStatuses.ASH_KIT_REJECTED:
    case ShippingStatuses.ASH_KIT_FULFILLMENT_CANCELED:
    case ShippingStatuses.ASH_KIT_ISSUE:
      return 'Results rejected';
    case ShippingStatuses.ASH_RESULTS_READY:
      return 'Results ready';
    default:
      return 'Ordered';
  }
};
