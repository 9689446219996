import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetSet, useTitle } from 'react-use';

import { useLazyGetMifStructureQuery, useSendMifResponseMutation } from 'services/mifs/mifs';
import {
  AppointmentMifQuestion,
  MifStructure,
  QuestionWithAnswerConfig,
  SubmitMifResponseReq
} from 'services/mifs/mifs.types';

import { selectAppointments, selectMifInfo, selectUser, store } from 'store';
import { setNewAppointmentExtended } from 'store/appointments/appointmentsSlice';
import {
  clearAppointmentMif,
  removePartOfAppointmentMifAnswers,
  setAppointmentMif
} from 'store/mif/mifSlice';

import { useAppDispatch, useAppSelector } from 'hooks';
import { PathName } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

import { useMifNavigate } from './useMifNavigate';

const getSlug = (config?: AppointmentMifQuestion['config']) => {
  if (!config) {
    return 'intro';
  }
  if (config.type === 'info') {
    return config.group;
  }
  return config.question.value;
};

export const useAppointmentMif = (mifCode?: string) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useTitle('Medical Intake Form');
  const { isEdit } = useMifNavigate();

  const [params, setParams] = useSearchParams();
  const { mifID = '' } = useParams();
  const ID = mifID || mifCode || '';
  const stepFromQuery = params.get('mif-s') ?? '';
  const redirectStep = params.get('redirectStep') ?? 'date-time';

  const { appointmentMif = [] } = useAppSelector(selectMifInfo);
  const { newAppointmentExtended } = useAppSelector(selectAppointments);
  const { sexAtBirth } = useAppSelector(selectUser);

  const [
    getStructure,
    { data: structureFromTheServer, isUninitialized, isFetching: initialLoading }
  ] = useLazyGetMifStructureQuery();
  const [submitMif, { isLoading: isSubmittingResults }] = useSendMifResponseMutation();
  const [mifSteps, setMifSteps] = useState<MifStructure['configs']>([]);
  const [isBackAnimation, toggleBackAnimation] = useGetSet(false);

  const getStepNames = (): string[] => {
    const stepNames = [
      ...(structureFromTheServer?.data?.description ? ['intro'] : []),
      ...mifSteps.map(({ config }) => String(getSlug(config))),
      'results'
    ];
    return stepNames;
  };
  const handleSubmit = () => {
    const body = appointmentMif.map(({ question, answer, textAreaFields }) => ({
      question,
      answer,
      ...(textAreaFields && { textAreaFields })
    }));

    submitMif({ id: ID, body })
      .unwrap()
      .then(() => {
        // dispatch(clearAppointmentMif());
        const redirectStep = sessionStorage.getItem('redirectStep') ?? 'date-time';
        navigate(PathName.CreateAppointment + `?s=${redirectStep}`);
      });
  };

  const onLeaveFlow = () => {
    dispatch(clearAppointmentMif());
    navigate(PathName.Home, { replace: true });
  };

  const getCurrentQuestionIndex = (): number => {
    const curStep = mifSteps.find(
      (s) => 'question' in s.config && s.config.question.value === stepFromQuery
    );
    const parentStep = mifSteps.find(
      (s) =>
        !!curStep &&
        'question' in s.config &&
        s.config.question.value === (curStep?.config as QuestionWithAnswerConfig)?.showIf?.question
    );
    const filtered = mifSteps.filter(({ config }) => config.type !== 'info' && !config.showIf);
    if (parentStep) {
      return filtered.findIndex((s) => s.order === parentStep.order) + 1;
    }

    return (
      filtered.findIndex(
        (s) => 'question' in s.config && s.config.question.value === stepFromQuery
      ) + 1
    );
  };

  const getActiveStep = () => {
    if (['intro', 'results'].includes(stepFromQuery)) {
      return stepFromQuery;
    }
    return mifSteps.find(({ config }) => getSlug(config) === stepFromQuery);
  };

  const activeStep = getActiveStep();
  const DUMMY_SEX_AT_BIRTH_VALUE = 'sex_at_birth';

  const progress = (): number => {
    if (!activeStep) {
      return 0;
    }
    return (
      getStepNames().findIndex(
        (step) =>
          step ===
          (typeof activeStep === 'string' ? activeStep : String(getSlug(activeStep.config)))
      ) ?? 0
    );
  };

  const moveToStep = (step: 'next' | 'prev' | (string & {}), search?: URLSearchParams) => {
    if (step === 'next') {
      toggleBackAnimation(false);
      const reactiveMifState = store.getState().mif.appointmentMif as SubmitMifResponseReq;
      if (isEdit) {
        // v-c is for value-changed
        const isValueChanged = search?.get('v-c') === 'true';
        const backToResults = () => {
          return setParams((prev) => {
            prev.set('mif-s', 'results');
            return prev;
          });
        };
        if (
          !activeStep ||
          typeof activeStep === 'string' ||
          activeStep.config.type === 'info' ||
          !isValueChanged
        ) {
          return backToResults();
        }
        const subQuestionsOfCurrentQuestion = mifSteps.filter((s) => {
          if ('question' in s.config) {
            return s.config.showIf?.question === stepFromQuery;
          }
          return false;
        });
        if (subQuestionsOfCurrentQuestion.length) {
          const copy = [...appointmentMif];
          const questionsToRemove = copy.filter((item) => {
            return subQuestionsOfCurrentQuestion.some(
              (s) => s.config.type === 'info' || s.config.question.value === item.question
            );
          });
          dispatch(removePartOfAppointmentMifAnswers(questionsToRemove));
        }

        const parentHasMoreNotAnsweredQuestions = (): boolean => {
          const currentQuestion = mifSteps.find((p) => {
            return 'question' in p.config && p.config.question.value === stepFromQuery;
          });
          const parentQuestion =
            !!currentQuestion &&
            'showIf' in currentQuestion.config &&
            currentQuestion?.config.showIf?.question;
          if (!parentQuestion) {
            return false;
          }
          const parentQuestionsList = mifSteps.filter((s) => {
            if ('question' in s.config) {
              return s.config.showIf?.question === parentQuestion;
            }
            return false;
          });
          return parentQuestionsList.some((s) => {
            const answerOnQuestion = reactiveMifState.find(
              (item) => 'question' in s.config && item.question === s.config.question.value
            )?.answer;
            return !answerOnQuestion;
          });
        };
        if (!subQuestionsOfCurrentQuestion.length && !parentHasMoreNotAnsweredQuestions()) {
          return backToResults();
        }
        const answerOnCurrentQuestion = reactiveMifState.find(
          (item) => item.question === stepFromQuery
        )?.answer;
        if (
          !subQuestionsOfCurrentQuestion.some(
            (s) => 'question' in s.config && s.config.showIf?.value === answerOnCurrentQuestion
          ) &&
          !parentHasMoreNotAnsweredQuestions()
        ) {
          return backToResults();
        }
      }
      const nextStepName = getStepNames()[progress() + 1];
      const nextStep = mifSteps.find(({ config }) => getSlug(config) === nextStepName);
      if (nextStep?.config.type === 'info' || !nextStep?.config.showIf) {
        return setParams((prev) => {
          prev.set('mif-s', nextStepName);
          if (!!search) {
            search.forEach((value, key) => {
              prev.set(key, value);
            });
          }
          return prev;
        });
      } else {
        // cut previous steps
        const stepsLeft = [...mifSteps].slice(
          // if mif has description, we need to add 1 to progress, because we have 'intro' step, which is not in mifSteps
          progress() + (!structureFromTheServer?.data?.description ? 1 : 0)
        );
        // logic to see what conditional step to show
        const nextStepToShow = stepsLeft.find((s) => {
          if (s.config.type === 'info' || !s.config.showIf) {
            return true;
          }
          const parentQuestions = structureFromTheServer?.data.configs.filter((m) => {
            return 'question' in m.config && !('showIf' in m.config);
          });
          const parentQuestion = parentQuestions?.find((i) => {
            if (i.config.type === 'info' || s.config.type === 'info') {
              return false;
            }
            return i.config.question.value === s.config.showIf?.question;
          });
          const answerOnParentQuestion = reactiveMifState.find(
            (item) =>
              !!parentQuestion &&
              'question' in parentQuestion.config &&
              item.question === parentQuestion?.config.question.value
          )?.answer;
          if (Array.isArray(answerOnParentQuestion)) {
            return answerOnParentQuestion.includes(s.config.showIf.value);
          }
          return s.config.showIf.value === answerOnParentQuestion;
        });
        const lastStep = mifSteps.at(-1);
        return setParams((prev) => {
          if (!!nextStepToShow) {
            prev.set('mif-s', getSlug(nextStepToShow.config) || '');
          } else {
            prev.set('mif-s', getSlug(lastStep?.config) || '');
          }
          if (!!search) {
            search.forEach((value, key) => {
              prev.set(key, value);
            });
          }
          return prev;
        });
      }
    } else if (step === 'prev') {
      toggleBackAnimation(true);
      return navigate(-1);
    } else {
      if (getStepNames().includes(step) || step === 'prevent') {
        return setParams((prev) => {
          prev.set('mif-s', step);
          if (!!search) {
            search.forEach((value, key) => {
              prev.set(key, value);
            });
          }
          return prev;
        });
      }
      return setParams((prev) => {
        prev.set('mif-s', getStepNames()[0]);
        if (!!search) {
          search.forEach((value, key) => {
            prev.set(key, value);
          });
        }
        return prev;
      });
    }
  };

  const checkIfMifHasDQConditions = () => {
    let hasDQ = false;
    appointmentMif.forEach((item) => {
      const config = structureFromTheServer?.data.configs.find(
        (s) => 'question' in s.config && s.config.question.value === item.question
      )?.config;
      if (config?.type === 'info' || !config) {
        return;
      }
      if (config.type === 'checkbox' && Array.isArray(item.answer)) {
        return item.answer.forEach((answer) => {
          const dq = config.options.find((o) => o.value === answer)?.requireSpecialistConsultation;
          if (dq) {
            hasDQ = true;
          }
        });
      }
      if (config.type === 'radio') {
        const dq = config.options.find(
          (o) => o.value === item.answer
        )?.requireSpecialistConsultation;
        if (dq) {
          hasDQ = true;
        }
      }
    });
    dispatch(
      setNewAppointmentExtended({
        isQualifiedForAsyncAppointment: !hasDQ
      })
    );
  };

  const getFlowLength = () =>
    mifSteps.filter(({ config }) => config.type !== 'info' && !config.showIf).length;

  useEffect(() => {
    if (!newAppointmentExtended.appointmentTypeId || newAppointmentExtended.status === 'created') {
      dispatch(clearAppointmentMif());
      navigate(PathName.GetCare, { replace: true });
    }
    !!ID &&
      getStructure(ID)
        .unwrap()
        .then(({ data }) => {
          let filteredQuestions = data.configs;
          const sexAtBirthQuestion = data.configs.find(
            (s) => s.config.type !== 'info' && s.config.question.value === DUMMY_SEX_AT_BIRTH_VALUE
          );
          if (!!sexAtBirth && !!sexAtBirthQuestion) {
            filteredQuestions = filteredQuestions.filter((c) => {
              if (c.config.type === 'info') {
                return true;
              }
              return c.config.question.value !== DUMMY_SEX_AT_BIRTH_VALUE;
            });
            dispatch(
              setAppointmentMif([
                {
                  question: DUMMY_SEX_AT_BIRTH_VALUE,
                  answer: sexAtBirth,
                  groupTitle: ''
                  // questionContext: (sexAtBirthQuestion.config as QuestionWithAnswerConfig)
                  //   .questionContext,
                  // group: (sexAtBirthQuestion.config as QuestionWithAnswerConfig).group
                }
              ])
            );
          }
          const sorted = filteredQuestions.toSorted((a, b) => a.order - b.order);
          setMifSteps(sorted);
          const getFirstStep = () =>
            sorted.find(({ config }) => {
              // if mif has non empty description field, it means that we need to show 'intro' step
              if (config.type === 'info') {
                return true;
              }
              // if step doesn't have showIf field, it means that we can consider it as a first step
              if (!config.showIf) {
                return true;
              }
              const reactiveMifState = store.getState().mif.appointmentMif as SubmitMifResponseReq;
              // if step has showIf field, we need to check if it's value is equal to the answer on the question from showIf field
              const answerOnConfigQuestion = reactiveMifState.find((item) => {
                return item.question === config.showIf?.question;
              })?.answer;
              // if answer matches showIf condition, we can consider this step as a first step
              return config.showIf.value === answerOnConfigQuestion;
            });
          if (!stepFromQuery) {
            setParams(
              (prev) => {
                const slug = !!data.description ? 'intro' : (getSlug(getFirstStep()?.config) ?? '');
                if (!slug) {
                  throw new Error('Error while fetching MIF structure');
                }
                prev.set(
                  'mif-s',
                  // if mif has non empty description field, it means that we need to show 'intro' step,
                  //  otherwise just show first question of the mif
                  slug
                );
                return prev;
              },
              {
                replace: true
              }
            );
          }
        })
        .catch((e) => {
          handleRequestCatch(e, 'Error while fetching MIF structure');
          setParams(
            (prev) => {
              prev.set('mif-s', getStepNames()[0]);
              return prev;
            },
            {
              replace: true
            }
          );
        })
        .finally(() => {
          if (redirectStep) {
            sessionStorage.setItem('redirectStep', redirectStep);
          }
        });
  }, []);

  // useEffect(() => {
  //   !!stepFromQuery && toggleBackAnimation(true);
  // }, [stepFromQuery]);

  return {
    steps: mifSteps,
    currentQuestion: activeStep,
    description: structureFromTheServer?.data?.description || '',
    loading: initialLoading,
    isUninitialized,
    activeStepIndex: progress(),
    flowLength: getFlowLength(),
    moveToStep,
    handleSubmit,
    isSubmittingResults,
    onLeaveFlow,
    currentQuestionIndex: getCurrentQuestionIndex(),
    checkIfMifHasDQConditions,
    isBackAnimation: isBackAnimation()
  };
};
