import { NavLink } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';

import { PathName } from 'utils/enums';

const SignInAndSecurity = () => {
  useTitle('LifeMD - Sign in & security');
  return (
    <FadeWrapper className="flex flex-col gap-8">
      <NavLink
        className="flex w-full items-center gap-2 py-4 text-left text-mBase font-semibold"
        to={PathName.ChangePassword}
      >
        <Common.Icon className="size-9 p-2 text-gray-700" name="lock" />
        <span className="flex-1">Change password</span>
        <Common.Icon className="size-5 text-gray-400" name="arrow-right" />
      </NavLink>
    </FadeWrapper>
  );
};

export default SignInAndSecurity;
