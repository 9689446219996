import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetSet } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';
import { useFlag } from '@unleash/proxy-client-react';
import debounce from 'lodash/debounce';

import { useLazyGetLabsQuery } from 'services/auth/auth';
import { LabItem, LabsType } from 'services/auth/auth.types';
import { useLazyGetUserGeolocationQuery } from 'services/external/external';

import { selectOrchestrate } from 'store';

import Loader from 'shared/Loader';

import { useAppSelector, useQuery } from 'hooks';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';
import { FeatureFlag } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

import Heading from '../../components/Heading';

import { Option } from 'models/forms.types';

import { LabSelected, LabsList } from './components';

const tabs: Option<LabsType>[] = [
  { label: 'Labcorp', value: 'labcorp' },
  { label: 'Quest Diagnostics', value: 'quest' }
];

const SelectLab: React.FC<{ onContinue: () => void }> = ({ onContinue }) => {
  const isLabcorpEnable = useFlag(FeatureFlag.Labcorp);
  const [search, setSearch] = useGetSet('');
  const [, setParams] = useSearchParams();
  const step = useQuery().get('step') ?? '';
  const [activeTab, setActiveTab] = useGetSet<LabsType>(isLabcorpEnable ? 'labcorp' : 'quest');

  const [getLocation, { isFetching: isGettingLocation }] = useLazyGetUserGeolocationQuery();

  const { selectedLab } = useAppSelector(selectOrchestrate);

  const [getLabs, { currentData, isFetching }] = useLazyGetLabsQuery();

  const { send, isLoading } = useSubmitOrchestrateForm();

  const debouncedHandleChange = useMemo(
    () =>
      debounce((v) => {
        if (v.length === 5) {
          getLabs({ labType: activeTab(), zipCode: v }).unwrap().catch(handleRequestCatch);
        }
      }, 0),
    []
  );

  const handleSelect = (lab: LabItem) => {
    send('selectedLab', lab, () => {
      setParams((params) => {
        params.set('step', 'selected');
        return params;
      });
    });
  };

  const labsList = currentData?.data?.labs || [];

  useEffect(() => {
    if (step === 'selected' && !selectedLab) {
      setParams((params) => {
        params.delete('step');
        return params;
      });
    }
    const shouldGetLocation = step !== 'selected' && !search();
    shouldGetLocation &&
      getLocation()
        .unwrap()
        .then((res) => {
          if (res.postal) {
            setSearch(res.postal);
          }
        });
  }, []);

  useEffect(() => {
    debouncedHandleChange(search());
  }, [search()]);

  useEffect(() => {
    if (search().length === 5) {
      getLabs({ labType: activeTab(), zipCode: search() }).unwrap().catch(handleRequestCatch);
    }
  }, [activeTab()]);

  return (
    <>
      {!step && (
        <div className="onboarding-page-wrapper">
          <Heading category="Plan" title="Select your lab location" />
          <div className="flex w-full flex-col gap-2">
            <Common.SearchInput
              className="rounded-full shadow"
              dataTestId="search_input"
              disabled={isFetching}
              max={99999}
              placeholder="Zip code"
              type="number"
              value={!!search() ? Number(search()) : undefined}
              onChange={(e) => setSearch(e.target.value.slice(0, 5))}
            />
            {isLabcorpEnable && (
              <>
                <span className="text-base text-gray" data-testid="showing_locations_for">
                  Showing locations for:
                </span>
                <Common.Tabs
                  data={tabs}
                  type="line"
                  onChange={(v) => setActiveTab(v.value as LabsType)}
                />
              </>
            )}
            <LabsList
              isZipCodeValid={search().length === 5}
              items={labsList}
              loading={isFetching || isLoading || isGettingLocation}
              selectedLabAddress={selectedLab?.address}
              onSelect={handleSelect}
            />
            <Loader isVisible={isFetching || isGettingLocation} relative />
          </div>
        </div>
      )}
      {step === 'selected' && !!selectedLab && (
        <LabSelected lab={selectedLab} onContinue={onContinue} />
      )}
    </>
  );
};

export default SelectLab;
