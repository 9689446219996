export type SrcOfAppointmentScheduling = 'checker' | 'appMobile' | 'default';

import { PrescriptionForType } from 'widgets/createAppointment/extendedFlow/PrescriptionFor/prescriptionFor.types';
import { PrescriptionsValueType } from 'widgets/createAppointment/extendedFlow/PrescriptionsType/prescriptionsType.types';
import { PaymentFormFields } from 'widgets/PaymentFormNew/paymentFormNew.types';

import {
  AppointmentCallMethod,
  AppointmentTime,
  AppointmentTypeItem
} from 'models/appointment.types';
import { PricePoint } from 'models/plans.types';
import { MailingAddress } from 'models/user.types';

export type StepName = PossibleStepsType[number];

export const CREATE_APPOINTMENT_STEPS = [
  'choose-type-of-care',
  'red-flags',
  'prescription-for',
  'appointment-types-picker',
  'labs-to-review',
  'prescriptions-type',
  'is-renew',
  'details-for-provider',
  'mif',
  'additional-information',
  'create-account-intro',
  'create-account-personal-details',
  'create-account-address',
  'create-account-dob',
  'create-account-phone',
  'create-account-password',
  'qualified-for-async',
  'subscription-required',
  'date-time',
  'payment-checkout',
  'confirmation'
] as const;

export type CareTypes = 'talk-to-a-doctor' | 'prescriptions' | 'shop-labs' | 'labs-appt';

export type PossibleStepsType = typeof CREATE_APPOINTMENT_STEPS;

export interface MembershipData {
  freeAppointmentInfo: {
    bookedFreeAppointmentDate?: string;
    freeAppointmentsAmount?: number;
    isFree: boolean;
    periodEnd: string;
    periodStart: string;
  };
  newPP: PricePoint | null;
  newPlanID: string;
}

export type FillAppointmentDataProps =
  | {
      data: CareTypes;
      step: 'choose-type-of-care';
    }
  | {
      data?: AppointmentTypeItem;
      step: 'red-flags';
    }
  | {
      step: 'is-renew';
    }
  | {
      data: AppointmentTypeItem;
      step: 'appointment-types-picker';
    }
  | {
      data: {
        labsResultID: string;
      };
      step: 'labs-to-review';
    }
  | {
      data: {
        appointmentDescription: string;
        hasAccount: boolean;
      };
      step: 'details-for-provider';
    }
  | {
      step: 'date-time';
    }
  | {
      data: PrescriptionForType;
      step: 'prescription-for';
    }
  | {
      data: {
        apptType: AppointmentTypeItem;
        prescriptionsValueType: PrescriptionsValueType;
      };
      step: 'prescriptions-type';
    }
  | {
      data: {
        callMethod: AppointmentCallMethod;
        planId: string;
        pricePoint: PricePoint | null;
      };
      step: 'subscription-required';
    }
  | {
      step: 'create-account-intro';
    }
  | {
      data: {
        email: string;
        firstName: string;
        lastName: string;
      };
      step: 'create-account-personal-details';
    }
  | {
      data: MailingAddress;
      step: 'create-account-address';
    }
  | {
      data: string;
      step: 'create-account-dob';
    }
  | {
      data: string;
      step: 'create-account-phone';
    }
  | {
      step: 'create-account-password';
    }
  | {
      data: {
        callMethod: AppointmentCallMethod;
        price?: string;
      };
      step: 'qualified-for-async';
    }
  | {
      step: 'mif';
    }
  | {
      data: File[];
      step: 'additional-information';
    }
  | {
      step: 'payment-checkout';
    };

export type PaymentStepProps =
  | {
      formData: PaymentFormFields;
      isNewUser: true;
    }
  | {
      isNewUser: false;
    };

export interface AppointmentRequestBody {
  appointmentDescription: string;
  appointmentMethod: AppointmentCallMethod;
  appointmentTime: AppointmentTime;
  appointmentTypeId: string;
  doctorId: string;
  isWeightManagementAppointment: boolean;
  resultId?: string;
}
