import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';

import { selectNewAppointmentExtended } from 'store';

import PhoneInput from 'shared/form/PhoneInput';
import { notifyError } from 'shared/Toast/Toast';

import { useAppSelector, useQuery } from 'hooks';

const PhoneNumber: React.FC<{ onContinue: (phone: string) => void }> = ({ onContinue }) => {
  const { userPhone } = useAppSelector(selectNewAppointmentExtended);

  const query = useQuery();
  const isPhoneExists = query.get('phone_exists') === 'true';

  const { control, getValues, handleSubmit, reset, watch } = useForm<{ phone: string }>({
    shouldFocusError: false
  });

  const onSubmit = () => {
    const phone = getValues('phone');
    if (phone) {
      onContinue(phone);
    }
  };

  const watchPhone = watch('phone');

  useEffect(() => {
    if (userPhone) {
      reset({ phone: userPhone });
    }
    if (isPhoneExists) {
      notifyError('Phone number already in use');
    }
  }, []);

  return (
    <form className="flex h-full flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
      <h3 className="main-header text-primary-700">
        Please enter the best contact number for you:
      </h3>
      <PhoneInput
        control={control}
        dataTestId="phone_field"
        hint="As part of our efforts to ensure patient safety, we need to verify your phone number. By giving us your phone number and continuing, you agree that LifeMD may send text messages to you to verify your phone number and for any other lawful purpose related to your LifeMD account and your use of our services, including order confirmations, shipment notifications, and messages from your provider. Message and data rates may apply. Message frequency varies. Reply HELP for help. Reply STOP to opt out."
        label="Phone number"
        name="phone"
        size="lg"
      />
      <Common.Button
        className="max-md:mt-auto md:mx-auto"
        color="blue"
        disabled={isPhoneExists && userPhone === watchPhone}
        type="submit"
        fullWidthOnMobile
      >
        Continue
      </Common.Button>
    </form>
  );
};

export default PhoneNumber;
