import { createApi } from '@reduxjs/toolkit/query/react';

import { setReports } from 'store/reports/reportsSlice';

import { baseQueryWithReauth } from 'utils/services';

import {
  ChangeReportsStatusReqProps,
  ChangeReportsStatusResProps,
  GetReportDetailsResProps,
  GetReportReqProps,
  GetReportResProps,
  GetReportsReqProps,
  GetReportsResProps
} from './reports.types';

export const reportsApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getReport: build.query<GetReportResProps, GetReportReqProps>({
      query: (params) => ({
        params,
        responseHandler: (response: Response) => response.blob().then((res) => res),
        url: '/reports/details'
      })
    }),
    getReports: build.query<GetReportsResProps, GetReportsReqProps>({
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        if (data) {
          dispatch(setReports(data.data));
        }
      },
      query: () => '/reports'
    }),
    changeReportsStatus: build.mutation<ChangeReportsStatusResProps, ChangeReportsStatusReqProps>({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/reports/change-status'
      })
    }),
    getReportResults: build.query<GetReportDetailsResProps, GetReportReqProps>({
      query: ({ reportId }) => ({
        url: `/reports/${reportId}/results`
      })
    })
  }),
  reducerPath: 'reportsApi',
  tagTypes: ['Reports']
});

export const {
  useGetReportsQuery,
  useLazyGetReportQuery,
  useChangeReportsStatusMutation,
  // useGetReportResultsQuery,
  useLazyGetReportResultsQuery
} = reportsApi;
