import { Common } from '@thecvlb/design-system/lib/src';

import CareTypes from 'features/CareTypes';

import { ChooseTypeOfCareProps } from './chooseTypeOfCare.types';

const ChooseTypeOfCare: React.FC<ChooseTypeOfCareProps> = ({ onSelect }) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <Common.ColorTag color="primary" text="Get care" />
        <h1 className="text-m2xl font-bold text-primary-700" data-testid="header">
          How can we help you?
        </h1>
      </div>
      <CareTypes onSelect={onSelect} />
    </div>
  );
};

export default ChooseTypeOfCare;
