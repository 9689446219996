import { Common } from '@thecvlb/design-system';

import GovernmentID from 'modals/VerifyYourIdentity/GovernmentID';
import PersonPhoto from 'modals/VerifyYourIdentity/PersonPhoto';

import { useUpdateAvatar } from 'hooks/useUpdateAvatar';

import { VerifyYourIdentityProps } from './verifyYourIdentity.types';

const VerifyYourIdentity: React.FC<VerifyYourIdentityProps> = ({
  onClose,
  accessToken,
  type,
  docs: { government, personPhoto },
  refetchDocuments,
  verifyIdentity
}) => {
  const { uploadAvatar, isLoading, setFileToUseAsAvatar } = useUpdateAvatar();

  const isDisabled =
    (type === 'SELFIE' && !personPhoto._id) || (type === 'GV' && !government._id) || isLoading;

  const handleContinue = () => {
    type === 'GV' || !!accessToken ? onClose() : uploadAvatar(onClose);
  };

  return (
    <Common.Modal close={onClose} isOpen={!!type} size="lg">
      <div className="flex flex-col items-center gap-8 md:p-2">
        <h2 className="text-2xl font-bold text-primary-700 max-md:hidden">Verify your identity</h2>
        <div className="flex w-full flex-col items-center gap-4">
          {type === 'GV' ? (
            <GovernmentID
              accessToken={accessToken}
              initialValue={government}
              verifyIdentity={verifyIdentity}
              onFileStatusUpdate={refetchDocuments}
            />
          ) : (
            <PersonPhoto
              accessToken={accessToken}
              initialValue={personPhoto}
              onFileStatusUpdate={refetchDocuments}
              onFileUpdate={!accessToken ? setFileToUseAsAvatar : undefined}
            />
          )}
        </div>
        <Common.Button
          color="blue"
          dataTestId="continue_btn"
          disabled={isDisabled}
          isLoading={isLoading}
          fullWidthOnMobile
          onClick={handleContinue}
        >
          Continue
        </Common.Button>
      </div>
    </Common.Modal>
  );
};

export default VerifyYourIdentity;
