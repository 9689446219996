import React from 'react';
import { Common } from '@thecvlb/design-system/lib/src';

const Intro: React.FC<{ onContinue: () => void }> = ({ onContinue }) => {
  return (
    <div className="onboarding-page-wrapper items-center">
      <Common.Illustration name="success" />
      <div className="flex flex-col gap-4 text-center text-primary-700">
        <h2 className="main-header">Create an account</h2>
        <span>
          We’ll need some basic details—like your name, email, and address—to set up your LifeMD
          account. This helps us create a personalized experience tailored to your health needs.
        </span>
      </div>
      <div className="flex items-center gap-3 rounded-2xl bg-secondary-100 p-4">
        <Common.Icon className="flex-none text-secondary" name="stethoscope" />
        <span>
          Your information is <span className="font-bold">secure</span>, and setup only takes a
          minute!
        </span>
      </div>
      <Common.Button className="max-md:mt-auto" color="blue" fullWidthOnMobile onClick={onContinue}>
        Continue
      </Common.Button>
    </div>
  );
};

export default Intro;
