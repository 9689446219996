import HTMLReactParser from 'html-react-parser';

import { HeadingProps } from './heading.types';

const Heading: React.FC<HeadingProps> = ({ title, subtitle, category }) => {
  const parsedTitle = typeof title === 'string' ? title.replaceAll(' –', ' &#8209;') : title;

  return (
    <div className="flex flex-col gap-2 text-primary-700 md:gap-1">
      <span className="wm-signup-subtitle block">{category}</span>
      <h2 className="wm-signup-title" data-testid="header">
        {typeof parsedTitle === 'string' ? HTMLReactParser(parsedTitle) : parsedTitle}
      </h2>
      {!!subtitle && typeof subtitle === 'string' ? <h3>{subtitle}</h3> : subtitle}
    </div>
  );
};

export default Heading;
