import { useState } from 'react';
import { Common } from '@thecvlb/design-system/lib/src';

import { AltSignupStepProps } from 'containers/SignUp/Content/content.types';
import RadioGroup from 'shared/RadioGroup';

import { YES_NO_OPTIONS } from 'constants/formFields';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';

import Heading from '../../components/Heading';

const KetogenicDiet: React.FC<AltSignupStepProps> = ({ onContinue }) => {
  const { send, isLoading } = useSubmitOrchestrateForm();
  const [activeItem, setActiveItem] = useState<boolean>();

  const handleSelect = (v: boolean) => {
    setActiveItem(v);
    send('mif_qa', [{ answer: v, question: 'ketogenic_diet' }], () => {
      setActiveItem(undefined);
      onContinue();
    });
  };

  return (
    <div className="flex flex-col gap-6">
      <>
        <Heading
          category="About you"
          title="Do you plan to be on and/or remain on a ketogenic diet (where you consume less than 60 grams of carbohydrates per day)?"
        />
        <Common.Alert type="warning" colorableBackground>
          We are collecting this information for your provider's review, as a keto diet may
          exacerbate ketosis and increase the risk of acidosis.
        </Common.Alert>
        <RadioGroup
          isLoading={isLoading}
          items={YES_NO_OPTIONS}
          selectedItem={activeItem}
          onSelect={handleSelect}
        />
      </>
    </div>
  );
};

export default KetogenicDiet;
