import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AltSignupStepProps } from 'containers/SignUp/Content/content.types';
import RadioGroup from 'shared/RadioGroup';

import { YES_NO_OPTIONS } from 'constants/formFields';
import { useQuery } from 'hooks';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';
import { buildSearch } from 'utils/helpers';

import Heading from '../../components/Heading';
import FailedState from '../FailedState';

const MAOInhibitors: React.FC<AltSignupStepProps> = ({ onContinue, selectedFlow }) => {
  const { send, isLoading, handleProspect } = useSubmitOrchestrateForm();
  const [activeItem, setActiveItem] = useState<boolean>();

  const navigate = useNavigate();
  const subStep = useQuery().get('subStep');

  const handleSelect = (v: boolean) => {
    if (v) {
      navigate({
        search: buildSearch(selectedFlow === 'authorized' ? 's=mao-inhibitors' : '', 'subStep=dq')
      });
    } else {
      setActiveItem(v);
      send('mif_qa', [{ answer: v, question: 'mao_inhibitors' }], () => {
        setActiveItem(undefined);
        onContinue();
      });
    }
  };

  return (
    <div className="flex h-full flex-col gap-6">
      {subStep === 'dq' ? (
        <FailedState
          onClickOnBackButton={() =>
            handleProspect(
              {
                answer: true,
                question: 'mao_inhibitors'
              },
              selectedFlow === 'authorized'
            )
          }
        />
      ) : (
        <>
          <Heading category="About you" title="Have you used MAO inhibitors in the last 14 days?" />
          <RadioGroup
            isLoading={isLoading}
            items={YES_NO_OPTIONS}
            selectedItem={activeItem}
            onSelect={handleSelect}
          />
        </>
      )}
    </div>
  );
};

export default MAOInhibitors;
