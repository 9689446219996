import { Common } from '@thecvlb/design-system/lib/src';

import { AcceptedInsuranceProps } from './acceptedInsurance.types';

const AcceptedInsurance: React.FC<AcceptedInsuranceProps> = () => {
  // const isPercentageDiscount =
  //   typeof insuranceCoverageData?.coinsurance === 'number' &&
  //   insuranceCoverageData?.coinsurance > 0;
  // const fixedFinalPrice = getFixedFinalPrice(insuranceCoverageData?.finalCost);

  return (
    <div className="mb-6 flex justify-between gap-2 rounded-2xl border border-secondary-200 bg-secondary-50 p-4 md:mb-8">
      <div className="flex flex-col gap-2 ">
        <p className="text-mBase font-bold text-primary-700">
          Your insurance will be applied to this appointment
        </p>
        <div className="flex gap-1">
          <div>
            {/* <p className="text-sm">
              Your total {isPercentageDiscount ? 'coinsurance' : 'copay'} is
              {isPercentageDiscount
                ? ` ${insuranceCoverageData.coinsurance}% `
                : ` $${fixedFinalPrice} `}
              for this telehealth appointment.
            </p>
            <p className="text-sm text-gray">
              {isPercentageDiscount ? 'Coinsurance' : 'Copay'} does not include cost of medication.
            </p> */}
            <p className="text-mSm text-primary-700">
              Your <span className="font-bold">patient responsible amount</span> will be determined
              after your appointment by your insurance provider.{' '}
            </p>
          </div>
        </div>
      </div>
      <div>
        <Common.Icon className="size-[30px] text-green" name="check-circle" />
      </div>
    </div>
  );
};

export default AcceptedInsurance;
