import { ColorTagProps } from '@thecvlb/design-system/lib/src/common';
import upperFirst from 'lodash/upperFirst';

import { LabResultStatuses } from './labsResultMetricCard.types';

export const getIdealRangeText = (
  units: string,
  referenceMin?: number | null,
  referenceMax?: number | null
): string => {
  if (referenceMin && referenceMax) {
    return `Ideal range: ${referenceMin} - ${referenceMax} ${units}`;
  } else if (referenceMin) {
    return `Ideal range: ${referenceMin} ${units} or higher`;
  } else if (referenceMax) {
    return `Ideal range: ${referenceMax} ${units} or lower`;
  }
  return '';
};

export const getLabResultStatusForTag = (
  value: string,
  status?: LabResultStatuses,
  referenceMin?: string,
  referenceMax?: string
): ColorTagProps | null => {
  let isBellowsRange = false;
  let isInRange = false;
  let isAboveRange = false;

  const referenceMinNumber = !!Number(referenceMin?.replace(/[^\d.-]+/g, ''))
    ? Number(referenceMin?.replace(/[^\d.-]+/g, ''))
    : null;
  const referenceMaxNumber = !!Number(referenceMax?.replace(/[^\d.-]+/g, ''))
    ? Number(referenceMax?.replace(/[^\d.-]+/g, ''))
    : null;
  const valueNumber = value.length && (!!Number(value) || value === '0') ? Number(value) : null;

  if (valueNumber || valueNumber === 0) {
    if (referenceMinNumber && referenceMaxNumber) {
      isBellowsRange = valueNumber < referenceMinNumber;
      isInRange = valueNumber >= referenceMinNumber && valueNumber <= referenceMaxNumber;
      isAboveRange = valueNumber > referenceMaxNumber;
    } else if (referenceMinNumber) {
      isBellowsRange = valueNumber < referenceMinNumber;
      isInRange = valueNumber >= referenceMinNumber;
      isAboveRange = false;
    } else if (referenceMaxNumber) {
      isBellowsRange = false;
      isInRange = valueNumber <= referenceMaxNumber;
      isAboveRange = valueNumber > referenceMaxNumber;
    }
  } else if (!status) {
    const isValueInRange = value === referenceMin || value === referenceMax;
    isBellowsRange = false;
    isInRange = isValueInRange;
    isAboveRange = !isValueInRange;
  }

  switch (true) {
    case isBellowsRange:
      return {
        color: 'yellow',
        text: 'Below range'
      };
    case isInRange:
      return {
        color: 'gray',
        text: 'In range'
      };
    case isAboveRange:
      return {
        color: 'yellow',
        text: 'Above range'
      };
    case status === 'abnormal':
      return {
        color: 'yellow',
        text: 'Abnormal'
      };
    case !!status || !!referenceMin || !!referenceMax:
      return {
        color: 'gray',
        text: upperFirst(`${status || referenceMin || referenceMax}`)
      };
  }
  return null;
};
