import { useForm } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import ResendEmailButton from 'shared/ButtonResendEmail';
import TextInput from 'shared/form/TextInput';

import useAnalytics from 'hooks/useAnalytics';
import { DateFormat } from 'utils/enums';
import { onFormError } from 'utils/helpers';
import { EMAIL_CODE_REGEX } from 'utils/regExp';

import { EmailVerificationForm, VerifyCodeFormProps } from './verifyCodeForm.types';

const VerifyCodeForm: React.FC<VerifyCodeFormProps> = ({
  email,
  changePassword,
  onSubmit,
  onResendClick,
  isLoading
}) => {
  const logEvent = useAnalytics();

  const {
    formState: { isValid },
    control,
    getValues,
    handleSubmit
  } = useForm<EmailVerificationForm>({
    criteriaMode: 'all',
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const isSentCodeExpired = changePassword
    ? dayjs().diff(dayjs(changePassword?.expiresAt), 'minutes') > 0
    : false;
  const hint = `Code sent to ${email} at ${dayjs(changePassword?.sentAt).format(
    DateFormat.h_mma_z
  )}`;

  const handleClickResend = () => {
    logEvent('acc_information_resend_email_btn_click');
    onResendClick(getValues('code'));
  };

  return (
    <form
      className="flex flex-col gap-4 rounded-2xl border bg-white p-8"
      role="form"
      onSubmit={handleSubmit(onSubmit, onFormError)}
    >
      <div className="flex flex-col gap-1">
        <h2 className="text-lg font-semibold">Verify your email</h2>
        <p className="text-base">We sent a code to your email account to verify that it’s you.</p>
      </div>
      <TextInput
        control={control}
        dataTestId="code_input"
        hint={<span className="break-words">{hint}</span>}
        invalidErrorMsg="Code is invalid"
        label="Enter code"
        name="code"
        regExp={EMAIL_CODE_REGEX}
        requiredErrorMsg="Code is required"
        size="md"
      />
      <div className="flex gap-2">
        <Common.Button
          color="blue"
          dataTestId="continue_btn"
          disabled={!isValid || isLoading}
          isLoading={isLoading}
          size="sm"
        >
          Continue
        </Common.Button>
        <ResendEmailButton initDisabled={isSentCodeExpired} onClick={handleClickResend}>
          Resend code
        </ResendEmailButton>
      </div>
    </form>
  );
};

export default VerifyCodeForm;
