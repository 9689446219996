import { Common } from '@thecvlb/design-system';

import { selectUser } from 'store';
import { ChronicDiseasesItemProps } from 'store/mif/mif.types';

import ListItemWrapper from 'shared/animationWrappers/ListItemWrapper';

import { ADDITIONAL_CONDITIONS } from 'constants/formFields';
import { useAppSelector } from 'hooks';
import { SexAtBirth } from 'utils/enums';

import { AdditionalConditionsProps } from './additionalConditions.types';

const AdditionalConditions: React.FC<AdditionalConditionsProps> = ({
  loading = false,
  onUpdate,
  additionalConditions
}) => {
  const { sexAtBirth } = useAppSelector(selectUser);
  const optionNamesList = additionalConditions.map((v) => v.name);
  const NONE_ELEMENT = ADDITIONAL_CONDITIONS.find((e) => e.fieldID === '0');
  const filteredOptions = ADDITIONAL_CONDITIONS.filter((el) => {
    // 6 stands for PMS
    return sexAtBirth !== SexAtBirth.Female ? el.fieldID !== '6' : true;
  });

  const handleChange = (name: string) => {
    let history: ChronicDiseasesItemProps[];
    if (additionalConditions.map((v) => v.name).includes(name)) {
      history = additionalConditions.filter((field) => field.name !== name);
    } else {
      if (name === NONE_ELEMENT?.fieldID) {
        history = [{ name, notes: '' }];
      } else {
        history = [...additionalConditions, { name, notes: '' }].filter(
          (e) => e.name !== NONE_ELEMENT?.fieldID
        );
      }
    }
    onUpdate(history);
  };

  const handleChangeNotes = (id: string, notes: string) => {
    onUpdate(additionalConditions.map((v) => (v.name === id ? { ...v, notes } : v)));
  };

  return (
    <div className="flex flex-col gap-2">
      {filteredOptions.map((el) => (
        <ListItemWrapper dataTestId="additional_condition" key={el.fieldID}>
          <Common.RichCheckbox
            checked={optionNamesList.includes(el.fieldID)}
            dataTestId="checkbox"
            disabled={loading}
            key={el.fieldID}
            label={el.label}
            value={el.fieldID}
            onChange={() => handleChange(el.fieldID)}
          >
            {optionNamesList.includes(el.fieldID) && el.fieldID !== NONE_ELEMENT?.fieldID && (
              <Common.TextArea
                dataTestId="text_area"
                disabled={loading}
                label={`Notes ${loading ? '- saving...' : ''}`}
                name="drug"
                placeholder={`Please add any relevant notes here...\n- Are you being treated for this? How? \n- When did it start?`}
                rows={5}
                value={additionalConditions.find((value) => value.name === el.fieldID)?.notes}
                onChange={(e) => handleChangeNotes(el.fieldID, e.target.value)}
              />
            )}
          </Common.RichCheckbox>
        </ListItemWrapper>
      ))}
    </div>
  );
};

export default AdditionalConditions;
