import { useState } from 'react';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import VerifyYourIdentity from 'modals/VerifyYourIdentity';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
import Card from 'widgets/identityVerification/Card';
import { CardType } from 'widgets/identityVerification/Card/card.types';
import ErrorMessages from 'widgets/identityVerification/ErrorMessages';

import useAnalytics from 'hooks/useAnalytics';
import { useGetValidationDocs } from 'hooks/useGetValidationDocs';

import { IntroProps } from './intro.types';

const Intro: React.FC<IntroProps> = ({ onContinue, onSkip, inPopup = false, loading = false }) => {
  useTitle('Verify your identity', { restoreOnUnmount: true });

  const logEvent = useAnalytics();
  const {
    docs,
    isLoading,
    isUpdating,
    refetchDocuments,
    handleVerifyIdentity,
    handleDeleteDocument
  } = useGetValidationDocs();
  const [verifyYourIdentityType, setVerifyYourIdentityType] = useState<CardType>();

  const { government, personPhoto } = docs;
  const filesUploaded = government._id && personPhoto._id;
  const filesIdentified = government.identity && personPhoto.identity;
  const filesVerified = government.identity?.isVerified && personPhoto.identity?.isVerified;
  const mergedErrors = [
    ...(government.identity?.errors || []),
    ...(personPhoto.identity?.errors || [])
  ];

  const handleSubmit = () => {
    logEvent('verify_identity_continue_btn_click');

    if (!government._id || !personPhoto._id) return;

    handleVerifyIdentity(government._id, personPhoto._id, (isVerified) =>
      isVerified ? onContinue() : refetchDocuments()
    );
  };

  const alertText =
    'To comply with regulations, we need to confirm your identity. We will never share this information.';

  return (
    <FadeWrapper
      className={classNames('h-full md:rounded-2xl md:bg-white md:p-8', { 'md:shadow': !inPopup })}
    >
      <Loader isVisible={isLoading} />
      <VerifyYourIdentity
        docs={{ government, personPhoto }}
        refetchDocuments={refetchDocuments}
        type={verifyYourIdentityType}
        verifyIdentity={() => government._id && handleVerifyIdentity(government._id)}
        onClose={() => setVerifyYourIdentityType(undefined)}
      />
      <div className="mx-auto flex h-full max-w-[500px] flex-col items-center md:gap-4">
        <Common.Icon
          className="size-20 rounded-full bg-primary-50 p-4 text-primary-400 max-md:hidden"
          name="tag-person"
        />
        <h2 className="text-m2xl font-bold text-primary-700 max-md:self-start md:text-2xl">
          Verify your identity
        </h2>
        <span className="mt-2 text-mSm text-gray-600 md:hidden">{alertText}</span>
        <Common.Alert className="max-md:hidden" type="plain" colorableBackground>
          {alertText}
        </Common.Alert>
        <div className="flex w-full gap-3 max-md:my-6">
          <Card
            docs={docs}
            isLoading={isLoading}
            type="GV"
            onClick={setVerifyYourIdentityType}
            onDelete={handleDeleteDocument}
          />
          <Card
            docs={docs}
            type="SELFIE"
            onClick={setVerifyYourIdentityType}
            onDelete={handleDeleteDocument}
          />
        </div>
        <ErrorMessages
          filesIdentified={!!filesIdentified}
          filesVerified={!!filesVerified}
          mergedErrors={mergedErrors}
          verifyIdentity={() => government._id && handleVerifyIdentity(government._id)}
        />
        <div className="mt-auto flex w-full flex-col items-center gap-4 md:mt-4 md:gap-2">
          <Common.Button
            color="blue"
            disabled={loading || !filesUploaded}
            isLoading={isUpdating}
            fullWidthOnMobile
            onClick={handleSubmit}
          >
            Submit
          </Common.Button>
          <Common.Button disabled={loading || isUpdating} fullWidthOnMobile onClick={onSkip}>
            {filesIdentified ? 'Continue anyway' : 'I’ll do this later'}
          </Common.Button>
        </div>
      </div>
    </FadeWrapper>
  );
};

export default Intro;
