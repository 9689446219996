import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';

import AskToUpgradeToTheWM from 'modals/AskToUpgradeToTheWM';
import NotOnThisList from 'shared/NotOnThisList';
import RadioGroup from 'shared/RadioGroup';

import { DEFAULT_APPOINTMENT_TYPE } from 'constants/defaults';
import useWeightManagement from 'hooks/useWeightManagement';

import { setAppointmentOptions } from './appointmentTypesPicker.settings';
import { AppointmentTypesPickerProps } from './appointmentTypesPicker.types';

const AppointmentTypesPicker: React.FC<AppointmentTypesPickerProps> = ({
  appointmentTypes,
  onClickNext
}) => {
  const [params, setParams] = useSearchParams();
  const { isWeightManagement } = useWeightManagement();
  const [activeItem, setActiveItem] = useState<string>();
  const [searchKey, setSearchKey] = useState<string>('');
  const [isUpgradePopupOpen, toggleUpgradePopup] = useToggle(false);

  const showMore = params.has('fullList');

  const appointmentOptions = [
    ...setAppointmentOptions(!showMore, appointmentTypes, searchKey),
    ...(!showMore ? [{ label: 'Something else', value: 'other', data: null }] : [])
  ];

  const handleOnSelect = (value: string) => {
    setActiveItem(value);

    if (value === 'other') {
      setParams((params) => {
        params.set('fullList', 'true');
        return params;
      });
    } else {
      const selectedOption = appointmentOptions.find((appt) => appt.value === value);
      if (!selectedOption) {
        throw new Error('Selected option not found');
      }
      const shouldTriggerWMUpgradePopup = (): boolean => {
        if (isWeightManagement) {
          return false;
        }
        return (
          Array.isArray(selectedOption.data?.plans) &&
          selectedOption.data.plans.some((plan) => plan.code === 'weight-management') &&
          !selectedOption.data.plans.some((plan) => plan.code === 'lifemd-plus')
        );
      };

      if (shouldTriggerWMUpgradePopup()) {
        setActiveItem(undefined);
        return toggleUpgradePopup();
      }
      !!selectedOption?.data && onClickNext(selectedOption.data);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const defaultAppointmentType = appointmentTypes.find(
    (appt) => appt.displayName === DEFAULT_APPOINTMENT_TYPE
  );

  const redirectToDetailsScreen = () => {
    defaultAppointmentType && onClickNext(defaultAppointmentType);
  };

  return (
    <>
      <AskToUpgradeToTheWM
        alertContent={
          <>
            <b>Please upgrade your plan</b> to book a weight management appointment.
          </>
        }
        declineButtonText="Close"
        isOpen={isUpgradePopupOpen}
        onClose={toggleUpgradePopup}
        // onReopen={() => toggleUpgradePopup(true)}
      />
      <div className="flex flex-col gap-6">
        {!showMore && (
          <div className="flex flex-col gap-2">
            <Common.ColorTag color="primary" text="Talk to a doctor" />
            <h1 className="text-m2xl font-bold text-primary-700" data-testid="header">
              What would you like to be seen for?
            </h1>
          </div>
        )}

        {showMore && (
          <>
            <Common.SearchInput
              className="mt-0.5"
              dataTestId="search_input"
              placeholder="Find an appointment type..."
              onChange={handleSearch}
            />
            {!!defaultAppointmentType && (
              <NotOnThisList
                btnText="Be seen for something else"
                onClick={redirectToDetailsScreen}
              />
            )}
          </>
        )}

        <RadioGroup
          items={appointmentOptions}
          selectedItem={activeItem}
          onSelect={handleOnSelect}
        />
      </div>
    </>
  );
};

export default AppointmentTypesPicker;
