import { useErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system/lib/src';
import { useLottie } from 'lottie-react';

import { useLogout } from 'hooks/useLogout';
import { PathName } from 'utils/enums';

import error from 'assets/animations/error.json';

const Error = () => {
  const { resetBoundary } = useErrorBoundary();

  const options = {
    animationData: error,
    loop: true
  };

  const { View } = useLottie(options);

  const navigate = useNavigate();
  const logout = useLogout();

  const onGoToDashboard = () => {
    resetBoundary();
    navigate(PathName.Home);
  };

  const onLogout = () => {
    logout(resetBoundary);
    localStorage.clear();
  };

  return (
    <div className="flex size-full bg-gray-100 p-4 md:items-center md:justify-center">
      <div className="flex flex-col items-center gap-4 text-center">
        <div className="size-[150px] grayscale">{View}</div>
        <h1 className="text-[40px] font-bold leading-tight text-gray">
          Oops, something went wrong
        </h1>
        <p className=" text-mXl text-gray-300">
          We are working on the problem already. Please try again later.
        </p>
        <div className="flex gap-3 max-md:mt-auto max-md:w-full max-md:flex-col md:mt-6">
          <Common.Button color="blue" fullWidthOnMobile onClick={onGoToDashboard}>
            Go to the dashboard
          </Common.Button>
          <Common.Button className="w-full" color="white-alt" fullWidthOnMobile onClick={onLogout}>
            Try to log in again
          </Common.Button>
        </div>
      </div>
    </div>
  );
};

export default Error;
