import { useState } from 'react';
import { Rating } from 'react-simple-star-rating';
import { Common } from '@thecvlb/design-system';

import { useAddPlatformReviewMutation } from 'services/review/review';

import { setUser } from 'store/user/userSlice';

import { useAppDispatch } from 'hooks';
import useWidth from 'hooks/useWidth';
import { handleRequestCatch } from 'utils/helpers';

import { EMOJI_RATING, PlatformReviewProps } from './ratePlatform.types';

const PlatformReview: React.FC<PlatformReviewProps> = ({ isOpen, onClose, onSkip, origin }) => {
  const dispatch = useAppDispatch();
  const [addReview, { isLoading }] = useAddPlatformReviewMutation();
  const { isMobile } = useWidth();
  const [rating, setRating] = useState(0);

  const handleAddReviewThen = () => {
    dispatch(setUser({ lastSentRating: rating }));
    onClose();
  };

  const handleAddReviewClick = () => {
    addReview({ origin, rating }).unwrap().then(handleAddReviewThen).catch(handleRequestCatch);
  };

  return (
    <Common.Modal close={onSkip} isOpen={isOpen} padding={!isMobile} size="sm">
      <div className="flex w-full flex-col items-center gap-4 px-4 py-6 md:px-1 md:py-0">
        <h2 className="text-mXl font-bold text-gray-700 md:text-xl">Enjoying LifeMD?</h2>
        <span aria-label="rating" className="text-[50px] leading-[115%]" role="img">
          {EMOJI_RATING[rating]}
        </span>
        <Rating
          emptyColor="transparent"
          fillColor="#FFA700"
          size={32}
          SVGclassName="inline-block"
          SVGstorkeWidth="1"
          SVGstrokeColor="#FFA700"
          onClick={setRating}
        />
        <div className="mb-10 mt-1.5 flex w-full items-center gap-3 max-md:flex-col-reverse md:mb-2 md:gap-2">
          <Common.Button
            className="md:basis-1/2 md:text-sm"
            color="white-alt"
            fullWidthOnMobile
            onClick={onSkip}
          >
            Skip
          </Common.Button>
          <Common.Button
            className="md:basis-1/2 md:text-sm"
            color="blue"
            dataTestId="continue_without_app"
            disabled={rating < 1 || isLoading}
            fullWidthOnMobile
            onClick={handleAddReviewClick}
          >
            Send review
          </Common.Button>
        </div>
      </div>
    </Common.Modal>
  );
};

export default PlatformReview;
