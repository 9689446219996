import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system/lib/src';

import { PathName } from 'utils/enums';

import { AsyncAppointmentStatusProps } from './asyncAppointmentStatus.types';
import { TimeLineSection } from './TimeLine';

const AsyncAppointmentStatus: React.FC<AsyncAppointmentStatusProps> = ({
  identityVerified,
  medicalIntakeCompleted,
  title,
  isNewTask = false,
  isCompleted = false,
  messagesCount = 0
}) => {
  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate(PathName.Home);
  };

  const navigateToVerifyIdentity = () => {
    navigate(PathName.IntakeForm);
  };

  const navigateToMessages = () => {
    navigate({ pathname: PathName.Messages, search: 'tab=care-team' });
  };

  const isPostonboardCompleted = identityVerified && medicalIntakeCompleted;

  return (
    <div className="mx-auto flex h-full grow flex-col justify-between md:max-w-[664px]">
      <div>
        <Common.Icon
          className="mx-auto size-[96px] rounded-full bg-secondary-100 p-8 text-secondary"
          name={isNewTask ? 'check' : 'chat-outline'}
        />
        <h1 className="my-4 text-center text-m2xl font-bold">{title}</h1>
        {!isPostonboardCompleted && isNewTask && (
          <p className="text-center">
            <span className="font-bold">Next step:</span> We need to collect some basic medical
            information. This should only take a couple of minutes.
          </p>
        )}
        {!isNewTask && (
          <p className="text-center text-mBase font-medium text-gray-500">Message-based consult</p>
        )}
        <div className="my-4 flex flex-col gap-4">
          {isPostonboardCompleted && (
            <TimeLineSection>
              <p className="text-mSm font-medium text-gray-500">Medical intake form</p>
              <p className="text-mSm font-medium text-gray-500">Complete</p>
            </TimeLineSection>
          )}
          {isPostonboardCompleted && !isCompleted && (
            <div className="flex flex-col gap-4 rounded-xl bg-white p-4 shadow-lg">
              <h2 className="text-mBase font-bold text-primary-400">Care team review</h2>
              <p className="font-medium text-gray-700">
                Next, our care team will review your answers.
              </p>
            </div>
          )}
          {isPostonboardCompleted && isCompleted && (
            <TimeLineSection>
              <p className="text-mSm font-medium text-gray-500">Care team review</p>
              <p className="text-mSm font-medium text-gray-500">Complete</p>
            </TimeLineSection>
          )}
          {!isPostonboardCompleted && (
            <div className="flex flex-col gap-4 rounded-xl bg-white p-4 shadow-lg">
              <h2 className="text-mBase font-bold text-primary-400">Medical intake form</h2>
              <ul className="">
                <li className="flex items-center gap-2">
                  <Common.Icon
                    className="size-4 text-green-500"
                    name={identityVerified ? 'check-circle' : 'donut-0'}
                  />
                  <span>Verify identity</span>
                </li>
                <li className="flex items-center gap-2">
                  <Common.Icon
                    className="size-4 text-green-500"
                    name={medicalIntakeCompleted ? 'check-circle' : 'donut-0'}
                  />
                  <span>Complete questionnaire</span>
                </li>
              </ul>
              <Common.Button
                className="mx-auto"
                color="blue"
                size="md"
                fullWidthOnMobile
                onClick={navigateToVerifyIdentity}
              >
                Next:{' '}
                {identityVerified && !medicalIntakeCompleted
                  ? 'Complete questionnaire'
                  : 'Verify identity'}
              </Common.Button>
            </div>
          )}
          {!isPostonboardCompleted && (
            <TimeLineSection>
              <p className="text-mSm font-medium text-gray-700">Care team review</p>
              <p className="text-mSm font-medium text-gray-500">
                Our care team will review your answers.
              </p>
            </TimeLineSection>
          )}
          {!isCompleted && (
            <TimeLineSection>
              <p className="text-mSm font-medium text-gray-700">Follow-up via messaging</p>
              <p className="text-mSm font-medium text-gray-500">
                Your provider will follow-up with next steps via messaging. If a prescription is
                warranted, we’ll send it to your pharmacy.
              </p>
            </TimeLineSection>
          )}
          {isCompleted && (
            <div className="flex flex-col gap-4 rounded-xl bg-white p-4 shadow-lg">
              <h2 className="text-mBase font-bold text-primary-400">Follow-up via messaging</h2>
              <p className="font-medium text-gray-700">
                Your care team has sent you a new message.
              </p>
              <Common.Button
                className="mx-auto"
                color="white-alt"
                size="md"
                fullWidthOnMobile
                onClick={navigateToMessages}
              >
                View messages{' '}
                {!!messagesCount && (
                  <div className="flex size-3 items-center justify-center rounded-full bg-red-500 text-[7px] font-bold text-white">
                    {messagesCount}
                  </div>
                )}
              </Common.Button>
            </div>
          )}
        </div>
      </div>
      {!!medicalIntakeCompleted && (
        <Common.Button
          className="mx-auto"
          color="white-alt"
          size="lg"
          fullWidthOnMobile
          onClick={navigateToDashboard}
        >
          View dashboard
        </Common.Button>
      )}
    </div>
  );
};

export default AsyncAppointmentStatus;
