import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';
import { useFlag } from '@unleash/proxy-client-react';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';

import {
  useGetReportsQuery,
  useLazyGetReportQuery,
  useLazyGetReportResultsQuery
} from 'services/reports/reports';
import { GetReportResProps } from 'services/reports/reports.types';

import { selectAppointments, selectUser } from 'store';

import LabsResultMetricCard from 'features/LabsResultMetricCard';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';

import { useAppSelector } from 'hooks';
import useWeightManagement from 'hooks/useWeightManagement';
import useWidth from 'hooks/useWidth';
import { PathName } from 'utils/enums';
import { isAbleToScheduleNewAppointment } from 'utils/helpers';

import CircleChart from '../CircleChart';

const LabResults = () => {
  useTitle('LifeMD - Labs & Imaging');
  const navigate = useNavigate();
  const { isMobile } = useWidth();
  const { id: reportId } = useParams();

  const { data: reports } = useGetReportsQuery();
  const { upcomingAppointments = [] } = useAppSelector(selectAppointments);
  const { isFirstAppointmentCompleted } = useAppSelector(selectUser);

  const { isAsyncPlan, isWeightManagement } = useWeightManagement();

  const isEnableToScheduleLabAppointment = !useFlag('disable-schedule-appt-on-lab-result');
  const [getReportResults, { data, isFetching }] = useLazyGetReportResultsQuery();

  const [getReportQuery, { isFetching: isDownloadFetching, isLoading: isDownloadLoading }] =
    useLazyGetReportQuery();

  const report = data?.data;
  const reportApptData = reports?.data?.find((item) => item._id === report?._id);
  const rangeCounts = report?.results?.reduce(
    (acc, item) => {
      if (item.isAbnormal) {
        acc.outOfRange += 1;
      } else {
        acc.inRange += 1;
      }
      return acc;
    },
    { inRange: 0, outOfRange: 0 }
  );
  useEffect(() => {
    if (reportId) {
      getReportResults({ reportId });
    }
  }, [reportId]);

  const handleDownloadReport = () => {
    reportId &&
      getReportQuery({ reportId })
        .unwrap()
        .then((res: GetReportResProps) => {
          fileDownload(res, `${report?.title || report?.reportType || 'Report'}.pdf`);
        });
  };
  const showCreateApptButton = isAbleToScheduleNewAppointment(
    upcomingAppointments,
    isWeightManagement,
    isFirstAppointmentCompleted,
    isAsyncPlan
  );
  const showScheduleAppointment =
    isEnableToScheduleLabAppointment && !reportApptData?.pendingAppointment && showCreateApptButton;

  return (
    <FadeWrapper>
      <Loader isVisible={isFetching} />
      <div className="flex flex-col gap-6">
        <Common.Button
          className="ml-auto hidden md:flex"
          color="white-alt"
          dataTestId="download_btn"
          isLoading={isDownloadFetching || isDownloadLoading}
          preIcon="download"
          size="sm"
          onClick={handleDownloadReport}
        >
          Download results
        </Common.Button>
        <div className="flex flex-col gap-4 rounded-2xl p-6 md:flex-row-reverse md:justify-end">
          <div className="flex flex-col justify-center text-mBase font-medium text-gray-500 md:text-base">
            <p>Completed {dayjs(report?.signedDate).format('MMM DD, YYYY')}</p>
            <p>
              We collected {report?.results?.length} biomarkers for your lab panel.{' '}
              <span className="text-gray-700">{rangeCounts?.inRange} were in-range</span>, and{' '}
              <span className="text-gray-700">{rangeCounts?.outOfRange} were out of range</span>.
            </p>
          </div>
          {report?.results?.length && (
            <div className="flex gap-4">
              <CircleChart
                inRangeCount={rangeCounts?.inRange ?? 0}
                outOfRangeCount={rangeCounts?.outOfRange ?? 0}
                totalCount={report?.results?.length}
              />
              <div className="flex flex-col justify-center md:w-[123px]">
                <div className="flex items-center gap-2">
                  <div className="w-2">{rangeCounts?.inRange}</div>{' '}
                  <div className="size-[8px] rounded-full bg-blue-300" /> In range
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-2">{rangeCounts?.outOfRange}</div>
                  <div className="size-[8px] rounded-full bg-yellow-500" /> Out of range
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="grid gap-4">
          {report?.results?.map((result, idx) => {
            const status =
              'isAbnormal' in result ? (result.isAbnormal ? 'abnormal' : 'normal') : '';
            return <LabsResultMetricCard {...result} key={idx} status={status} />;
          })}
        </div>
        <div className="flex items-end justify-end">
          <Common.Button
            className="md:hidden"
            color="white-alt"
            dataTestId="download_btn"
            isLoading={isDownloadFetching || isDownloadLoading}
            preIcon="download"
            size="md"
            onClick={handleDownloadReport}
          >
            Download results
          </Common.Button>
        </div>
        {showScheduleAppointment && (
          <section>
            <h2 className="mb-6 text-mLg font-bold md:text-xl">Take action</h2>
            <div className="flex flex-col gap-6 rounded-2xl bg-white p-6">
              <div>
                <p className="font-bold">Follow-up with a provider</p>
                <p className="text-gray">
                  Meet with a provider to discuss your lab results and create an action plan.
                </p>
              </div>
              <Common.Button
                color="blue"
                size={isMobile ? 'lg' : 'md'}
                fullWidthOnMobile
                onClick={() =>
                  navigate(
                    `${PathName.CreateAppointmentExtended}?src=labs-appt&s=red-flags&resultId=${report?._id}`
                  )
                }
              >
                Schedule appointment
              </Common.Button>
            </div>
          </section>
        )}
      </div>
    </FadeWrapper>
  );
};

export default LabResults;
