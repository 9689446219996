import { useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';

import { useDeleteFromCartMutation, useGetCartQuery } from 'services/shop/shop';

import { selectShop } from 'store';

import CartItem from 'pages/shop/Cart/components/CartItem';

import Loader from 'shared/Loader';

import { useAppSelector } from 'hooks';
import { PathName } from 'utils/enums';

import EmptyCart from 'assets/icons/empty-cart.svg?react';

const Cart = () => {
  useTitle('LifeMD: Cart');
  const navigate = useNavigate();
  const [deleteProduct, { isLoading }] = useDeleteFromCartMutation();
  const { refetch, isFetching } = useGetCartQuery();
  const { cart } = useAppSelector(selectShop);

  const handleDelete = (id: string) => {
    if (!!id) {
      deleteProduct({ productId: id }).unwrap().then(refetch);
    }
  };
  const cartProducts = cart.items ?? [];
  const isCartEmpty = cartProducts.length === 0;

  return (
    <div className="flex h-full flex-col gap-8">
      <Loader isVisible={isFetching} />
      <div className="flex flex-col max-md:h-full">
        {isCartEmpty && !isFetching ? (
          <div className="flex h-full flex-col gap-8">
            <div className="flex flex-1 flex-col items-center justify-center gap-2.5 py-8 text-center">
              <EmptyCart className="size-[52px]" />
              <span className="max-w-[306px] text-gray" data-testid="empty_cart_tooltip">
                Your cart is empty. When you add products, they’ll appear here.
              </span>
            </div>
            <Common.Button
              className="mx-auto max-md:mt-auto"
              color="blue"
              dataTestId="view_products_btn"
              size="lg"
              fullWidthOnMobile
              onClick={() => navigate(PathName.Shop)}
            >
              View products
            </Common.Button>
          </div>
        ) : (
          <>
            <div
              className="flex h-full flex-col overflow-auto max-md:-mx-4"
              data-testid="products_in_cart"
            >
              {cartProducts.map((product) => (
                <CartItem
                  key={product.productId}
                  product={product}
                  onDelete={() => handleDelete(product.productId)}
                />
              ))}
            </div>
            <div className="mt-8 flex flex-col gap-6 py-6 md:border-t">
              <div>
                <h3 className="flex justify-between text-mLg font-bold text-primary-700">
                  <span>Sub total</span>
                  <span data-testid="sub_total_price">${cart.subTotal}</span>
                </h3>
                <span className="text-mSm text-gray" data-testid="no_shipping_no_taxes_included">
                  Does not include shipping or taxes
                </span>
              </div>
              <Common.Button
                color="blue"
                dataTestId="continue_to_checkout_view_products_btn"
                disabled={isLoading || isFetching}
                size="lg"
                fullWidthOnMobile
                onClick={() => navigate(PathName.ShopCheckout)}
              >
                Continue to checkout
              </Common.Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Cart;
