import { useLogoutMutation } from 'services/auth/auth';

import { selectUser } from 'store';
import { clearUser } from 'store/user/userSlice';

import { useAppDispatch, useAppSelector, useMessages } from './index';

export const useLogout = () => {
  const [logout] = useLogoutMutation();
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const { clearChannels } = useMessages();

  const handleClearStore = (cb?: () => void) => {
    localStorage.setItem('logout-event', 'logout' + Math.random());
    dispatch(clearUser());
    clearChannels();
    sessionStorage.clear();
    cb?.();
  };

  return (cb?: () => void) => {
    logout(user.accessToken)
      .unwrap()
      .finally(() => handleClearStore(cb));
  };
};
