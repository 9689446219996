import { Measurement } from 'pages/DeviceStatus/deviceStatus.types';

import { SideEffect } from 'widgets/prescriptionFlows/RenewalPrescriptionFlow/renewalPrescriptionFlow.settings';

import { ResponseProps } from '../services.types';

import { OrderType } from 'models/prescriptions.types';
import { TirzepatideMigrationProps } from 'models/user.types';

export type GetPagesReqProps = void;

export enum DosageToTake {
  double = 'double',
  standard = 'standard'
}

export enum LabsStatus {
  // completed = 'completed',
  ordered = 'ordered',
  required = 'required'
}

export enum LabsType {
  ash = 'ash',
  elation = 'elation'
}

export enum ActionType {
  checkIn = 'check-in',
  firstDeviceCheckIn = 'first-device-check-in',
  monthlyDeviceCheckIn = 'monthly-device-check-in',
  monthlyMedicationCheckIn = 'monthly-medication-check-in',
  renewal = 'renewal',
  weeklyDeviceCheckIn = 'weekly-device-check-in',
  weeklyMedicationCheckIn = 'weekly-medication-check-in'
}

type VideoChapter = {
  _id: string;
  completed?: boolean;
  name: string;
  startTimeMs: number;
};

export type WmSection = {
  _id: string;
  order: number;
  parentIds: string[] | null;
  title: string;
};

export type WmPage = {
  __v: number;
  _id: string;
  chapters?: VideoChapter[];
  createdAt: string;
  description?: string;
  fileUrl: string;
  order?: number;
  previewUrl?: string;
  published: boolean;
  section:
    | 'getting-started'
    | 'patient-stories'
    | 'processes-faqs'
    | 'guides'
    | 'behavior-mindset'
    | 'health-lifestyle-tips'
    | string;
  title: string;
  type: 'html' | 'pdf' | 'video';
  updatedAt: string;
  videoDurationSeconds: number | null;
  viewed?: boolean;
};

export type GetPagesResProps = ResponseProps<WmPage[], { sections: WmSection[] }>;

export type GetSurveyDetailsReqProps = {
  orderId: string;
  type: ActionType;
};

export type ReminderConfigProps = {
  default: number;
  limit: number;
  presets: number[];
};

export type LabsProps = {
  labsStatus: LabsStatus;
  type: LabsType;
};

export type SideEffectProps = {
  description?: string;
  input?: {
    hint: string;
    maxLength: number;
    minLength: number;
  };
  label: string;
  value: string;
};

export type ConflictPrescriptionProps = {
  createdAt: Date;
  lastShippedDate?: Date;
  medicationName: string;
  orderId: string;
  renewalDate: Date;
};

export type WeightType = {
  current: number;
  max: number;
  min: number;
};

export type GetSurveyDetailsResProps = ResponseProps<{
  _id: string;
  addBodyPhoto?: boolean;
  completedAt?: string;
  conflictPrescriptions?: ConflictPrescriptionProps[];
  createdAt: string;
  currentDoseQty: number;
  currentDoseUnit: string;
  deliveredDate?: string;
  isAppointmentRequired: boolean;
  isBrandedDrug: boolean;
  isFreeMedication?: boolean;
  isStableDose: boolean;
  lab?: LabsProps;
  medication: {
    currentDosePrice?: number;
    doseToTake?: DosageToTake;
    higherDosePrice?: number;
    isWeightMaintenance: boolean;
    name: string;
    requiredTakenDoseCount?: number;
    type: OrderType;
    willUserBeCharged?: boolean;
  };
  medicationName?: string;
  orderId: string;
  reminderConfig: ReminderConfigProps;
  sideEffects: SideEffectProps[];
  tirzepatideMigration?: TirzepatideMigrationProps;
  type: ActionType;
  updateShippingAddress?: boolean;
  updatedAt: string;
  weight: WeightType;
}>;

export type MarkPageViewedReqProps = {
  viewedChapters?: string[];
  wmPageId: string;
};

export type MarkPageViewedResProps = void;

export interface CreateCheckInSurveyReqProps {
  orderId: string;
}

export interface CreateRenewalReqProps {
  orderId: string;
}
export type CreateRenewalResProps = ResponseProps<{
  _id: string;
  createdAt: string;
  notificationDates: string[];
  orderId: string;
  prescriptions: {
    elationPrescriptionId: number;
  }[];
  type: ActionType;
  updatedAt: string;
  userId: string;
}>;
export interface SurveyResponsesReqProps {
  orderId: string;
  response: {
    doseToTake?: DosageToTake;
    // Should go with type "check-in"
    firstDoseTaken?: {
      answer?: boolean | null;
      date?: string;
      reason?: string;
    };
    otherSideEffects?: string;
    received?: {
      answer?: boolean | null;
    };
    sideEffects?: SideEffect[];
    // Should go with type "renewal"
    weight?: number;
  };
  type: ActionType;
}

export type SurveyResponsesResProps = ResponseProps<{
  wmRequiredAppointment?: { isRequired: boolean };
}>;

export type GetOrdersReqProps = { type?: 'Withings' | 'Ash'; types?: ('Withings' | 'Ash')[] };
export type GetOrdersResProps = ResponseProps<OrdersDataItemProps[]>;

export interface OrdersDataItemProps {
  deliveredDate: string;
  id: string;
  items: {
    kitId: string;
    orderId: string;
    originOrderId: string;
    partnerId: string;
    sku: string;
  }[];
  orderId: string;
  // ASK BE if we'll have this field for order, not for item
  // originOrderId?: string;
  shippingStatus: string;
  trackingNumber: string;
  type: string;
}

export type GetSurveyResProps = ResponseProps<SurveyProps>;

type SurveyFlowType = 'wmDevices' | 'wmScales';

export interface SurveyProps {
  completedAt: string | null;
  flow: SurveyFlowType;
  missingData:
    | [
        {
          daysLastUpdated: number;
          metricName: string;
        }
      ]
    | null;
  response: {
    bloodPressure?: {
      collectedDate: Date;
      completionMethod: 'manual' | 'auto';
      isCompleted: boolean;
      metricId: string;
      metrics: {
        bloodPressure: {
          DIA: number;
          SYS: number;
          pulse: number;
        };
        date: string;
      };
      shouldConfirm: boolean;
    };
    height?: {
      collectedDate: Date;
      completionMethod: 'manual' | 'auto';
      isCompleted: boolean;
      metricId: string;
      metrics: {
        height: number;
      };
      shouldConfirm: boolean;
    };
    waistCircumference?: {
      collectedDate: Date;
      completionMethod: 'manual' | 'auto';
      isCompleted: boolean;
      metricId: string;
      metrics: {
        waistCircumference: number;
      };
      shouldConfirm: boolean;
    };
    weight?: WeightProps | Partial<WeightProps> | null;
  };
}

interface WeightProps {
  collectedDate: Date;
  completionMethod: 'manual' | 'auto';
  isCompleted: boolean;
  metricId: string;
  metrics: {
    date: string;
    weight: number;
  };
  shouldConfirm: boolean;
}

export interface GetSurveyReqProps {
  orderId: string;
  type: string;
}

export type UpdateSurveyResProps = ResponseProps<SurveyProps>;

export interface UpdateSurveyReqProps {
  orderId: string;
  response: {
    bloodPressure?: {
      isCompleted: boolean;
    };
    height?: {
      metrics?: {
        height?: number;
      };
    };
    waistCircumference?: {
      metrics?: {
        waistCircumference?: number;
      };
    };
    weight?: {
      isCompleted: boolean;
    };
  };
  type: string;
}

export type SubmitSurveyResProps = ResponseProps<SurveyProps>;

export interface SubmitSurveyReqProps {
  orderId: string;
  type: string;
}

export type NewDeviceMeasurementsResProps = ResponseProps<SurveyProps>;

export interface NewDeviceMeasurementsReqProps {
  isValid: boolean;
  metricId: string;
  metricType: Measurement;
  orderId?: string;
  type?: string;
}

export interface SelectRenewalOrderReqProps {
  orderId: string;
}

export interface AddReminderReqProps {
  reminderDate: string;
  surveyId: string;
}
