import { selectNewAppointmentExtended } from 'store';

import Loader from 'shared/Loader';
import PaymentCheckout from 'widgets/PaymentCheckout';

import { DEFAULT_REFILL_APPT_CODE } from 'constants/defaults';
import { useAppSelector } from 'hooks';
import { useGetLifeMDPlusPlan } from 'hooks/useGetLifeMDPlusPlan';

import { ConfirmAndPayProps } from './confirmAndPay.types';

const ConfirmAndPay: React.FC<ConfirmAndPayProps> = ({
  currentPlan,
  isRequiredToUpgradeToLifeMDPlus,
  loading,
  selectedPlan,
  onUpgradePlan
}) => {
  const {
    callMethod,
    displayName,
    code,
    startTime,
    membershipData = { newPlanID: '', freeAppointmentInfo: undefined, planPricePoint: null }
  } = useAppSelector(selectNewAppointmentExtended);

  const { lifeMDPlusPlan, defaultPricePoint, isFetching } = useGetLifeMDPlusPlan();

  const isAsync = callMethod === 'message';
  const plan = isRequiredToUpgradeToLifeMDPlus ? lifeMDPlusPlan : currentPlan;
  const selectedPricePoint = isRequiredToUpgradeToLifeMDPlus
    ? defaultPricePoint
    : membershipData?.planPricePoint;
  const newSelectedPlan = isRequiredToUpgradeToLifeMDPlus ? lifeMDPlusPlan : selectedPlan;

  if (isFetching) {
    return <Loader isVisible={isFetching} />;
  }

  return (
    <div className="max-md:h-full max-md:pb-4">
      {plan ? (
        <PaymentCheckout
          callMethod={callMethod}
          category={displayName}
          existingPlan={plan}
          isAsync={isAsync}
          isRefillAppt={code === DEFAULT_REFILL_APPT_CODE}
          loading={loading}
          providerName={displayName}
          selectedPlan={newSelectedPlan || plan}
          selectedPricePoint={selectedPricePoint}
          time={startTime}
          isLifeMDPlusFlow
          onProceed={onUpgradePlan}
        />
      ) : (
        <>No selected plan</>
      )}
    </div>
  );
};

export default ConfirmAndPay;
