import { selectUser } from 'store';

import AsyncAppointmentStatus from 'widgets/createAppointment/extendedFlow/AsyncAppointmentStatus';

import { useAppSelector, useMessages } from 'hooks';

import { AsyncAppointmentProps } from './asyncAppointment.types';

const AsyncAppointment: React.FC<AsyncAppointmentProps> = ({ appointment }) => {
  const { totalMessageCountUpdate } = useMessages();
  const { medicalIntakeCompleted, identityVerified } = useAppSelector(selectUser);
  const apptTitle = appointment?.appointmentType?.displayName || 'Async';
  const isTaskCompleted = appointment?.appointmentStatus === 'careTeamSentMessage';
  const messagesCount = totalMessageCountUpdate?.totalCount || 0;

  return (
    <AsyncAppointmentStatus
      identityVerified={identityVerified}
      isCompleted={isTaskCompleted}
      isNewTask={false}
      medicalIntakeCompleted={medicalIntakeCompleted}
      messagesCount={messagesCount}
      title={`${apptTitle} appointment`}
    />
  );
};

export default AsyncAppointment;
