import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';

import { parsePhoneNumber } from 'utils/helpers';

import { getIcon } from '../pharmacy.settings';

import { PharmacyActionsCardProps } from './pharmacyActionsCard.types';

const PharmacyActionsCard: React.FC<PharmacyActionsCardProps> = ({
  onDeselect,
  selectedPharmacy,
  onChoose,
  onContinue,
  userPharmacy,
  distance,
  isLoading
}) => {
  const { url } = getIcon(selectedPharmacy, false, userPharmacy);

  const [isDeselect, toggleDeselect] = useToggle(false);
  const handleContinue = () => {
    onContinue?.();
    toggleDeselect();
  };

  return (
    <div className="absolute -inset-x-1/3 bottom-4 mx-auto flex w-fit flex-col gap-4 rounded-2xl bg-white p-4 shadow-xl max-md:bottom-0 max-md:w-full max-md:shadow-xl md:max-w-[calc(100%_-_32px)]">
      <div className="flex flex-col gap-1">
        <p className="flex items-center gap-1">
          {
            <img
              alt="store-logo"
              className={`size-8 ${selectedPharmacy.brand === 'kroger' ? '-mt-2' : ''}`}
              src={url}
            />
          }
          <b className="flex-1">{selectedPharmacy.name}</b>
          {distance && <span className="text-gray">{distance} mi</span>}
        </p>
        <p>
          {selectedPharmacy.address}, {selectedPharmacy.city}, {selectedPharmacy.state}{' '}
          {selectedPharmacy.zipCode}
        </p>
        <p className="text-gray">{parsePhoneNumber(selectedPharmacy.phone)}</p>
      </div>
      <div className="flex gap-4">
        {userPharmacy?.pharmacyId === selectedPharmacy.pharmacyId && !isLoading ? (
          <>
            <Common.Button
              className="pointer-events-none w-full justify-center text-green"
              preIcon="check"
              style="text-only"
            >
              My pharmacy
            </Common.Button>
            {isDeselect ? (
              <Common.Button
                className="w-full justify-center"
                color="white-alt"
                disabled={isLoading}
                isLoading={isLoading}
                onClick={onDeselect}
              >
                Deselect
              </Common.Button>
            ) : (
              <Common.Button
                className="w-full justify-center"
                color="blue"
                onClick={handleContinue}
              >
                Continue
              </Common.Button>
            )}
          </>
        ) : (
          <Common.Button color="blue" disabled={isLoading} isLoading={isLoading} onClick={onChoose}>
            Choose pharmacy
          </Common.Button>
        )}
      </div>
    </div>
  );
};

export default PharmacyActionsCard;
