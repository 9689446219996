import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppointmentItem, AppointmentsState } from './appointments.types';

const initialState: AppointmentsState = {
  upcomingAppointments: [],
  pastAppointments: [],
  currentAppointment: undefined,
  newAppointmentExtended: {
    isQualifiedForAsyncAppointment: null,
    src: '',
    status: 'pending',
    initialDate: '',
    uploadRequired: false,
    appointmentDescription: '',
    appointmentTypeId: '',
    callMethod: 'video',
    doctorId: '',
    endTime: '',
    isWeightManagementAppointment: false,
    startTime: '',
    membershipData: {
      planId: '',
      planPricePoint: null
    },
    displayName: ''
  },
  newAppointment: {
    status: 'pending',
    initialDate: '',
    appointmentDescription: '',
    appointmentTypeId: '',
    callMethod: 'video',
    doctorId: '',
    endTime: '',
    files: [],
    isWeightManagementAppointment: false,
    startTime: '',
    membershipData: {
      freeAppointmentInfo: {
        bookedFreeAppointmentDate: '',
        freeAppointmentsAmount: 0,
        isFree: false,
        periodEnd: '',
        periodStart: ''
      },
      newPP: null,
      newPlanID: ''
    },
    displayName: ''
  }
};

const appointmentsSlice = createSlice({
  initialState,
  name: 'appointments',
  reducers: {
    setUpcomingAppointments: (state, action: PayloadAction<AppointmentItem[]>) => {
      state.upcomingAppointments = action.payload;
    },
    setPastAppointments: (state, action: PayloadAction<AppointmentItem[]>) => {
      state.pastAppointments = action.payload;
    },
    setCurrentAppointment: (state, action: PayloadAction<AppointmentItem>) => {
      state.currentAppointment = action.payload;
    },
    setNewAppointment: (
      state,
      action: PayloadAction<Partial<AppointmentsState['newAppointment']>>
    ) => {
      state.newAppointment = { ...state.newAppointment, ...action.payload };
    },
    setMembershipData: (
      state,
      action: PayloadAction<Partial<AppointmentsState['newAppointment']['membershipData']>>
    ) => {
      state.newAppointment.membershipData = {
        ...state.newAppointment.membershipData,
        ...action.payload
      };
    },
    clearNewAppointment: (state) => {
      state.newAppointment = initialState.newAppointment;
    },
    setNewAppointmentExtended: (
      state,
      action: PayloadAction<Partial<AppointmentsState['newAppointmentExtended']>>
    ) => {
      state.newAppointmentExtended = { ...state.newAppointmentExtended, ...action.payload };
    },
    clearNewAppointmentExtended: (state) => {
      state.newAppointmentExtended = initialState.newAppointmentExtended;
    }
  }
});

export const {
  setUpcomingAppointments,
  setPastAppointments,
  setCurrentAppointment,
  setNewAppointment,
  clearNewAppointment,
  setMembershipData,
  setNewAppointmentExtended,
  clearNewAppointmentExtended
} = appointmentsSlice.actions;

export default appointmentsSlice.reducer;
