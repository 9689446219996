import { ReviewOrigin } from 'utils/enums';

import { BaseModalProps } from '../modal.types';

export interface PlatformReviewProps extends BaseModalProps {
  onSkip?: () => void;
  origin?: ReviewOrigin;
}

export const EMOJI_RATING = ['😊', '😡', '🙁', '😐', '🙂', '🤩'];
