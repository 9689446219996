import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';

import { BaseModalProps } from 'modals/modal.types';
import { notifyToast } from 'shared/Toast/Toast';

import { PathName } from 'utils/enums';

const RenewalPending: React.FC<BaseModalProps> = ({ onClose, isOpen }) => {
  const navigate = useNavigate();

  const handleNavigateToDashboard = () => {
    notifyToast('We’ve kept your existing renewal request and will notify you when it’s complete.');
    navigate(PathName.Home);
  };

  return (
    <Common.Modal
      close={onClose}
      description="You already have a pending prescription renewal. We’ll notified when it’s been completed and your prescription is on the way."
      isOpen={isOpen}
      size="sm"
      title="Renewal already pending"
    >
      <div className="flex justify-end gap-3 max-md:flex-col-reverse">
        <Common.Button color="white-alt" fullWidthOnMobile onClick={onClose}>
          Close
        </Common.Button>
        <Common.Button color="blue" fullWidthOnMobile onClick={handleNavigateToDashboard}>
          View dashboard
        </Common.Button>
      </div>
    </Common.Modal>
  );
};

export default RenewalPending;
