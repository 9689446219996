import { useEffect, useState } from 'react';
import { Common } from '@thecvlb/design-system/lib/src';

import { selectMifInfo, selectSignUp } from 'store';
import { AdditionalConditionsItemProps } from 'store/mif/mif.types';
import { setMifInformation } from 'store/mif/mifSlice';

import AdditionalConditions from 'widgets/mif/AdditionalConditions';

import { useAppDispatch, useAppSelector } from 'hooks';
import { useMifNavigate } from 'hooks/useMifNavigate';
import useSubmitMifParts from 'hooks/useSubmitMifParts';
import { handleRequestCatch } from 'utils/helpers';

import { MifStepProps } from '../mifSteps.types';
import ButtonsGroup from '../parts/ButtonsGroup';
import StepHeaderInfo from '../parts/StepHeaderInfo';

const AdditionalConditionsPage: React.FC<MifStepProps> = ({ moveToStep }) => {
  const {
    mifInfo: { additionalHealthConditions: storedData }
  } = useAppSelector(selectMifInfo);
  const { user } = useAppSelector(selectSignUp);

  const { isEdit, onNavigate } = useMifNavigate();
  const { sendMifPart, isLoading } = useSubmitMifParts();

  const [additionalConditionsData, setAdditionalConditionsData] = useState<
    AdditionalConditionsItemProps[]
  >([]);
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    const body = {
      accessToken: user?.accessToken,
      metrics: {
        additionalHealthConditions: additionalConditionsData
      }
    };

    sendMifPart(body)
      .catch(handleRequestCatch)
      .finally(() => onNavigate(() => moveToStep('next')));
  };

  const setSelectedValues = () => {
    dispatch(
      setMifInformation({
        data: additionalConditionsData,
        prop: 'additionalHealthConditions'
      })
    );
    handleSubmit();
  };

  useEffect(() => {
    setAdditionalConditionsData(storedData || []);
  }, []);

  return (
    <div className="intake-form-wrapper">
      <StepHeaderInfo
        dataTestId="additional_conditions"
        stepSubtitle="Select all that apply."
        stepTitle="Have you had difficulty with any of the following?"
      />
      <Common.Alert type="info" colorableBackground>
        Your responses will allow us to provide personalized recommendations which will help us
        determine the appropriate medication for you.
      </Common.Alert>
      <AdditionalConditions
        additionalConditions={additionalConditionsData}
        onUpdate={setAdditionalConditionsData}
      />
      <ButtonsGroup
        isDisabledNextBtn={additionalConditionsData.length === 0 || isLoading}
        loading={isLoading}
        nextBtnText={isEdit ? 'Update' : 'Next'}
        hidePrev
        onClickNext={setSelectedValues}
      />
    </div>
  );
};

export default AdditionalConditionsPage;
