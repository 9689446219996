import useWeightManagement from 'hooks/useWeightManagement';

import CareTypeCard from './CareTypeCard';
import { getBuildCards } from './careTypes.settings';
import { CareTypesProps } from './сareTypes.types';

const CareTypes: React.FC<CareTypesProps> = ({ onSelect }) => {
  const { isWeightManagement } = useWeightManagement();

  const getClassNames = (title: string) => {
    if (title === 'Talk to a doctor') {
      return 'scalable-card col-span-2';
    }
    if (title === 'Prescriptions' && cards.every((c) => c.title !== 'Labs')) {
      return 'scalable-card col-span-2';
    }
    return 'scalable-card';
  };

  const cards = getBuildCards(onSelect, isWeightManagement);

  return (
    <div className="grid grid-cols-2 gap-4">
      {cards.map((card) => (
        <CareTypeCard className={getClassNames(card.title)} key={card.title} {...card} />
      ))}
    </div>
  );
};

export default CareTypes;
