import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';

import { BaseModalProps } from 'modals/modal.types';

import { useMessages } from 'hooks';
import { MessageTab, PathName } from 'utils/enums';

const EarlyForRenewal: React.FC<BaseModalProps> = ({ onClose, isOpen }) => {
  const { generalHealth } = useMessages();

  const navigate = useNavigate();

  const handleNavigateToDashboard = () => {
    navigate({
      pathname: PathName.Messages,
      search: `tab=${MessageTab.CARE_TEAM}&chatId=${generalHealth}`
    });
  };

  return (
    <Common.Modal
      close={onClose}
      description="According to our records, it’s too early to renew this medication. If you think this is an error, please message our care team."
      isOpen={isOpen}
      size="sm"
      title="It’s too early for renewal"
    >
      <div className="flex justify-end gap-3 max-md:flex-col-reverse">
        <Common.Button color="white-alt" fullWidthOnMobile onClick={onClose}>
          Close
        </Common.Button>
        <Common.Button color="blue" fullWidthOnMobile onClick={handleNavigateToDashboard}>
          Message care team
        </Common.Button>
      </div>
    </Common.Modal>
  );
};

export default EarlyForRenewal;
