import { useNavigate } from 'react-router-dom';

import MigrateFromTirzepatide from 'containers/MigrateFromTirzepatide';
import { notifyToast } from 'shared/Toast/Toast';

import { PathName } from 'utils/enums';

const MigrateFromTirzepatidePage = () => {
  const navigate = useNavigate();

  return (
    <MigrateFromTirzepatide
      onComplete={() => {
        navigate(PathName.Home);
        notifyToast(
          <>
            <b>All set!</b> Your medication will be prescribed when you next renew your
            prescription.
          </>,
          {
            position: 'top-center'
          }
        );
      }}
      onLeaveFlow={() => navigate(PathName.Home)}
    />
  );
};

export default MigrateFromTirzepatidePage;
