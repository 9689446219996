import { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';

import {
  useAddToCartMutation,
  useGetSingleProductDetailsQuery,
  useLazyGetCartQuery
} from 'services/shop/shop';

import { selectShop } from 'store';
import { setActiveProductName } from 'store/shop/shopSlice';

import AccordionInfo from 'features/AccordionInfo/AccordionInfo';
import Loader from 'shared/Loader';

import { useAppDispatch, useAppSelector } from 'hooks';
import { PathName } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

const Product = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id = '' } = useParams<{ id: string }>();

  const [getCart] = useLazyGetCartQuery();
  const [addToCart, { isLoading }] = useAddToCartMutation();
  const { data, isFetching } = useGetSingleProductDetailsQuery(id);

  const { cart } = useAppSelector(selectShop);
  const [activeIndex, setActiveIndex] = useState(0);

  const productInfo = data?.data;
  const isItemIncludedToCart = cart.items.some((item) => item.productId === id);
  const isPriceWithDiscount = Number(productInfo?.msrpPrice) > Number(productInfo?.price.unitPrice);

  useTitle(`LifeMD: ${productInfo?.name || 'Product details'}`);

  const handleAddToCart = () => {
    if (isItemIncludedToCart) {
      return navigate(PathName.ShopCart);
    }
    addToCart({ productId: id, count: 1 })
      .unwrap()
      .then(() => getCart())
      .then(() => navigate(PathName.ShopCart))
      .catch(handleRequestCatch);
  };

  const getThumbs = () =>
    productInfo?.assets.map((asset) => (
      <img
        alt={asset.url}
        className="size-10 max-h-[40px] select-none object-contain"
        key={asset.url}
        src={asset.url}
      />
    )) || [];

  useEffect(() => {
    dispatch(setActiveProductName(productInfo?.name ?? ''));
    return () => {
      dispatch(setActiveProductName(''));
    };
  }, [productInfo]);

  return (
    <>
      <Loader isVisible={isFetching} />
      {!!productInfo && (
        <div className="flex flex-col gap-3">
          <div className="w-full flex-1 overflow-hidden">
            <Carousel
              centerSlidePercentage={100}
              renderThumbs={getThumbs}
              selectedItem={activeIndex}
              showArrows={false}
              showIndicators={false}
              showStatus={false}
              swipeScrollTolerance={20}
              thumbWidth={40}
              centerMode
              emulateTouch
              infiniteLoop
              preventMovementUntilSwipeScrollTolerance
              swipeable
              onChange={setActiveIndex}
            >
              {productInfo.assets.map((image) => (
                <div
                  className="select-none rounded-2xl bg-gray-100 p-2"
                  data-testid="product_image"
                  key={image.url}
                >
                  <img
                    alt="img"
                    className="max-h-[361px] object-contain"
                    key={image.url}
                    src={image.url}
                  />
                </div>
              ))}
            </Carousel>
          </div>
          <div className="flex-1 md:p-2.5">
            <div className="flex items-center justify-between">
              <div className="text-mLg">
                <h3 className="font-semibold text-gray-800" data-testid="opened_product_name">
                  {productInfo.name}
                </h3>
                <span className="text-lg text-gray">{productInfo.type}</span>
              </div>
              <div>
                <p className="text-mLg">
                  {isPriceWithDiscount ? (
                    <span>
                      <span className="text-green" data-testid="current_price">
                        <span className="mr-1 text-gray line-through" data-testid="old_price">
                          ${productInfo.msrpPrice}
                        </span>
                        ${productInfo.price.unitPrice}
                      </span>
                    </span>
                  ) : (
                    <span className="text-gray-700">${productInfo.price.unitPrice}</span>
                  )}
                </p>
              </div>
            </div>
            <p className="mt-3 text-mBase text-gray-600" data-testid="product_description">
              {productInfo.description}
            </p>
            <div className="my-6">
              <AccordionInfo items={productInfo.extraDescription} />
            </div>
            <div className="sticky bottom-0 bg-gray-100 py-4">
              <Common.Button
                className="mx-auto"
                color="blue"
                dataTestId="add_to_cart_view_in_cart_btn"
                isLoading={isLoading}
                size="lg"
                fullWidthOnMobile
                onClick={handleAddToCart}
              >
                {isItemIncludedToCart ? 'View in cart' : 'Add to cart'}
              </Common.Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Product;
