import { selectUser } from 'store';

import { PartnerNameType } from 'models/user.types';

import { useAppSelector } from './index';

const usePartnerPatient = (partnerName: PartnerNameType) => {
  const { companyPartners } = useAppSelector(selectUser);
  const isPartnerPatient = () => {
    const lowerPartnerName = partnerName.toLowerCase();
    return (
      companyPartners?.connectedPartners?.some(
        (p) => p.partnerName.toLowerCase() === lowerPartnerName
      ) ||
      String(companyPartners?.onboardingPartner?.partnerName)
        .toLowerCase()
        .includes(lowerPartnerName)
    );
  };

  return isPartnerPatient();
};

export default usePartnerPatient;
