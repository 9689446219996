import { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTitle, useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';
import dayjs from 'dayjs';
import HTMLReactParser from 'html-react-parser';

import { useGetDocumentsQuery } from 'services/documents/documents';
import {
  useGetSignableDocumentsQuery,
  useSignDocumentMutation,
  useUnSignDocumentMutation
} from 'services/general/general';

import { selectUser } from 'store';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
import { notifyToast } from 'shared/Toast/Toast';

import { useAppSelector } from 'hooks';
import { DateFormat, PathName } from 'utils/enums';

const HippaAgreement = () => {
  useTitle('LifeMD - Hippa Agreement');

  const navigate = useNavigate();
  const { accessToken, firstName, lastName } = useAppSelector(selectUser);
  const { data: templates, isFetching } = useGetSignableDocumentsQuery();
  const { data: documents } = useGetDocumentsQuery({
    pageNo: 0,
    category: 'signable-document'
  });
  const [signDocument, { isLoading: isLoadingSignDocument }] = useSignDocumentMutation();
  const [unSignDocument, { isLoading: isLoadingUnSignDocument }] = useUnSignDocumentMutation();

  const [isAgreed, toggleIsAgreed] = useToggle(false);

  const { _id: documentId, signedDate } =
    documents?.data.find((item) => item.subCategory === 'hipaa-marketing-agreement') || {};

  const { documentId: templateId, html } = templates?.data.find(
    (item) => item.type === 'hipaa-marketing-agreement'
  ) || {
    documentId: '',
    html: ''
  };

  const handleUnSignDocument = (displayNotification?: boolean) => {
    if (!documentId) return;
    unSignDocument({ documentId })
      .unwrap()
      .then(() => {
        if (!displayNotification) return;
        navigate(-1);
        displayToast(() => handleSignDocument());
      });
  };

  const handleSignDocument = (displayNotification?: boolean) => {
    if (!templateId) return;
    signDocument({ templateId, accessToken })
      .unwrap()
      .then(() => {
        if (!displayNotification) return;
        navigate(-1);
        displayToast(() => handleUnSignDocument());
      });
  };

  const displayToast = (cb: () => void) => {
    notifyToast(
      <span>
        <b>HIPAA Marketing Agreement</b> preferences changed.
      </span>,
      {
        closeButton: false,
        closeOnClick: true
      },
      cb,
      'UNDO'
    );
  };

  const handleClickContinue = () => {
    isAgreed ? handleSignDocument(true) : handleUnSignDocument(true);
  };

  useEffect(() => {
    signedDate && toggleIsAgreed(true);
  }, [signedDate]);

  return (
    <FadeWrapper className="flex flex-col gap-4 md:p-8">
      <Loader isVisible={isFetching} />
      <NavLink className="flex items-center gap-3 max-md:hidden" to={PathName.Legal}>
        <Common.Icon name="arrow-left" />
        <h1 className="text-xl font-bold">HIPAA Marketing Agreement</h1>
      </NavLink>
      <div className="mx-auto flex w-full max-w-[500px] flex-col gap-8">
        <div className="text-primary-700" data-testid="agreement_block">
          {HTMLReactParser(html)}
        </div>
        <div className="flex flex-col gap-4">
          {signedDate && (
            <div className="flex flex-col gap-2 py-6">
              <p className="text-mXs font-semibold text-primary-700">
                Agreed on <b>{dayjs(signedDate).format(DateFormat.MM_DD_YYYY)}</b> by
              </p>
              <p className="text-gray">
                {firstName} {lastName}
              </p>
            </div>
          )}
          <Common.Checkbox
            checked={isAgreed}
            color="blue"
            disabled={isFetching}
            onChange={toggleIsAgreed}
          >
            I acknowledge and agree to the statements written above.
          </Common.Checkbox>
          <Common.Button
            className="mx-auto"
            color="blue"
            dataTestId="continue_btn"
            disabled={isFetching || !!signedDate === isAgreed}
            isLoading={isLoadingSignDocument || isLoadingUnSignDocument}
            fullWidthOnMobile
            onClick={handleClickContinue}
          >
            Save changes
          </Common.Button>
        </div>
      </div>
    </FadeWrapper>
  );
};
export default HippaAgreement;
