import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { ProgressBarProps } from './progressBar.types';

const ProgressBar: React.FC<ProgressBarProps> = ({
  step,
  totalSteps,
  color = 'secondary',
  height = '',
  rounded = false
}) => {
  const [progress, setProgress] = useState('0%');

  useEffect(() => {
    requestAnimationFrame(() => {
      setProgress(`${(step / totalSteps) * 100}%`);
    });
  }, [step, totalSteps]);

  return (
    <div
      className={classNames('w-full bg-gray-200 transition-all duration-300', {
        'rounded-lg': rounded
      })}
      data-testid="progress_bar"
    >
      <div
        className={classNames(`bg-${color} ${height ? `h-${height}` : 'h-1'}`, {
          'rounded-lg': rounded,
          'transition-all duration-300': totalSteps > 1
        })}
        data-testid="highlighted_progress_bar"
        style={{ width: parseInt(progress) > 100 ? '100%' : progress }}
      />
    </div>
  );
};

export default ProgressBar;
