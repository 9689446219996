// import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

// import { ScrollVisibilityApiType } from 'widgets/signUp/ProviderInfoCard/SlotsList/slotsList.types';
// import { useDrag } from 'hooks/useDrag';
import { PathName } from 'utils/enums';

import { Product } from 'models/shop';

import { ProductsListProps } from './productsList.types';

const ProductsList: React.FC<ProductsListProps> = ({ products, isList }) => {
  // const { dragStart, dragStop, dragMove } = useDrag();
  // const handleDrag =
  //   ({ scrollContainer }: ScrollVisibilityApiType) =>
  //   (ev: React.MouseEvent) =>
  //     dragMove(ev, (posDiff) => {
  //       if (scrollContainer.current) {
  //         scrollContainer.current.scrollLeft += posDiff;
  //       }
  //     });
  type ProductProps = Omit<Product, 'assets' | 'description' | 'extraDescription'> & {
    className?: string;
  };
  const product = ({
    _id,
    label,
    name,
    msrpPrice,
    type,
    price,
    featuredAsset,
    className = ''
  }: ProductProps) => (
    <Link
      className={classNames('group flex flex-col gap-1', className)}
      key={_id}
      to={PathName.ShopProducts + `/${_id}`}
    >
      <div
        className="relative overflow-hidden rounded-xl bg-white p-6 transition-all"
        data-testid="product_item"
      >
        {label && (
          <div className="absolute right-4 top-4 rounded-[4px] bg-blue-100 px-2 py-1 text-sm font-semibold text-blue-600">
            {label}
          </div>
        )}
        <img
          alt={name}
          className="mx-auto h-[115px] max-w-full object-contain transition-all duration-1000 group-hover:scale-[130%]"
          data-testid="product_img"
          src={featuredAsset.url}
        />
      </div>
      <div>
        <h4 className="text-mBase text-black">{name}</h4>
        <span className="text-mSm text-gray">{type}</span>
        <p>
          {Number(msrpPrice) > Number(price.unitPrice) ? (
            <span className="text-mSm text-green">
              ${price.unitPrice} <span className="mr-1 text-gray line-through">${msrpPrice}</span>
            </span>
          ) : (
            <span className="text-mSm text-green">${price.unitPrice}</span>
          )}
        </p>
      </div>
    </Link>
  );

  const mappedProducts = () => {
    return products.map((p, i) =>
      product({
        ...p,
        className: i === products.length - 1 && products.length % 2 > 0 ? 'col-span-2' : ''
      })
    );
  };

  const list = (
    // <ScrollMenu
    //   itemClassName="flex-none max-w-[160px]"
    //   scrollContainerClassName="gap-4"
    //   transitionBehavior={{ behavior: 'smooth' }}
    //   onMouseDown={() => dragStart}
    //   onMouseMove={handleDrag}
    //   onMouseUp={() => dragStop}
    // >
    <div className="grid grid-cols-2 gap-4">{mappedProducts()}</div>
    // </ScrollMenu>
  );

  const grid = (
    <div
      className={classNames('grid gap-3 md:gap-4', {
        '!grid-cols-1': products.length === 1,
        'grid-cols-2': !(products.length % 1)
        // 'md:grid-cols-3': products.length > 2
      })}
    >
      {mappedProducts()}
    </div>
  );
  return isList ? list : grid;
};

export default ProductsList;
