import { useEffect } from 'react';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system';

import { useGetSettingsQuery, useUpdateSettingsMutation } from 'services/settings/settings';
import { GetSettingsNotificationProps } from 'services/settings/settings.types';

import { selectNotificationPreferences } from 'store';
import { setSettings, toggleSetting } from 'store/settings/settingsSlice';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';

import { useAppDispatch, useAppSelector } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import { NotificationNames, NotificationTypes } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

const Notifications: React.FC = () => {
  useTitle('LifeMD - Notifications');
  const logEvent = useAnalytics();
  const dispatch = useAppDispatch();
  const { emailNotification, browserNotification, textNotification } = useAppSelector(
    selectNotificationPreferences
  );
  const { data } = useGetSettingsQuery();
  const [updateSettings] = useUpdateSettingsMutation();

  const getNotificationsData = (settingsNotification: GetSettingsNotificationProps) => ({
    [NotificationNames.Messages]: {
      isChecked: settingsNotification.messages,
      label: 'Messages'
    },
    [NotificationNames.AppointmentReminder]: {
      isChecked: settingsNotification.appointmentReminder,
      label: 'Appointment reminders'
    },
    [NotificationNames.AppointmentChanges]: {
      isChecked: settingsNotification.appointmentChanges,
      label: 'Appointment changes'
    },
    [NotificationNames.newOffers]: {
      isChecked: settingsNotification.newOffers,
      label: 'News & offers'
    }
  });

  const handleUpdateSettingsThen = (
    settingType: NotificationTypes,
    settingName: NotificationNames
  ) => {
    dispatch(
      toggleSetting({
        notificationName: settingName,
        notificationType: settingType
      })
    );
  };

  const handleChange = (
    settingType: NotificationTypes,
    settingName: NotificationNames,
    isChecked: boolean
  ) => {
    switch (settingName) {
      case 'appointmentChanges':
        logEvent('notifications_appt_changes_switch_click');
        break;
      case 'appointmentReminder':
        logEvent('notifications_appt_reminders_switch_click');
        break;
      case 'messages':
        logEvent('notifications_messages_switch_click');
        break;
      case 'newOffers':
        logEvent('notifications_new_offers_switch_click');
        break;
    }
    updateSettings({
      notificationPreferences: {
        [settingType]: {
          [settingName]: !isChecked
        }
      }
    })
      .unwrap()
      .then(() => handleUpdateSettingsThen(settingType, settingName))
      .catch(handleRequestCatch);
  };

  const handleUpdatedData = () => {
    data && dispatch(setSettings(data.data));
  };

  useEffect(handleUpdatedData, [data]);

  const subheaderWrapperClassName = 'flex items-center gap-2.5';
  const subheaderClassName = 'text-mBase font-semibold';

  const getMobileItems = (
    settingsData: GetSettingsNotificationProps,
    settingType: NotificationTypes
  ) => (
    <div className="mt-3 flex flex-col gap-5 rounded-lg border border-gray-200 bg-white p-4">
      {Object.entries(getNotificationsData(settingsData)).map(
        ([settingName, { label, isChecked = false }], index) => (
          <div
            className="flex items-center"
            data-testid={`notification_toggle_${isChecked ? 'enabled' : 'disabled'}`}
            key={settingName + index}
          >
            <p className="flex-1 text-mBase font-medium">{label}</p>
            <Common.Toggle
              checked={isChecked}
              color="blue"
              size="lg"
              onClick={() => handleChange(settingType, settingName as NotificationNames, isChecked)}
            />
          </div>
        )
      )}
    </div>
  );
  return (
    <FadeWrapper>
      <div className="flex flex-col gap-8">
        <div>
          <div className={subheaderWrapperClassName}>
            <Common.Icon name="email" />
            <h2 className={subheaderClassName}>Email notifications</h2>
          </div>
          {getMobileItems(emailNotification, NotificationTypes.EmailNotification)}
        </div>
        <div>
          <div className={subheaderWrapperClassName}>
            <Common.Icon name="chat-outline" />
            <h2 className={subheaderClassName}>Text notifications</h2>
          </div>
          {getMobileItems(textNotification, NotificationTypes.TextNotification)}
        </div>
        <div>
          <div className={subheaderWrapperClassName}>
            <Common.Icon name="iphone" />
            <h2 className={subheaderClassName}>App notifications</h2>
          </div>
          {getMobileItems(browserNotification, NotificationTypes.BrowserNotification)}
        </div>
      </div>
    </FadeWrapper>
  );
};

export default Notifications;
