import { useEffect, useState } from 'react';

import { selectMifInfo, selectSignUp } from 'store';
import { HealthConditionsItemProps } from 'store/mif/mif.types';
import { setMifInformation } from 'store/mif/mifSlice';

import HealthConditions from 'widgets/mif/HealthConditions';

import { useAppDispatch, useAppSelector } from 'hooks';
import { useMifNavigate } from 'hooks/useMifNavigate';
import useSubmitMifParts from 'hooks/useSubmitMifParts';
import { handleRequestCatch } from 'utils/helpers';

import { MifStepProps } from '../mifSteps.types';
import ButtonsGroup from '../parts/ButtonsGroup';
import StepHeaderInfo from '../parts/StepHeaderInfo';

const HealthConditionsStep: React.FC<MifStepProps> = ({ moveToStep }) => {
  const {
    mifInfo: { healthConditions: storedData }
  } = useAppSelector(selectMifInfo);
  const { sendMifPart, isLoading } = useSubmitMifParts();
  const { isEdit, onNavigate } = useMifNavigate();

  const { user } = useAppSelector(selectSignUp);
  const [healthConditionsData, setHealthConditionsData] = useState<HealthConditionsItemProps[]>([]);
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    const body = {
      accessToken: user?.accessToken,
      metrics: {
        healthConditions: healthConditionsData
      }
    };

    sendMifPart(body)
      .catch(handleRequestCatch)
      .finally(() => onNavigate(() => moveToStep('next')));
  };

  const setSelectedValues = () => {
    dispatch(
      setMifInformation({
        data: healthConditionsData,
        prop: 'healthConditions'
      })
    );
    handleSubmit();
  };

  useEffect(() => {
    setHealthConditionsData(storedData || []);
  }, []);

  return (
    <div className="intake-form-wrapper">
      <StepHeaderInfo
        dataTestId="health_conditions"
        stepSubtitle="Have you had any of the following medical conditions?"
        stepTitle="Health conditions"
      />
      <HealthConditions
        dataTestId="checkbox"
        healthConditions={healthConditionsData}
        onUpdate={setHealthConditionsData}
      />
      <ButtonsGroup
        isDisabledNextBtn={healthConditionsData.length === 0 || isLoading}
        loading={isLoading}
        nextBtnText={isEdit ? 'Update' : 'Next'}
        hidePrev
        onClickNext={setSelectedValues}
      />
    </div>
  );
};

export default HealthConditionsStep;
