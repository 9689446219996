import { selectMifInfo } from 'store';

import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
import Loader from 'shared/Loader';

import { LINK_TO_SUPPORT } from 'constants/externalLinks';
import { useAppSelector, useQuery } from 'hooks';
import { useAppointmentMif } from 'hooks/useAppointmentMif';

import { AppointmentMifProps } from './appointmentMif.types';
import { DynamicQuestion, MifStopper, MifSummary, MoreAboutYou } from './components';

const AppointmentMif: React.FC<AppointmentMifProps> = ({
  mifCode,
  behaviorContext = 'strict',
  onContinue,
  category = '',
  loading: externalLoading = false
}) => {
  const currentStep = useQuery().get('mif-s') ?? '';
  const { appointmentMif = [] } = useAppSelector(selectMifInfo);

  const {
    loading,
    steps,
    currentQuestion,
    isUninitialized,
    // activeStepIndex,
    flowLength,
    moveToStep,
    handleSubmit,
    isSubmittingResults,
    description,
    onLeaveFlow,
    currentQuestionIndex,
    checkIfMifHasDQConditions,
    isBackAnimation
  } = useAppointmentMif(mifCode);
  const getGroupTitle = (): string => {
    if (typeof currentQuestion === 'string') {
      return '';
    }
    const stepsOfCurrentGroup = steps.filter(
      (step) => !!step.config.group && step.config.group === currentQuestion?.config.group
    );
    const groupTitle = stepsOfCurrentGroup.find((item) => item.config.type === 'info');
    if (!!groupTitle && 'groupTitle' in groupTitle.config) {
      return groupTitle.config.groupTitle ?? '';
    }
    return '';
  };

  const handleContinue = () => {
    // if we go through flow as part of createAccount
    if (typeof onContinue === 'function') {
      checkIfMifHasDQConditions();
      onContinue();
    } else {
      handleSubmit();
    }
  };
  const content = () => {
    if (currentStep === 'prevent') {
      return (
        <MifStopper
          onContactSupport={() => window.open(LINK_TO_SUPPORT, '_blank')}
          onLeave={onLeaveFlow}
        />
      );
    }
    switch (currentStep) {
      case 'intro':
        return (
          <SlideAnimateWrapper className="h-full" isBackAnimation={isBackAnimation} key="intro">
            <MoreAboutYou
              description={
                description ||
                'Before creating your appointment, we’ll ask a few diet and lifestyle questions.'
              }
              onClickContinue={() => moveToStep('next')}
            />
          </SlideAnimateWrapper>
        );
      case 'results':
        return (
          <SlideAnimateWrapper isBackAnimation={false} key="results">
            <MifSummary
              answers={appointmentMif}
              category={category}
              loading={isSubmittingResults || externalLoading}
              questions={steps}
              onClickContinue={handleContinue}
              onClickEdit={(step, shouldRedirect) => {
                moveToStep(
                  step,
                  shouldRedirect ? new URLSearchParams({ 'step-action': 'edit' }) : undefined
                );
              }}
            />
          </SlideAnimateWrapper>
        );
      default:
        return (
          !!currentQuestion &&
          typeof currentQuestion !== 'string' && (
            <DynamicQuestion
              behaviorContext={behaviorContext}
              category={category}
              config={currentQuestion.config}
              currentQuestionIndex={currentQuestionIndex}
              groupTitle={getGroupTitle()}
              isBackAnimation={isBackAnimation}
              mifLength={flowLength}
              onClickContinue={(v) =>
                moveToStep('next', v ? new URLSearchParams({ 'v-c': String(v) }) : undefined)
              }
              onDQCondition={() => moveToStep('prevent')}
            />
          )
        );
    }
  };

  return loading || isUninitialized ? (
    <Loader isVisible />
  ) : (
    <div className="relative flex grow flex-col max-md:h-full">
      <div className="w-full max-w-screen-sm grow md:mx-auto">{content()}</div>
    </div>
  );
};

export default AppointmentMif;
