import { ChangeEvent, useState } from 'react';
import { Common } from '@thecvlb/design-system/lib/src';

import { useGetProductsQuery } from 'services/shop/shop';
import { CategoryType } from 'services/shop/shop.types';

import ProductsList from 'features/ProductsList';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';

import { useQuery } from 'hooks';

const Products = () => {
  const [searchKey, setSearchKey] = useState('');
  const category = useQuery().get('category') as CategoryType;
  const isLabs = category === 'labs';

  const { data, isLoading } = useGetProductsQuery(
    {
      category
    },
    {
      skip: !category
    }
  );

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const products =
    data?.data.filter((el) => el.name.toLowerCase().includes(searchKey.toLowerCase())) || [];

  return (
    <FadeWrapper className="flex flex-col gap-8">
      <Loader isVisible={isLoading} />
      {isLabs && (
        <Common.SearchInput
          dataTestId="search_input"
          placeholder="Search"
          value={searchKey}
          onChange={handleInputChange}
        />
      )}
      <div>
        <h3 className="py-3 text-mBase font-semibold">{isLabs ? 'All labs' : 'All devices'}</h3>
        <ProductsList products={products} />
      </div>
    </FadeWrapper>
  );
};

export default Products;
