import { createApi } from '@reduxjs/toolkit/query/react';

import { setAshOrders, setOrders } from 'store/orders/ordersSlice';

import { baseQueryWithReauth } from 'utils/services';

import {
  ChangeOrdersStatusReqProps,
  ChangeOrdersStatusResProps,
  GetAshOrdersResProps,
  GetOrderReqProps,
  GetOrderResProps,
  GetOrdersReqProps,
  GetOrdersResProps
} from './orders.types';

export const ordersApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    changeOrdersStatus: build.mutation<ChangeOrdersStatusResProps, ChangeOrdersStatusReqProps>({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/orders/change-status'
      })
    }),
    getOrder: build.query<GetOrderResProps, GetOrderReqProps>({
      query: (params) => ({
        method: 'GET',
        params,
        responseHandler: (response: Response) => response.blob().then((res) => res),
        url: '/orders/details'
      })
    }),
    getOrders: build.query<GetOrdersResProps, GetOrdersReqProps>({
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        if (data) {
          dispatch(setOrders(data.data));
        }
      },
      query: () => '/orders'
    }),
    getAshOrders: build.query<GetAshOrdersResProps, void>({
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        if (data) {
          dispatch(setAshOrders(data.data));
        }
      },
      query: () => '/orders/labs'
    })
  }),
  reducerPath: 'ordersApi',
  tagTypes: ['Orders']
});

export const {
  useGetOrdersQuery,
  useGetAshOrdersQuery,
  useLazyGetOrdersQuery,
  useLazyGetOrderQuery,
  useChangeOrdersStatusMutation
} = ordersApi;
