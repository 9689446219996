import { JSX, ReactElement } from 'react';
import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';

import { selectNewAppointmentExtended, selectUser } from 'store';

import AppointmentMif from 'pages/AppointmentMif';

import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
// import AttachFiles from 'widgets/createAppointment/AttachFiles';
import Confirmation from 'widgets/createAppointment/Confirmation';
import DateAndTime from 'widgets/createAppointment/DateAndTime';
import AdditionalInformation from 'widgets/createAppointment/extendedFlow/AdditionalInformation';
import AppointmentTypesPicker from 'widgets/createAppointment/extendedFlow/AppointmentTypesPicker';
import AsyncAppointmentStatus from 'widgets/createAppointment/extendedFlow/AsyncAppointmentStatus';
import ChooseTypeOfCare from 'widgets/createAppointment/extendedFlow/ChooseTypeOfCare';
import ConfirmAndPay from 'widgets/createAppointment/extendedFlow/ConfirmAndPay';
import ConfirmWithoutSubscription from 'widgets/createAppointment/extendedFlow/ConfirmWithoutSubscription';
import Address from 'widgets/createAppointment/extendedFlow/createAccount/Address';
import Dob from 'widgets/createAppointment/extendedFlow/createAccount/DOB';
import Intro from 'widgets/createAppointment/extendedFlow/createAccount/Intro';
import Password from 'widgets/createAppointment/extendedFlow/createAccount/Password';
import PersonalDetails from 'widgets/createAppointment/extendedFlow/createAccount/PersonalDetails';
import PhoneNumber from 'widgets/createAppointment/extendedFlow/createAccount/PhoneNumber';
import DetailsForProviders from 'widgets/createAppointment/extendedFlow/DetailsForProvider';
import LabsToReview from 'widgets/createAppointment/extendedFlow/LabsToReview';
import PrescriptionFor from 'widgets/createAppointment/extendedFlow/PrescriptionFor';
import PrescriptionsType from 'widgets/createAppointment/extendedFlow/PrescriptionsType';
import QualifiedForAsync from 'widgets/createAppointment/extendedFlow/QualifiedForAsync';
import RenewPrescription from 'widgets/createAppointment/extendedFlow/RenewPrescription';
import SubscriptionRequired from 'widgets/createAppointment/extendedFlow/SubscriptionRequired';
import RedFlags from 'widgets/createAppointment/RedFlags';

import { DEFAULT_LAB_TESTS_APPT_CODE } from 'constants/defaults';
import { useAppSelector } from 'hooks';
import { PathName } from 'utils/enums';

import { PossibleStepsType } from '../createAppointmentExtended.types';

import { Props } from './content.types';

const renderWithAnimation = (
  component: JSX.Element,
  key: string,
  params?: {
    className?: string;
    isBackAnimation?: boolean;
  }
) => (
  <SlideAnimateWrapper
    className={classNames(
      'mx-auto w-full max-w-[500px] p-4 md:pt-8 max-md:flex-1',
      params?.className ?? ''
    )}
    isBackAnimation={!!params?.isBackAnimation}
    key={key}
  >
    {component}
  </SlideAnimateWrapper>
);

const Content: React.FC<Props> = ({
  currentPlan,
  loading = false,
  step,
  moveToStep,
  isBackAnimation,
  onSelectDateAndTime,
  selectedPlan,
  onUpgradePlan,
  appointmentTypes,
  onCreateAccount,
  exitFlow,
  onSubmitMIF,
  isRequiredToUpgradeToLifeMDPlus
}) => {
  const {
    accessToken,
    doctorId: myDoctorID,
    medicalIntakeCompleted,
    identityVerified,
    activePlanCode,
    activePricePoint
  } = useAppSelector(selectUser);
  const {
    appointmentTypeId,
    appointmentDescription,
    initialDate,
    startTime,
    endTime,
    _id,
    callMethod,
    code,
    displayName,
    doctorId,
    mifCode,
    // files,
    asyncAllowed,
    membershipData = {
      planId: '',
      planPricePoint: null
    },
    userState,
    src
  } = useAppSelector(selectNewAppointmentExtended);

  const isLabTestsAppointment = code === DEFAULT_LAB_TESTS_APPT_CODE;

  const content: { [key in PossibleStepsType[number]]: ReactElement } = {
    'details-for-provider': renderWithAnimation(
      <DetailsForProviders
        appointmentDescription={appointmentDescription}
        category={displayName}
        placeholder={isLabTestsAppointment ? '(Optional) Add details here...' : undefined}
        title={
          isLabTestsAppointment
            ? 'Are there any details about your labs you’d like to discuss?'
            : undefined
        }
        onClickNext={(appointmentDescription) =>
          moveToStep({
            step: 'details-for-provider',
            data: { appointmentDescription, hasAccount: !!accessToken }
          })
        }
      />,
      'details-for-provider',
      { isBackAnimation }
    ),
    'labs-to-review': renderWithAnimation(
      <LabsToReview
        onSelect={(resultsID) => {
          moveToStep({
            step: 'labs-to-review',
            data: {
              labsResultID: resultsID
            }
          });
        }}
      />,
      'labs-to-review',
      {
        isBackAnimation
      }
    ),
    'payment-checkout': renderWithAnimation(
      activePricePoint ? (
        <ConfirmAndPay
          currentPlan={currentPlan}
          isRequiredToUpgradeToLifeMDPlus={isRequiredToUpgradeToLifeMDPlus}
          loading={loading}
          selectedPlan={selectedPlan}
          onUpgradePlan={() => onUpgradePlan({ isNewUser: false })}
        />
      ) : (
        <ConfirmWithoutSubscription
          loading={loading}
          onProceed={(formData) => onUpgradePlan({ formData, isNewUser: true })}
        />
      ),
      'payment-checkout',
      { isBackAnimation, className: '!max-w-screen-sm' }
    ),
    confirmation: renderWithAnimation(
      callMethod === 'message' ? (
        <AsyncAppointmentStatus
          identityVerified={identityVerified}
          medicalIntakeCompleted={medicalIntakeCompleted}
          title={identityVerified && medicalIntakeCompleted ? 'You’re all set!' : 'Success!'}
          isNewTask
        />
      ) : (
        <Confirmation
          appointment={{
            apptName: displayName,
            description: appointmentDescription,
            endTime,
            id: _id ?? '',
            startTime,
            title: isLabTestsAppointment
              ? `${displayName} appointment`
              : 'Appointment is scheduled',
            subtitle: isLabTestsAppointment ? 'Appointment scheduled.' : ''
          }}
          providerId={doctorId}
        />
      ),
      'confirmation',
      { isBackAnimation }
    ),
    'date-time': renderWithAnimation(
      <DateAndTime
        accessToken={!!activePlanCode ? accessToken : undefined}
        appointmentTypeId={appointmentTypeId}
        doctorId={myDoctorID}
        initialDate={initialDate}
        isSignUp={!activePlanCode}
        loading={loading}
        onboardingUserData={
          !!activePlanCode ? undefined : { planID: membershipData?.planId, state: userState ?? '' }
        }
        providerType={!myDoctorID || isLabTestsAppointment ? 'any' : undefined}
        showSelectorBetweenProviders={!isLabTestsAppointment}
        updateStartDate={(_, value, valueEnd, id, displayName) => {
          onSelectDateAndTime({
            startTime: value,
            endTime: valueEnd,
            doctorId: id || myDoctorID,
            providerName: displayName
          });
        }}
        onSelect={() => {
          moveToStep({
            step: 'date-time'
          });
        }}
      />,
      'date-time',
      { isBackAnimation, className: 'max-w-screen-sm' } // as it was before, to find out new size
    ),
    // files: renderWithAnimation(
    //   <AttachFiles
    //     files={files ?? []}
    //     setFiles={(f) => {
    //       fillAppointmentData({
    //         step: 'files',
    //         data: {
    //           files: f
    //         }
    //       });
    //     }}
    //     onBack={() => moveToStep('prev')}
    //     onContinue={() => moveToStep('next')}
    //   />,
    //   'files',
    //   {
    //     isBackAnimation,
    //     className: 'flex flex-col h-full'
    //   }
    // ),
    'choose-type-of-care': renderWithAnimation(
      <ChooseTypeOfCare
        onSelect={(data) =>
          moveToStep({
            step: 'choose-type-of-care',
            data
          })
        }
      />,
      'choose-type-of-care',
      {
        isBackAnimation,
        className: 'max-w-screen-sm'
      }
    ),
    'red-flags': renderWithAnimation(
      <RedFlags
        appointmentTypes={appointmentTypes}
        loading={loading}
        src={src}
        onSelect={(data) =>
          moveToStep({
            step: 'red-flags',
            data
          })
        }
        onSkip={() => moveToStep('prev')}
      />,
      'red-flags',
      { isBackAnimation, className: 'max-w-screen-sm' }
    ),
    'prescription-for': renderWithAnimation(
      <PrescriptionFor
        onChoosePrescriptionFor={(data) => moveToStep({ step: 'prescription-for', data })}
      />,
      'prescription-for',
      { isBackAnimation }
    ),
    'appointment-types-picker': renderWithAnimation(
      <AppointmentTypesPicker
        appointmentTypes={appointmentTypes}
        onClickNext={(data) => {
          moveToStep({
            step: 'appointment-types-picker',
            data
          });
        }}
      />,
      'appointment-types-picker',
      {
        isBackAnimation
      }
    ),
    'prescriptions-type': renderWithAnimation(
      <PrescriptionsType
        appointmentTypes={appointmentTypes}
        onChoosePrescriptionsType={(data) => moveToStep({ step: 'prescriptions-type', data })}
      />,
      'prescriptions-type',
      {
        isBackAnimation
      }
    ),
    'is-renew': renderWithAnimation(
      <RenewPrescription onSelect={() => moveToStep({ step: 'is-renew' })} />,
      'is-renew',
      {
        isBackAnimation
      }
    ),
    mif: renderWithAnimation(
      <AppointmentMif
        behaviorContext={asyncAllowed ? 'allow-dq' : 'strict'}
        category={displayName}
        loading={loading}
        mifCode={mifCode}
        onContinue={() => onSubmitMIF(!!accessToken)}
      />,
      'mif',
      {
        isBackAnimation
      }
    ),
    'create-account-intro': renderWithAnimation(
      <Intro onContinue={() => moveToStep('next')} />,
      'create-account-intro',
      {
        isBackAnimation
      }
    ),
    'additional-information': renderWithAnimation(
      <AdditionalInformation
        category={displayName}
        required={code === DEFAULT_LAB_TESTS_APPT_CODE}
        title={
          code === DEFAULT_LAB_TESTS_APPT_CODE
            ? 'Please upload the labs that you would like to review.'
            : undefined
        }
        onContinue={(data) => moveToStep({ data, step: 'additional-information' })}
      />,
      'additional-information',
      {
        isBackAnimation
      }
    ),
    'create-account-personal-details': renderWithAnimation(
      <PersonalDetails
        onContinue={(data) =>
          moveToStep({
            step: 'create-account-personal-details',
            data
          })
        }
        onExit={(email) =>
          exitFlow({
            pathname: PathName.Login,
            search: `?new=false&with=email&email=${email}`
          })
        }
      />,
      'create-account-personal-details',
      {
        isBackAnimation
      }
    ),
    'create-account-address': renderWithAnimation(
      <Address
        onContinue={(data) =>
          moveToStep({
            step: 'create-account-address',
            data
          })
        }
      />,
      'create-account-address',
      {
        isBackAnimation
      }
    ),
    'create-account-dob': renderWithAnimation(
      <Dob
        onContinue={(data) =>
          moveToStep({
            step: 'create-account-dob',
            data
          })
        }
      />,
      'create-account-dob',
      {
        isBackAnimation
      }
    ),
    'create-account-phone': renderWithAnimation(
      <PhoneNumber
        onContinue={(data) =>
          moveToStep({
            step: 'create-account-phone',
            data
          })
        }
      />,
      'create-account-phone',
      {
        isBackAnimation
      }
    ),
    'create-account-password': renderWithAnimation(
      <Password loading={loading} onContinue={onCreateAccount} />,
      'create-account-password',
      {
        isBackAnimation
      }
    ),
    'qualified-for-async': renderWithAnimation(
      <QualifiedForAsync
        category={displayName}
        isRequiredToUpgradeToLifeMDPlus={isRequiredToUpgradeToLifeMDPlus}
        onSelect={(data) =>
          moveToStep({
            step: 'qualified-for-async',
            data
          })
        }
      />,
      'qualified-for-async',
      {
        isBackAnimation
      }
    ),
    'subscription-required': renderWithAnimation(
      <SubscriptionRequired
        callMethod={callMethod}
        category={displayName}
        onSelect={(data) =>
          moveToStep({
            step: 'subscription-required',
            data
          })
        }
      />,
      'subscription-required',
      {
        isBackAnimation
      }
    )
  };

  return <AnimatePresence mode="wait">{content[step]}</AnimatePresence>;
};

export default Content;
