import { useEffect, useState } from 'react';
import { Common } from '@thecvlb/design-system';

import useAnalytics from 'hooks/useAnalytics';

import { ProfileAvatarProps } from './profileAvatar.types';

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({
  src,
  onUpdateLogo,
  fileForUpdateAvatar
}) => {
  const logEvent = useAnalytics();
  const [imgSrc, setImgSrc] = useState<string | ArrayBuffer | null>(''); // also tried <string | Blob>

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    onUpdateLogo(fileList[0]);
  };

  useEffect(() => {
    if (fileForUpdateAvatar) {
      const reader = new FileReader();
      reader.readAsDataURL(fileForUpdateAvatar);
      reader.onloadend = () => {
        setImgSrc(reader.result);
      };
    } else {
      setImgSrc(null);
    }
  }, [fileForUpdateAvatar]);

  return (
    <label className="relative w-fit flex-none cursor-pointer" htmlFor="uploadAvatar">
      <Common.ProfileImage size="2xl" src={(imgSrc as string) || src} />
      <input
        accept="image/*"
        data-testid="uploadAvatar"
        id="uploadAvatar"
        multiple={false}
        style={{ display: 'none' }}
        type="file"
        onChange={handleImageChange}
        onClick={() => logEvent('acc_information_avatar_image_click')}
      />
      <Common.Icon
        className="absolute bottom-0 right-0 size-6 rounded-full border-2 border-gray-50 bg-blue p-0.5 text-white shadow"
        name="camera"
      />
    </label>
  );
};

export default ProfileAvatar;
