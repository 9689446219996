import { Common } from '@thecvlb/design-system';

import { CareTypes } from 'containers/CreateAppointmentExtended/createAppointmentExtended.types';

export const getBuildCards = (onSelect: (v: CareTypes) => void, isWeightManagement?: boolean) => {
  return [
    {
      title: 'Talk to a doctor',
      icon: <Common.Icon className="text-red" name="medical-plus" />,
      subtitle: 'New or refills',
      onClick: () => onSelect('talk-to-a-doctor')
    },
    {
      title: 'Prescriptions',
      icon: <Common.Icon name="pill" />,
      subtitle: 'Refill a medication',
      onClick: () => onSelect('prescriptions')
    },
    ...(isWeightManagement
      ? [
          {
            title: 'Labs',
            icon: <Common.Icon name="lab" />,
            subtitle: 'Know your body',
            onClick: () => onSelect('shop-labs')
          }
        ]
      : [])
  ];
};
