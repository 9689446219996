import { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function RouteScrollTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });
  }, [location.pathname, location.search]);

  return <Fragment />;
}
