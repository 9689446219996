import { selectReOnboardingWMStatus, selectUser } from 'store';

import { UpgradeFlows } from 'modals/AskToUpgradeToTheWM/askToUpgradeTotheWM.types';

import { useAppSelector } from 'hooks';

export const useGetReOnboardingType = (): UpgradeFlows => {
  const { accessToken } = useAppSelector(selectUser);
  if (!accessToken) {
    return 'onboarding';
  }
  const isExtendedReOnboardingWMFlowEnabled = useAppSelector(selectReOnboardingWMStatus);
  return isExtendedReOnboardingWMFlowEnabled ? 'upgrade-based-on-weight' : 'upgrade-plan';
};
