import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { GOVERNMENT_ID_ERRORS, SELF_PORTRAIT_ERRORS } from 'constants/defaults';
import useAnalytics from 'hooks/useAnalytics';

import { CardProps } from './card.types';

const Card: React.FC<CardProps> = ({ type, onClick, onDelete, isLoading, docs }) => {
  const logEvent = useAnalytics();
  const { government, personPhoto } = docs;
  const isSelfie = type === 'SELFIE';
  const { identity, _id, filePath } = isSelfie ? personPhoto : government;
  const hasErrors = identity?.errors?.filter(({ type }) =>
    (isSelfie ? SELF_PORTRAIT_ERRORS : GOVERNMENT_ID_ERRORS).includes(type)
  ).length;

  const status = identity ? (hasErrors ? 'error' : 'success') : undefined;
  const isDisabled = (isSelfie && !government._id) || isLoading;

  const handleClick = () => {
    logEvent(
      isSelfie
        ? 'verify_identity_selfie_photo_btn_click'
        : 'verify_identity_government_id_btn_click'
    );
    !isDisabled && onClick(type);
  };

  return (
    <>
      <div
        className={classNames(
          'relative flex flex-1 flex-col items-center rounded-xl bg-white justify-center shadow min-h-[140px]',
          isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
        )}
        style={{
          background: filePath
            ? `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), center / cover no-repeat url(${filePath})`
            : ''
        }}
        onClick={handleClick}
      >
        {status && (
          <div className="absolute left-3 top-3">
            {status === 'error' ? (
              <div className="flex size-4 items-center justify-center rounded-full bg-red text-2xs text-white">
                !
              </div>
            ) : (
              <Common.Icon className="rounded-full bg-green p-1 text-white" name="check" />
            )}
          </div>
        )}
        {filePath && (
          <button
            className="absolute right-3 top-3"
            onClick={(e) => {
              e.stopPropagation();
              _id && onDelete(_id);
            }}
          >
            <Common.Icon className="text-white" name="close" />
          </button>
        )}
        {!filePath && (
          <>
            <Common.Icon
              className="size-12 text-primary-400"
              name={isSelfie ? 'person-capture-photo' : 'identification'}
            />
            <p className="mb-4 font-semibold">{isSelfie ? 'Selfie photo' : 'Government ID'}</p>
            <Common.Button color="white" disabled={isDisabled} size="sm">
              Upload
            </Common.Button>
          </>
        )}
      </div>
    </>
  );
};

export default Card;
