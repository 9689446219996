// TODO: move all hardcoded pricepoints here so it's easier to update them in the future

export const ONE_MONTH_CARE_ONLY_PP = 'wm-one-month-149';

export const BACK_TO_WM_PRICE_POINTS = ['wm-one-month-149', 'wm-six-months-894'];

export const DEFAULT_UTI_PRICE_POINT = 'limited-access-59';

export const WM_VALID_ONBOARDING_PRICE_POINTS_FOR_PARTNER_FLOWS = [
  'wm-one-month-129',
  'wm-three-months-129',
  'wm-six-months-129',
  'wm-twelve-months-129'
];

export const WM_NEW_VALID_ONBOARDING_PRICE_POINTS = [
  'wm-one-month-149',
  'wm-three-months-447',
  'wm-six-months-894',
  'wm-twelve-months-1788'
];

export const WM_BALLAD_HEALTH_PRICE_POINTS = ['wm-one-month-149', 'wm-three-months-447'];

export const WM_ONBOARDING_PRICE_POINTS_SPECIFIC_FLOW = ['wm-one-month-vip-29'];

export const TRIPLE_THERAPY_PRICE_POINTS = ['wm-tt-one-month', 'one-month-tt'];

// to show one or few pp during onboarding
export const WM_INSURANCE_VALID_ONBOARDING_PRICE_POINTS = ['wm-insurance-one-month-0'];

// to define if authorized user is insurance user
export const WM_INSURANCE_VALID_PRICE_POINTS = [
  'wm-insurance-one-month',
  'wm-insurance-one-month-0',
  'wm-insurance-one-month-trial',
  'wm-insurance-one-month-19'
];

export const BLUE_LINE_ONBOARDING_PRICE_POINTS = ['wm-bl'];

// export const WM_INSURANCE_ALT_VALID_PRICE_POINTS = ['wm-insurance-one-month-trial'];
export const WM_INSURANCE_ALT_VALID_PRICE_POINTS = ['wm-insurance-one-month-19'];

export const TIRZEPATIDE_PRICE_POINT = 'bundle-tirzepatide-one-month';

export const SEMAGLUTIDE_PRICE_POINT = 'bundle-semaglutide-one-month-399';
