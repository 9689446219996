import posthog from 'posthog-js';

// import { IS_LIVE } from './constants';
import { FlowTypes, SignUpSteps } from './enums';

export const handlePostHogTracking = (stepName: SignUpSteps, payload?: Record<string, string>) => {
  posthog.capture(`[${stepName}]`, payload);
};

export const handlePostHogEvent = (
  message: string,
  payload?: Record<string, string | number | boolean | undefined>
) => {
  posthog.capture(message, payload);
};

export const handlePostHogPurchase = (
  flow: FlowTypes,
  order: Record<string, unknown> | null = {}
) => {
  // IS_LIVE &&
  posthog.capture('purchase', {
    flow,
    orderCouponCode: order?.couponCode || '',
    orderTotal: order?.orderTotal || '',
    orderTs: order?.orderTs || '',
    planName: order?.productName || '',
    productId: order?.productId || ''
  });
};
